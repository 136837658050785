import { put, take, all, select } from 'redux-saga/effects';

import { API } from 'api';
import { authConstants } from 'store/auth/constants';
import { creativesActions } from 'store/creatives/actions';
import { Campaigns } from 'api/Campaigns';
import { Creatives } from 'api/Creatives';
import { getCreativesParams } from '../creatives/selectors';
import { localStorageService } from '../../services/localStorage';
import { applicationActions } from '../app/actions';
import { fetchPrivateDeals, fetchPrivateDealsCount } from './privateDeals';
import { fetchCampaignsCountByStatus } from './campaignsCountByStatus';
import { fetchCampaigns } from './campaigns';

export default function* loginSuccess() {
  const creativesParams = yield select(getCreativesParams);

  while (yield take(authConstants.LOGIN_SUCCESS)) {
    const hasUserApprovalAccess: boolean =
      localStorageService.getBaseInfo<{ [key: string]: any }>().isCampaignApprovalAccess &&
      localStorageService.getBaseInfo<{ [key: string]: any }>().isCreativeApprovalAccess;

    const userTypeId: number = localStorageService.getBaseInfo<{ [key: string]: any }>().userType;

    yield put(applicationActions.setUserApprovalAccess(hasUserApprovalAccess));
    yield put(applicationActions.setUserTypeId(userTypeId));

    if (hasUserApprovalAccess) {
      const [creativesResponse]: [Creatives, Campaigns] = yield all([
        API.Creatives.FetchCreatives(creativesParams),
        fetchCampaigns(),
        fetchPrivateDeals(),
        fetchPrivateDealsCount(),
        fetchCampaignsCountByStatus(),
      ]);

      yield put(creativesActions.setCreatives(creativesResponse));
      yield put(creativesActions.setCreativesParams({ pageNo: 2 }));
    } else {
      yield all([fetchPrivateDeals(), fetchPrivateDealsCount(), fetchCampaignsCountByStatus()]);
    }
  }
}

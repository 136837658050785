import { reducerFromMap } from 'utils/actions';
import { Action } from 'models/Action';
import { tableConstants } from './constants';
import { Table } from '../../models/Table';
import { tableData } from './consts';

export interface TableState {
  tableData: Table;
}

const defaultTableState: TableState = {
  tableData,
};

function getData(state: TableState, action: Action<Table>): TableState {
  return {
    ...state,
    tableData: action.payload,
  };
}

const reducers = reducerFromMap<TableState>(defaultTableState, {
  [tableConstants.GET_TABLE_DATA]: getData,
});

export const table = (state: TableState = defaultTableState, action: Action<any>) =>
  reducers(state, action);

import { Campaign, CampaignCount, Campaigns, Params } from '../../api/Campaigns';
import { Action } from '../../models/Action';
import { reducerFromMap } from '../../utils/actions';
import { campaignsConstants } from './constants';
import { Option } from '../../models/Option';

export interface CampaignsState {
  campaigns: Campaigns;
  campaignsParams: Params;
  isCampaignsFetching: boolean;
  isSelectedCampaignsApprovalStart: boolean;
  multipleItemsCategories: Option[];
  campaignsCountByStatus: CampaignCount[];
}

export const defaultCampaignsState: CampaignsState = {
  campaigns: {
    data: [],
    filteredRecords: null,
    totalRecords: null,
  },
  campaignsParams: {
    pageNo: 1, // currently loaded page
    noOfEntries: 10,
    status: 'pending',
    searchField: '',
  },
  isCampaignsFetching: false,
  isSelectedCampaignsApprovalStart: false,
  multipleItemsCategories: [],
  campaignsCountByStatus: [],
};

const setCampaigns = (state: CampaignsState, action: Action<Campaigns>): CampaignsState => {
  return {
    ...state,
    campaigns: {
      ...action.payload,
      data: action.payload.data || [],
    },
    campaignsParams: {
      ...state.campaignsParams,
      pageNo: 1,
    },
    isCampaignsFetching: false,
    isSelectedCampaignsApprovalStart: false,
    multipleItemsCategories: [],
  };
};

const addCampaigns = (state: CampaignsState, action: Action<Campaigns>): CampaignsState => {
  return {
    ...state,
    campaigns: {
      data: [...state.campaigns.data, ...action.payload.data],
      filteredRecords: action.payload.filteredRecords,
      totalRecords: action.payload.totalRecords,
    },
    campaignsParams: {
      ...state.campaignsParams,
      pageNo: state.campaignsParams.pageNo + 1,
    },
    isCampaignsFetching: false,
  };
};

const setCampaignsParams = (
  state: CampaignsState,
  action: Action<Partial<Params>>,
): CampaignsState => {
  return {
    ...state,
    campaignsParams: {
      ...state.campaignsParams,
      ...action.payload,
    },
  };
};

const setCampaignsFetching = (state: CampaignsState, action: Action<boolean>): CampaignsState => {
  return {
    ...state,
    isCampaignsFetching: action.payload,
  };
};

const selectDeselectCampaign = (state: CampaignsState, action: Action<number>): CampaignsState => {
  return {
    ...state,
    campaigns: {
      filteredRecords: state.campaigns.filteredRecords,
      totalRecords: state.campaigns.totalRecords,
      data: state.campaigns.data.map((item: Campaign) => {
        if (item.id !== action.payload) {
          // This isn't the item we care about - keep it as-is
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          isSelected: !item.isSelected,
        };
      }),
    },
  };
};

const selectDeselectAllCampaigns = (
  state: CampaignsState,
  action: Action<boolean>,
): CampaignsState => {
  return {
    ...state,
    campaigns: {
      filteredRecords: state.campaigns.filteredRecords,
      totalRecords: state.campaigns.totalRecords,
      data: state.campaigns.data.map((item: Campaign) => {
        return {
          ...item,
          isSelected: action.payload,
        };
      }),
    },
  };
};

const setCampaignsSearch = (state: CampaignsState, action: Action<string>): CampaignsState => {
  return {
    ...state,
    campaignsParams: {
      ...state.campaignsParams,
      pageNo: 2,
      searchField: action.payload,
    },
    isCampaignsFetching: true,
  };
};

const setCampaignCategories = (
  state: CampaignsState,
  action: Action<{ id: number; categories: Option[] }>,
): CampaignsState => {
  const { id, categories } = action.payload;

  return {
    ...state,
    campaigns: {
      filteredRecords: state.campaigns.filteredRecords,
      totalRecords: state.campaigns.totalRecords,
      data: state.campaigns.data.map((item: Campaign) => {
        if (item.id !== id) {
          // This isn't the item we care about - keep it as-is
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          adCategoryIds: categories.map((category: Option) => category.value),
        };
      }),
    },
  };
};

const setSelectedCampaignsApprovalStart = (
  state: CampaignsState,
  action: Action<boolean>,
): CampaignsState => {
  return {
    ...state,
    isSelectedCampaignsApprovalStart: action.payload,
  };
};

const setMultipleItemsCategories = (
  state: CampaignsState,
  action: Action<Option[]>,
): CampaignsState => {
  return {
    ...state,
    multipleItemsCategories: action.payload,
  };
};

const resetMultipleItemsCategories = (state: CampaignsState): CampaignsState => {
  return {
    ...state,
    isSelectedCampaignsApprovalStart: false,
    multipleItemsCategories: [],
  };
};

const setCampaignsCountByStatus = (state: CampaignsState, action: Action<CampaignCount[]>) => {
  return {
    ...state,
    campaignsCountByStatus: action.payload,
  };
};

const reducer = reducerFromMap<CampaignsState>(defaultCampaignsState, {
  [campaignsConstants.SET_CAMPAIGNS]: setCampaigns,
  [campaignsConstants.ADD_CAMPAIGNS]: addCampaigns,
  [campaignsConstants.SET_CAMPAIGNS_PARAMS]: setCampaignsParams,
  [campaignsConstants.SET_CAMPAIGNS_FETCHING]: setCampaignsFetching,
  [campaignsConstants.SELECT_DESELECT_CAMPAIGN]: selectDeselectCampaign,
  [campaignsConstants.SELECT_DESELECT_ALL_CAMPAIGNS]: selectDeselectAllCampaigns,
  [campaignsConstants.CAMPAIGNS_LIST_SEARCH_CHANGE]: setCampaignsSearch,
  [campaignsConstants.SET_CAMPAIGN_CATEGORIES]: setCampaignCategories,
  [campaignsConstants.SELECTED_CAMPAIGNS_APPROVAL_START]: setSelectedCampaignsApprovalStart,
  [campaignsConstants.MULTIPLE_ITEMS_CATEGORIES_CHANGE]: setMultipleItemsCategories,
  [campaignsConstants.RESET_MULTIPLE_ITEMS_CATEGORIES]: resetMultipleItemsCategories,
  [campaignsConstants.SET_CAMPAIGNS_COUNT_BY_STATUS]: setCampaignsCountByStatus,
});

export const campaigns = (state: CampaignsState = defaultCampaignsState, action: Action<any>) =>
  reducer(state, action);

import { createAction } from 'utils/actions';
import { organisationConstants } from './constants';
import { ListOption } from '../../models/ListOption';

export const organisationActions = {
  setSwitch(organisationSwitch: string) {
    return createAction<string>(organisationConstants.SET_ORGANISATION_SWITCH, organisationSwitch);
  },
  setRole(role: ListOption) {
    return createAction<ListOption>(organisationConstants.SET_ROLE, role);
  },
};

export interface SetSwitch {
  setSwitch: (organisationSwitch: string) => void;
}

export interface SetRole {
  setRole: (role: ListOption) => void;
}

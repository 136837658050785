import React from 'react';
import { TextField, Tumbler } from 'factor';

import styles from './styles.module.scss';

class Header extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      commissions: '10%',
      viewOnly: false,
    };
  }

  render() {
    const { className, isAccountOwner } = this.props;
    const { commissions, viewOnly } = this.state;

    return (
      <header className={`${styles.header} ${className}`}>
        <h2 className={`title-3 ${styles.name}`}>Advertiser</h2>
        {isAccountOwner && (
          <div className={styles.assigned}>
            Assigned to Tim Wilson
            <button type="button" className={styles.reassign} disabled>
              Reassign
            </button>
          </div>
        )}
        <div className={styles.textField}>
          <TextField
            label="% Commissions"
            value={commissions}
            onChange={(v: string) => this.setState({ commissions: v })}
          />
        </div>
        <div className={`${styles.viewOnly} ${isAccountOwner ? '_disabled' : ''} ml-4`}>
          <div className={styles.label}>View Only</div>
          <Tumbler
            className={styles.tumbler}
            onOff
            name="viewOnly"
            id="viewOnly"
            on={viewOnly}
            onChange={() => this.setState({ viewOnly: !viewOnly })}
            disabled={isAccountOwner}
          />
        </div>
        {isAccountOwner && <div className={styles.warning}>limited editing*</div>}
      </header>
    );
  }
}

export default Header;

import React from 'react';
import { connect } from 'react-redux';
import { IQMRouter } from 'iqm-framework';
import { Snackbar, Dialog } from 'factor';

import { BASE_URL } from 'config';
import { authActions, Authorized, Logout } from 'store/auth/actions';
import { User } from 'models/User';
import { createIqmInstance } from 'api';
import { PrivateRoutes } from '../PrivateRoutes';
import { Navbar } from '../Navbar';

import './App.scss';
import { SnackbarActions, snackbarActions } from '../../store/snackbar/actions';
import { AppState } from '../../store';
import { SnackbarState } from '../../store/snackbar/reducers';
import { DialogState } from '../../store/dialog/reducers';
import { DialogActions, dialogActions } from '../../store/dialog/actions';

interface Props
  extends Authorized,
    Logout,
    SnackbarActions,
    SnackbarState,
    DialogState,
    DialogActions {}

const AppComponent = (props: Props) => {
  const {
    authorized,
    logout,
    closeSnackbar,
    isSnackbarOpen,
    snackbarMessage,
    isDialogOpen,
    dialogContent,
    closeDialog,
    dialogClassName,
    duration,
  } = props;

  const onSuccessLogin = (data: User) => {
    authorized(data);
    createIqmInstance({
      apiToken: data.apiToken,
      domainURL: data.domainURL,
    });
  };

  const onLogout = () => logout();

  return (
    <IQMRouter
      documentTitle="Accounts"
      successLoginRedirectPath={BASE_URL}
      onSuccessLogin={onSuccessLogin}
      onLogout={onLogout}
    >
      <Navbar />
      <PrivateRoutes />
      <Snackbar
        open={isSnackbarOpen}
        onClose={closeSnackbar}
        message={snackbarMessage}
        autoHideDuration={duration}
      />
      <Dialog className={dialogClassName} open={isDialogOpen} onClickOutside={closeDialog}>
        {dialogContent}
      </Dialog>
    </IQMRouter>
  );
};

const mapStateToProps = (state: AppState) => ({
  isSnackbarOpen: state.snackbar.isSnackbarOpen,
  snackbarMessage: state.snackbar.snackbarMessage,
  duration: state.snackbar.duration,
  isDialogOpen: state.dialog.isDialogOpen,
  dialogContent: state.dialog.dialogContent,
  dialogClassName: state.dialog.dialogClassName,
});

const mapAction = {
  authorized: authActions.authorized,
  logout: authActions.logout,
  closeSnackbar: snackbarActions.closeSnackbar,
  closeDialog: dialogActions.closeDialog,
};

export const App = connect(mapStateToProps, mapAction)(AppComponent);

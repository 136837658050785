import axios, { AxiosResponse, Canceler } from 'axios';
import { Logger } from '../utils/logger';
import { getInstance } from './Instance';
import { GetResponse } from '../models/Response';

/* eslint-disable */
export type Params = {
  pageNo: number;
  noOfEntries?: number;
  searchField?: string;
  status?: string;
  advertiserIds?: string | null;
  creativeTypeIds?: string | null;

  filter_dsp?: string;
  filter_adv?: string;
  filter_camp?: string;
  sortBy?: string;
  order?: string;
  draw?: number;
};
/* eslint-enable */
export type Campaigns = {
  data: Campaign[];
  filteredRecords: number | null;
  totalRecords: number | null;
};
/* eslint-disable */
export type Campaign = {
  id: number;
  name: string;
  creativeType: string;
  creativeTypeId: number;
  budgetTotal: number;
  budgetDay: number;
  startTime: number;
  advertiserId: number;
  advertiserName: string;
  campaignType: string;
  status: string;
  creativeCount: number;
  approvedCreativeCount: number;
  created: number;

  status_lists?: {
    running: string;
    budget_paused: string;
    deleted: string;
  };
  advertiser_email?: string;
  company_name?: string;
  dsp_id?: number;
  advertiser_name?: string;
  target_cpi?: number;
  creative_running_count?: number;
  max_daily_clicks?: number | null;
  advertiser_domain?: string;
  max_daily_impressions?: string | null;
  end_time?: string | null;
  app_url?: string | null;
  conversion_type?: string;
  timezone?: string;
  creative_pending_count?: number;
  max_daily_conversions?: number | null;
  creative_rejected_count?: number;
  bid_optimization?: number;
  max_bid?: number;
  user_deal_id?: number | null;
  creative_deleted_count?: number;
  creative_paused_count?: number;
  bid_pacing?: number;
  max_total_impressions?: number | null;
  max_total_conversions?: number | null;
  country_name?: string | number;
  max_total_clicks?: number | null;
  impression_capping?: number;

  isSelected?: boolean;

  adCategoryIds?: number[];
};

export type ApproveParams = {
  campaignIds: string;
  adCategoryIds?: string;
};

export type RejectParams = {
  campaignIds: string;
  rejectionReason: string;
};
/* eslint-enable */

/* eslint-disable camelcase */
export type CampaignCount = {
  status_label: string;
  status_key: string;
  order: number;
  status_count: number;
};
/* eslint-enable camelcase */

let cancel: Canceler;

export const FetchCampaigns = async (params: Params): Promise<any> => {
  if (cancel) {
    cancel('Canceled by the user');
  }
  try {
    const response: AxiosResponse<GetResponse<Campaigns>> = await getInstance().get(
      '/v2/cmp/campaign/listByDsp',
      {
        params: {
          status: 'pending',
          ...params,
        },
        cancelToken: new axios.CancelToken((c) => {
          cancel = c;
        }),
      },
    );

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while getting campaigns', e);
    return {};
  }
};

export const fetchCampaignsCountByStatus = async (): Promise<CampaignCount[]> => {
  try {
    const response: AxiosResponse<CampaignCount[]> = await getInstance().get(
      '/get/status/campaigns',
    );

    return response.data;
  } catch (e) {
    Logger.log('Error while getting campaigns count by status', e);
    return e;
  }
};

export const ApproveCampaigns = async (params: ApproveParams): Promise<any> => {
  try {
    const response: AxiosResponse<GetResponse<Campaigns>> = await getInstance().put(
      '/v2/cmp/campaigns/update-status',
      {
        ...params,
        status: 'running',
      },
    );

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while approving campaigns', e);
    return e.response.data.responseObject;
  }
};

export const RejectCampaigns = async (params: RejectParams): Promise<any> => {
  try {
    const response: AxiosResponse<GetResponse<Campaigns>> = await getInstance().put(
      '/v2/cmp/campaigns/update-status',
      {
        ...params,
        status: 'rejected',
      },
    );

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while rejecting campaigns', e);
    return {};
  }
};

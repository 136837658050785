import { Option } from '../../models/Option';
import { Action } from '../../models/Action';
import { reducerFromMap } from '../../utils/actions';
import { creativeTypesConstants } from './constants';

export interface CreativeTypesState {
  creativeTypesList: Option<number>[];
  selectedCreativeTypes: Option<number>[];
}

export const defaultCreativeTypesState: CreativeTypesState = {
  creativeTypesList: [],
  selectedCreativeTypes: [],
};

const setCreativeTypes = (
  state: CreativeTypesState,
  action: Action<Option<number>[]>,
): CreativeTypesState => {
  return {
    ...state,
    creativeTypesList: action.payload,
  };
};

const selectCreativeTypes = (
  state: CreativeTypesState,
  action: Action<Option<number>[]>,
): CreativeTypesState => {
  return {
    ...state,
    selectedCreativeTypes: action.payload,
  };
};

const reducer = reducerFromMap<CreativeTypesState>(defaultCreativeTypesState, {
  [creativeTypesConstants.SELECT_CREATIVE_TYPES]: selectCreativeTypes,
  [creativeTypesConstants.SET_CREATIVE_TYPES]: setCreativeTypes,
});

export const creativeTypes = (
  state: CreativeTypesState = defaultCreativeTypesState,
  action: Action<any>,
) => reducer(state, action);

import { createAction } from '../../utils/actions';
import { privateDealConstants } from './constants';
import { Data, DealTypes } from '../../api/CreatePrivateDeal';

export type CreatePrivateDeal = {
  createPrivateDeal: (data: Data) => void;
};

export type SetPrivateDealTypes = {
  setPrivateDealTypes: (types: DealTypes) => void;
};

export type SetPrivateDealCreated = {
  setPrivateDealCreated: () => void;
};

export const privateDealActions = {
  createPrivateDeal(data: Data) {
    return createAction(privateDealConstants.CREATE_PRIVATE_DEAL, data);
  },
  setPrivateDealTypes(types: DealTypes) {
    return createAction(privateDealConstants.SET_PRIVATE_DEAL_TYPES, types);
  },
  setPrivateDealCreated() {
    return createAction(privateDealConstants.PRIVATE_DEAL_CREATED);
  },
};

import React from 'react';
import { Header } from 'iqm-framework';
import { Tabs, CustomerSelect } from 'factor';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
/* eslint-disable-next-line */
import { History, Location } from 'history';

import { API } from 'api';
import styles from './styles.module.scss';
import { AppState } from '../../store';
import { AdvertiserActions, advertiserActions } from '../../store/advertisers/actions';
import { Advertiser } from '../../api/Advertisers';
import { User } from '../../models/User';
import { CustomerOption } from '../../models/Option';

const TABS = [
  { title: 'Organisation', value: '/organisation' },
  { title: 'Approval & Management', value: '/customers' },
  { title: 'Settings', value: '/settings' },
];

interface Props extends AdvertiserActions {
  advertisers: Advertiser[];
  advertisersSearchField: string;
  selectedAdvertiserOption: CustomerOption | null;
  user: User | null;
  history: History;
  location: Location;
}
interface State {
  balance: string;
}

class NavbarComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      balance: '—',
    };
  }

  async componentDidUpdate(newProps: Props) {
    const { selectedAdvertiserOption, user } = this.props;
    const oldSelectedAdvertiserOption = newProps.selectedAdvertiserOption;
    const { balance } = this.state;

    if (selectedAdvertiserOption) {
      if (
        !oldSelectedAdvertiserOption ||
        oldSelectedAdvertiserOption.value !== selectedAdvertiserOption.value
      ) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          balance: '—',
        });

        const newBalance = await API.Advertisers.fetchAdvertiserBalance({
          list_adv: `${selectedAdvertiserOption.value}`,
          dspId: user?.dspId,
          advertiserId: user?.userId,
        });

        const CurrencyFormat = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          balance: newBalance === null ? '—' : CurrencyFormat.format(newBalance),
        });
      }
    } else if (balance && balance !== '—') {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        balance: '—',
      });
    }
  }

  onChange = (value: CustomerOption | null) => {
    const { selectAdvertiser } = this.props;
    selectAdvertiser(value);
  };

  getTransformAdvertisers = () => {
    const { advertisers, advertisersSearchField } = this.props;

    let transformedAdvertisers = advertisers.map((advertiser: Advertiser) => ({
      label: advertiser.name,
      value: advertiser.id,
      email: advertiser.email,
      image: '', // no image got now
    }));

    // search searchField in label (name), value (id) and email
    if (advertisersSearchField !== '') {
      transformedAdvertisers = transformedAdvertisers.filter(
        (advertiser: any) =>
          advertiser.label.toLowerCase().indexOf(advertisersSearchField.toLowerCase()) !== -1 ||
          advertiser.value
            .toString()
            .toLowerCase()
            .indexOf(advertisersSearchField.toLowerCase()) !== -1 ||
          advertiser.email.toLowerCase().indexOf(advertisersSearchField.toLowerCase()) !== -1,
      );
    }

    return transformedAdvertisers;
  };

  changeSearchHandler = (value: any) => {
    const { setAdvertisersSearchField } = this.props;
    const searchValue = value.nativeEvent.target.value;
    setAdvertisersSearchField(searchValue || '');
  };

  render() {
    const { history, location, selectedAdvertiserOption, user } = this.props;
    const { balance } = this.state;

    const onTabsChange = (item: { title: string; value: string }) => {
      history.push(item.value);
    };

    const tab = TABS.find((i) => i.value === location.pathname);
    const tabValue = tab ? tab.value : null;

    return (
      <Header backButton={false} title="Accounts" leftLogo="Accounts">
        <Tabs className={styles.tabs} items={TABS} onChange={onTabsChange} value={tabValue} />
        {user && (
          <CustomerSelect
            balance={balance}
            className={`${styles.width} ml-auto`}
            options={this.getTransformAdvertisers()}
            onChange={this.onChange}
            value={selectedAdvertiserOption}
            selectSpecificProps={{
              placeholder: 'Select Advertiser',
              changeSearchHandler: this.changeSearchHandler,
            }}
          />
        )}
      </Header>
    );
  }
}

const mapState = (state: AppState) => ({
  user: state.auth.user,
  advertisers: state.advertisers.advertisers,
  advertisersSearchField: state.advertisers.advertisersSearchField,
  selectedAdvertiserOption: state.advertisers.selectedAdvertiserOption,
});

const mapAction = {
  setAdvertisersSearchField: advertiserActions.setAdvertisersSearchField,
  selectAdvertiser: advertiserActions.selectAdvertiser,
};

const NavbarConnected = connect(mapState, mapAction)(NavbarComponent);

export const Navbar = withRouter(NavbarConnected);

import { createAction } from '../../utils/actions';
import { creativesConstants } from './constants';
import { Creatives, Params } from '../../api/Creatives';
import { Option } from '../../models/Option';

export interface CreativesActions {
  setCreatives: (creatives: Creatives) => void;
  addCreatives: (creatives: Creatives) => void;
  setCreativesParams: (creatives: Partial<Params>) => void;
  setSelectedCampaigns: (campaigns: Option[]) => void;
  setCreativesFetching: (isFetching: boolean) => void;
  setCreativesSearch: (searchField: string) => void;
  creativesApprovedOrRejected: () => void;
}

export interface SetCreativesCompact {
  setCreativesCompact: (isCompact: boolean) => void;
}

export const creativesActions = {
  setCreatives(creatives: Creatives) {
    return createAction(creativesConstants.SET_CREATIVES, creatives);
  },
  addCreatives(creatives: Creatives) {
    return createAction(creativesConstants.ADD_CREATIVES, creatives);
  },
  setCreativesParams(creativesParams: Partial<Params>) {
    return createAction(creativesConstants.SET_CREATIVES_PARAMS, creativesParams);
  },
  setSelectedCampaigns(campaigns: Option[]) {
    return createAction(creativesConstants.SET_SELECTED_CAMPAIGNS, campaigns);
  },
  setCreativesFetching(isFetching: boolean) {
    return createAction(creativesConstants.SET_CREATIVES_FETCHING, isFetching);
  },
  selectDeselectCreative(value: number) {
    return createAction(creativesConstants.SELECT_DESELECT_CREATIVE, value);
  },
  selectDeselectAllCreatives(value: boolean) {
    return createAction(creativesConstants.SELECT_DESELECT_ALL_CREATIVES, value);
  },
  setCreativesSearch(searchField: string) {
    return createAction(creativesConstants.CREATIVES_LIST_SEARCH_CHANGE, searchField);
  },
  creativesApprovedOrRejected() {
    return createAction(creativesConstants.CREATIVES_APPROVED_OR_REJECTED);
  },
  setCreativesCompact(isCompact: boolean) {
    return createAction(creativesConstants.SET_CREATIVES_COMPACT, isCompact);
  },
};

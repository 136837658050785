import React from 'react';
import { Icon } from 'factor';
import { connect } from 'react-redux';

import styles from './styles.module.scss';
import { transformDealsCount } from '../../utils/transformDealsCount';
import { AppState } from '../../store';
import { PrivateDealsCount } from '../../api/PrivateDeals';
import { SidebarState } from '../../store/sidebar/reducers';
import { CampaignCount } from '../../api/Campaigns';

const CAMPAIGNS_COUNT_ICON_MAP: { [key: string]: string } = {
  running: 'StatusRunningColor',
  pending: 'StatusPendingColor',
  paused: 'StatusPausedColor',
  draft: 'StatusDraftColor',
  budget_paused: 'StatusBudgetPausedColor',
  deleted: 'StatusDeletedColor',
  expired: 'StatusExpiredColor',
  '': 'StatusAllColor',
};

interface Props {
  privateDealsCount: PrivateDealsCount;
  activeSidebarListLabel: SidebarState['activeSidebarListLabel'];
  campaignsCountByStatus: CampaignCount[];
}

const OverviewComponent = (props: Props) => {
  const { privateDealsCount, activeSidebarListLabel, campaignsCountByStatus } = props;

  let stats: { label: string; value: string; count: number | string; iconName: string }[] = [];

  switch (activeSidebarListLabel) {
    case 'Deals': {
      const transformedDealsCount = transformDealsCount(privateDealsCount);
      stats = transformedDealsCount.map((item) => ({
        label: item.tabLabel,
        value: item.fetchedKey,
        count: item.count,
        iconName: item.iconName,
      }));
      break;
    }
    case 'Campaigns':
      stats = campaignsCountByStatus.map((count) => ({
        label: count.status_label,
        value: count.status_key,
        count: count.status_count,
        iconName: CAMPAIGNS_COUNT_ICON_MAP[count.status_key],
      }));
      break;
    default:
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{activeSidebarListLabel} Overview</h3>
      <ul className="row align-items-stretch mb-5">
        {stats.map((stat) => (
          <div className="col-3 d-flex align-items-stretch mb-3" key={stat.label}>
            <li className={styles.stat} key={stat.label}>
              <i className={styles.statIcon}>
                <Icon name={stat.iconName} />
              </i>
              <div className={styles.statInfo}>
                <div className={styles.statValue}>{stat.count}</div>
                <div className={styles.statLabel}>{stat.label}</div>
              </div>
            </li>
          </div>
        ))}
      </ul>
    </div>
  );
};

const mapState = (state: AppState) => ({
  privateDealsCount: state.privateDeals.privateDealsCount,
  activeSidebarListLabel: state.sidebar.activeSidebarListLabel,
  campaignsCountByStatus: state.campaigns.campaignsCountByStatus,
});

export const Overview = connect(mapState)(OverviewComponent);

import React from 'react';
import { connect } from 'react-redux';
import { ParamsCard, ParamsCardsDate, TextField, Button } from 'factor';

import moment from 'moment';
import styles from './styles.module.scss';
import { RightBarList } from '../rightBarList';
import { rightbarActions } from '../../../../store/rightbar/actions';
import { AppState } from '../../../../store';
import { CustomerOption, Option } from '../../../../models/Option';
import { API } from '../../../../api';
import { SnackbarActions, snackbarActions } from '../../../../store/snackbar/actions';
import { CampaignsActions, campaignsActions } from '../../../../store/campaigns/actions';
import { Campaign, Campaigns, Params } from '../../../../api/Campaigns';
import { Skeleton } from '../../../shared/skeleton';
import { RejectDialog } from '../rejectDialog';
import { DialogActions, dialogActions } from '../../../../store/dialog/actions';
import { PublisherResponse } from '../../../../api/Publishers';
import { ApproveMultipleCampaigns } from '../approveMultipleCampaigns';
import { CategoriesSelect } from '../categoriesSelect';
import { PartlyFailedResponseObj } from '../../../../models/Response';
import { RejectedTumbler } from '../rejectedTumbler';
import { SelectCreativeTypes } from '../selectCreativeTypes';

const SINGLE_ACTIONS = [
  { label: 'View', iconName: 'Impressions' },
  { label: 'Edit', iconName: 'Edit' },
  { label: 'Approve', iconName: 'Done' },
  { label: 'Decline', iconName: 'NoData' },
];

interface Props extends CampaignsActions, SnackbarActions, DialogActions {
  className?: string;
  campaigns: Campaigns;
  campaignsParams: Params;
  isCampaignsFetching: boolean;
  selectDeselectCampaign: (id: number) => void;
  selectDeselectAllCampaigns: (shouldSelectAll: boolean) => void;
  isSelectedCampaignsApprovalStart: boolean;
  multipleItemsCategories: Option[];
  rejectionReason: string;
  rightbarReportsSearch: string;
  setRightbarReportsSearch: (search: string) => void;
  updateReport: (value: { id: number; field: string; value: any }) => void;
  rawPublishers: PublisherResponse[];
  selectedAdvertiserOption: CustomerOption | null;
  selectedCreativeTypes: Option[];
}

interface State {
  cardWithOpenSubContentId: number | null;
  cardSubContentType: string | null;
  categoriesOptions: Option<number>[] | [];
  searchField: string;
}

class CampaignsListComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cardWithOpenSubContentId: null,
      cardSubContentType: null,
      categoriesOptions: [],
      searchField: props.campaignsParams.searchField || '',
    };
  }

  reloadCampaigns = async () => {
    const { campaignsParams, setCampaigns, setCampaignsFetching } = this.props;

    setCampaignsFetching(true);
    const response = await API.Campaigns.FetchCampaigns({
      ...campaignsParams,
      pageNo: 1,
    });
    setCampaigns(response);
  };

  selectDeselectAllCampaigns = () => {
    const {
      campaigns: { data },
      selectDeselectAllCampaigns,
    } = this.props;
    const isNowSomethingSelected = data.filter((item: any) => item.isSelected).length;
    selectDeselectAllCampaigns(!isNowSomethingSelected);
  };

  loadNewData = async () => {
    const {
      campaigns,
      campaignsParams,
      addCampaigns,
      setCampaignsFetching,
      selectedAdvertiserOption,
      selectedCreativeTypes,
    } = this.props;

    if (
      campaigns.data &&
      campaigns.filteredRecords !== null &&
      campaigns.data.length >= campaigns.filteredRecords
    ) {
      return;
    }

    setCampaignsFetching(true);

    const pageNo = campaignsParams.pageNo + 1;
    const advertiserIds = selectedAdvertiserOption?.value;
    const creativeTypeIds = selectedCreativeTypes
      .map((type: Option<number>) => type.value)
      .join(',');

    const response = await API.Campaigns.FetchCampaigns({
      ...campaignsParams,
      pageNo,
      ...(typeof advertiserIds === 'number' ? { advertiserIds: advertiserIds.toString() } : {}),
      ...(selectedCreativeTypes.length ? { creativeTypeIds } : {}),
    });
    addCampaigns(response);
  };

  /* eslint-disable */
  renderSkeleton = () => <>
    {[...new Array(10)].map((i, k) => (
      <ParamsCard
        key={k}
        title={<Skeleton style={{ width: '5rem', height: `${14 / 16}rem`, margin: `${1/16}rem 0 ${2/16}rem` }} />}
        list={[...new Array(3)].map(() => ({
          label: <Skeleton style={{ width: '3rem', height: `${12 / 16}rem`, margin: `${3/16}rem 0` }} />,
          value: <Skeleton style={{ width: '2rem', height: `${12 / 16}rem`, margin: `${1/16}rem 0 ${2/16}rem` }} />,
        }))}
      />
    ))}
  </>;
  /* eslint-enable */

  getTransformedData = () => {
    const {
      campaigns: { data },
      selectedAdvertiserOption,
    } = this.props;

    if (!data?.length) {
      return [];
    }

    const transformedData: any[] = [];
    let currentBatch: { created?: string; data: any[] } = { data: [] };
    transformedData.push(currentBatch);
    data.forEach((item: Campaign) => {
      // in case there is a lack of '000' at the end of 'created'
      let itemCreated = item.created;
      let itemCreatedString = item.created.toString();
      if (itemCreatedString.length === 10) {
        itemCreatedString = `${itemCreatedString}000`;
        itemCreated = parseInt(itemCreatedString, 10);
      }

      const created: string = moment(itemCreated).format('MM/DD/YYYY');
      if (!currentBatch.created) {
        currentBatch.created = created;
      } else if (currentBatch.created !== created) {
        currentBatch = { created, data: [] };
        transformedData.push(currentBatch);
      }

      // arrange name with or without subtitle
      let name: React.ReactNode = <>{item.name}</>;
      if (selectedAdvertiserOption === null) {
        name = (
          <>
            {item.name}
            <span className={styles.campaignSubtitle}>
              {item.advertiserId}/{item.advertiserName}
            </span>
          </>
        );
      }

      // arrange creatives param appearance
      let creatives: React.ReactNode = (
        <>
          {item.approvedCreativeCount} of {item.creativeCount}
        </>
      );
      if (item.creativeCount > 0 && item.approvedCreativeCount === 0) {
        creatives = (
          <span className={`${styles.creativesCount} ${styles.bad}`}>
            {item.approvedCreativeCount} of {item.creativeCount}
          </span>
        );
      }
      if (item.creativeCount === item.approvedCreativeCount) {
        creatives = (
          <span className={`${styles.creativesCount} ${styles.good}`}>
            {item.approvedCreativeCount}
          </span>
        );
      }

      currentBatch.data.push({
        id: item.id,
        name,
        params: [
          { label: 'ID:', value: item.id },
          { label: 'Type:', value: item.creativeType },
          { label: 'Budget:', value: item.budgetTotal },
          { label: 'Creatives:', value: creatives },
        ],
        isSelected: item.isSelected,
      });
    });

    return transformedData;
  };

  setParamsCardActivity = (id: number) => () => {
    const { selectDeselectCampaign } = this.props;

    selectDeselectCampaign(id);
  };

  approveCampaign = (id: number) => async () => {
    const { openSnackbar } = this.props;

    const { categoriesOptions } = this.state;

    let adCategoryIds = '';

    adCategoryIds = (categoriesOptions as any[]).map((option: Option) => option.value).join(',');

    const response = await API.Campaigns.ApproveCampaigns({ campaignIds: `${id}`, adCategoryIds });
    if (response?.status) {
      openSnackbar({ message: `Campaign with id ${id} was successfully approved` });
      this.reloadCampaigns();
      this.closeCardSubContent();
    } else if (response.errorMsg) {
      openSnackbar({
        message: `Campaign with id ${id} approval was failed. Error: ${response.errorMsg}`,
      });
    } else {
      this.handlePartlyFailedApprovalResponse(response);
    }
  };

  handleApprovalResponse = (response: any, selectedIds: number[]) => {
    const { openSnackbar } = this.props;

    if (response.status) {
      openSnackbar({
        message: `Campaigns with ids ${selectedIds.join(', ')} were successfully approved`,
      });
      this.reloadCampaigns();
    } else if (response.errorMsg) {
      openSnackbar({
        message: `Campaigns with ids ${selectedIds.join(', ')} approval was failed. Error: ${
          response.errorMsg
        }`,
        duration: 10000,
      });
    } else {
      this.handlePartlyFailedApprovalResponse(response);
    }
  };

  handlePartlyFailedApprovalResponse = (response: PartlyFailedResponseObj) => {
    const { openSnackbar } = this.props;

    const approvedIds = response.updatedData?.map((item: any) => item.campaignId);

    const failedMessages = response.failedData.map((item: any) => {
      return <div>{item.message}</div>;
    });

    openSnackbar({
      message: (
        <>
          {approvedIds && (
            <>
              Campaigns with ids {approvedIds.join(', ')} were successfully approved.
              <br />
            </>
          )}
          {failedMessages}
        </>
      ),
      duration: 10000,
    });

    if (approvedIds) {
      this.reloadCampaigns();
    }
  };

  approveSelectedCampaigns = async () => {
    const {
      campaigns: { data },
      multipleItemsCategories,
    } = this.props;

    const selectedIds = data
      .filter((item: Campaign) => item.isSelected)
      .map((item: Campaign) => item.id);

    const adCategoryIds = multipleItemsCategories.map((item: Option) => item.value).join(',');

    const response = await API.Campaigns.ApproveCampaigns({
      campaignIds: selectedIds.join(','),
      adCategoryIds,
    });
    this.handleApprovalResponse(response, selectedIds);
  };

  reject = (ids: number[]) => async () => {
    const { openSnackbar, closeDialog, rejectionReason } = this.props;

    closeDialog();
    const response = await API.Campaigns.RejectCampaigns({
      campaignIds: ids.join(','),
      rejectionReason,
    });
    const s = ids.length > 1 ? 's' : '';
    if (response?.status) {
      openSnackbar({
        message: `Campaign${s} with id${s} ${ids.join(', ')} ${
          s ? 'were' : 'was'
        } successfully rejected`,
      });
      this.reloadCampaigns();
    } else {
      openSnackbar({ message: `Campaign${s} with id${s} ${ids.join(', ')} rejection was failed` });
    }
  };

  rejectCampaigns = (ids: number[]) => () => {
    const { openDialog } = this.props;

    openDialog({
      content: <RejectDialog ids={ids} reject={this.reject(ids)} type="Campaign" />,
      className: styles.rejectDialog,
    });
  };

  openCardSubContent = (id: number, isApproval?: boolean) => () => {
    const {
      campaigns: { data },
      rawPublishers,
    } = this.props;
    /* eslint-disable-next-line */
    const campaign = data.find((campaign: Campaign) => campaign.id === id);

    let categoriesOptions = [];

    if (campaign?.adCategoryIds?.length) {
      categoriesOptions = rawPublishers.reduce((acc: any, publisher: PublisherResponse) => {
        if (campaign.adCategoryIds?.indexOf(publisher.id) !== -1) {
          acc.push({ label: publisher.name, value: publisher.id });
        }
        return acc;
      }, []);
    }

    this.setState({
      cardWithOpenSubContentId: id,
      categoriesOptions,
      cardSubContentType: isApproval ? 'Approve' : null,
    });
  };

  getItemActions = (id: number) => {
    return SINGLE_ACTIONS.map((action) => {
      let onClick = () => console.log(`action ${action.label}`);

      switch (action.label) {
        case 'Approve':
          onClick = this.openCardSubContent(id, true);
          break;
        case 'Decline':
          onClick = this.rejectCampaigns([id]);
          break;
        case 'Edit':
          onClick = this.openCardSubContent(id);
          break;
        default:
      }

      return {
        ...action,
        onClick,
      };
    });
  };

  onAdDomainChange = (id: number) => (value: string) => {
    // const { updateReport } = this.props;
    // updateReport({ id, field: 'adDomain', value });
    console.log('onAdDomainChange', id, value);
  };

  onCampaignCategoriesChange = (value: Option[]) => {
    this.setState({ categoriesOptions: value });
  };

  saveCategories = () => {
    const { setCampaignCategories } = this.props;
    const { categoriesOptions, cardWithOpenSubContentId } = this.state;

    if (cardWithOpenSubContentId) {
      if (categoriesOptions) {
        setCampaignCategories({
          id: cardWithOpenSubContentId,
          categories: categoriesOptions,
        });
      }

      this.closeCardSubContent();
    }
  };

  closeCardSubContent = () => {
    this.setState({
      cardWithOpenSubContentId: null,
      cardSubContentType: null,
      categoriesOptions: [],
    });
  };

  renderCardSubContent = (id: number) => {
    const {
      campaigns: { data },
    } = this.props;
    const { categoriesOptions, cardSubContentType } = this.state;
    /* eslint-disable-next-line */
    const campaign = data.find((campaign: Campaign) => campaign.id === id);

    if (!campaign) {
      return null;
    }

    const isApproval = cardSubContentType === 'Approve';

    return (
      <div className={styles.subContent}>
        <h4 className={styles.subContentTitle}>{campaign.name}</h4>
        {!isApproval && (
          <TextField
            className={styles.subContentRow}
            label="Ad Domain"
            placeholder="Ad Domain"
            value={campaign.advertiser_domain}
            onChange={this.onAdDomainChange(id)}
          />
        )}
        <CategoriesSelect value={categoriesOptions} onChange={this.onCampaignCategoriesChange} />
        <div className="d-flex justify-content-end mt-3">
          <Button
            className="btn-square _md _cornflower-blue mr-2"
            onClick={this.closeCardSubContent}
          >
            Cancel
          </Button>
          <Button
            className="btn-square _md _filled _cornflower-blue"
            onClick={!isApproval ? this.saveCategories : this.approveCampaign(id)}
            disabled={isApproval && !categoriesOptions.length}
          >
            {!isApproval ? 'Save Changes' : 'Approve'}
          </Button>
        </div>
      </div>
    );
  };

  onSearchChange = (searchField: string) => {
    const { setCampaignsSearch } = this.props;
    this.setState({ searchField });
    setCampaignsSearch(searchField);
  };

  renderMultipleCampaignsApproval = () => (
    <ApproveMultipleCampaigns approve={this.approveSelectedCampaigns} />
  );

  renderBeforeList = () => {
    const { campaignsParams, setCampaignsParams, setCampaigns, setCampaignsFetching } = this.props;

    return (
      <>
        <div className={styles.tumblers}>
          <RejectedTumbler
            params={campaignsParams}
            setParams={setCampaignsParams}
            setData={setCampaigns}
            setFetching={setCampaignsFetching}
            fetchData={API.Campaigns.FetchCampaigns}
          />
        </div>
        <div className={styles.creativeTypesSelect}>
          <SelectCreativeTypes />
        </div>
      </>
    );
  };

  render() {
    const {
      className = '',
      campaigns: { data = [] },
      isCampaignsFetching,
      campaignsParams,
      setSelectedCampaignsApprovalStart,
      isSelectedCampaignsApprovalStart,
    } = this.props;

    const { searchField, cardWithOpenSubContentId } = this.state;

    const selectedIds = data
      .filter((item: Campaign) => item.isSelected)
      .map((item: Campaign) => item.id);

    const isSomethingSelected = !!selectedIds.length;

    const isRejectedCampaigns = campaignsParams.status === 'rejected';

    const hasCardsActions = !isSelectedCampaignsApprovalStart && !isRejectedCampaigns;

    return (
      <RightBarList
        className={className}
        search={searchField}
        onSearchChange={this.onSearchChange}
        selectDeselectAll={this.selectDeselectAllCampaigns}
        isSomethingSelected={isSomethingSelected}
        loadData={this.loadNewData}
        isFetching={isCampaignsFetching}
        skeleton={this.renderSkeleton()}
        rejectSelected={this.rejectCampaigns(selectedIds)}
        approvalStart={setSelectedCampaignsApprovalStart}
        isApprovalStarted={isSelectedCampaignsApprovalStart}
        approvalContent={this.renderMultipleCampaignsApproval()}
        isRejected={isRejectedCampaigns}
        beforeList={this.renderBeforeList()}
      >
        {this.getTransformedData().map((batch) => (
          <div key={batch.created}>
            <ParamsCardsDate date={batch.created} />
            {batch.data.map((item: any) => (
              <ParamsCard
                key={item.id}
                title={item.name}
                list={item.params}
                onClick={this.setParamsCardActivity(item.id)}
                isActive={item.isSelected}
                actions={hasCardsActions ? this.getItemActions(item.id) : null}
                subContent={this.renderCardSubContent(item.id)}
                isSubContentOpen={item.id === cardWithOpenSubContentId}
              />
            ))}
          </div>
        ))}
      </RightBarList>
    );
  }
}

const mapState = (state: AppState) => {
  return {
    reports: state.rightbar.reports,
    rightbarReportsSearch: state.rightbar.rightbarReportsSearch,
    rawPublishers: state.publishers.rawPublishers,
    campaigns: state.campaigns.campaigns,
    campaignsParams: state.campaigns.campaignsParams,
    isCampaignsFetching: state.campaigns.isCampaignsFetching,
    isSelectedCampaignsApprovalStart: state.campaigns.isSelectedCampaignsApprovalStart,
    multipleItemsCategories: state.campaigns.multipleItemsCategories,
    rejectionReason: state.dialog.rejectionReason,
    selectedAdvertiserOption: state.advertisers.selectedAdvertiserOption,
    selectedCreativeTypes: state.creativeTypes.selectedCreativeTypes,
  };
};

const mapActions = {
  setRightbarReportsSearch: rightbarActions.setRightbarReportsSearch,
  selectDeselectReport: rightbarActions.selectDeselectReport,
  selectDeselectAllReports: rightbarActions.selectDeselectAllReports,
  updateReport: rightbarActions.updateReport,
  setCampaigns: campaignsActions.setCampaigns,
  addCampaigns: campaignsActions.addCampaigns,
  setCampaignsParams: campaignsActions.setCampaignsParams,
  setCampaignsFetching: campaignsActions.setCampaignsFetching,
  selectDeselectCampaign: campaignsActions.selectDeselectCampaign,
  selectDeselectAllCampaigns: campaignsActions.selectDeselectAllCampaigns,
  setCampaignsSearch: campaignsActions.setCampaignsSearch,
  setCampaignCategories: campaignsActions.setCampaignCategories,
  setSelectedCampaignsApprovalStart: campaignsActions.setSelectedCampaignsApprovalStart,
  openSnackbar: snackbarActions.openSnackbar,
  openDialog: dialogActions.openDialog,
  closeDialog: dialogActions.closeDialog,
};

export const CampaignsList = connect(mapState, mapActions)(CampaignsListComponent);

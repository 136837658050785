import React from 'react';
import { connect } from 'react-redux';

import styles from './styles.module.scss';
import { getRowClassName } from '../../helpers';
import { Table, TableRow } from '../../../models/Table';
import { AppState } from '../../../store';
import { GetData, tableActions } from '../../../store/table/actions';

interface Props extends GetData {
  className?: string;
  tableData: Table;
}

class SelectedCampaignsComponent extends React.Component<Props> {
  handleClear = () => {
    const { tableData: oldTableData, getData } = this.props;

    const newData = JSON.parse(JSON.stringify(oldTableData));

    const tableData = newData.data;
    const tableFirstRow = newData.unsortedData;

    newData.unsortedData = {
      ...tableFirstRow,
      selectedAll: false,
    };
    newData.data = tableData.map((i: TableRow) => {
      /* eslint-disable */
      i.selected = false;
      i.rowClassName = getRowClassName(i.rowClassName, '_selected', false);
      /* eslint-enable */
      return { ...i };
    });

    getData(newData);
  };

  render() {
    const { className, tableData } = this.props;
    const selectedLength = tableData.data.filter((i: TableRow) => i.selected).length;

    return (
      <div className={`${styles.selected} ${className || ''}`}>
        <button type="button" className={`btn-cross ${styles.remove}`} onClick={this.handleClear} />
        {`${selectedLength} Audience${selectedLength > 1 ? 's' : ''} selected`}
      </div>
    );
  }
}

const mapState = (state: AppState) => ({
  tableData: state.table.tableData,
});

const mapActions = {
  getData: tableActions.getData,
};

export const SelectedCampaigns = connect(mapState, mapActions)(SelectedCampaignsComponent);

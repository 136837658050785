import { reducerFromMap } from 'utils/actions';
import { Action } from 'models/Action';
import { applicationConstants } from './constants';

export interface ApplicationState {
  hasUserApprovalAccess: boolean;
  isSidebarOpen: boolean;
  isOpenExchangeMode: boolean;
  isRightbarOpen: boolean;
  rightbarActiveLabel: string | null;
  userTypeId: number | null;
}

const defaultApplicationState: ApplicationState = {
  hasUserApprovalAccess: false,
  isSidebarOpen: true,
  isOpenExchangeMode: true,
  isRightbarOpen: true,
  rightbarActiveLabel: null,
  userTypeId: null,
};

function setUserApprovalAccess(state: ApplicationState, action: Action<boolean>): ApplicationState {
  return {
    ...state,
    hasUserApprovalAccess: action.payload,
  };
}

function toggleSidebar(state: ApplicationState): ApplicationState {
  return {
    ...state,
    isSidebarOpen: !state.isSidebarOpen,
  };
}

const toggleRightbar = (state: ApplicationState): ApplicationState => {
  return {
    ...state,
    isRightbarOpen: !state.isRightbarOpen,
  };
};

const setRightbarActiveLabel = (
  state: ApplicationState,
  action: Action<string | null>,
): ApplicationState => {
  return {
    ...state,
    rightbarActiveLabel: action.payload,
  };
};

function setUserTypeId(state: ApplicationState, action: Action<number>): ApplicationState {
  return {
    ...state,
    userTypeId: action.payload,
  };
}

const reducer = reducerFromMap<ApplicationState>(defaultApplicationState, {
  [applicationConstants.SET_USER_APPROVAL_ACCESS]: setUserApprovalAccess,
  [applicationConstants.TOGGLE_SIDEBAR_MENU]: toggleSidebar,
  [applicationConstants.TOGGLE_RIGHTBAR]: toggleRightbar,
  [applicationConstants.SET_RIGHTBAR_ACTIVE_LABEL]: setRightbarActiveLabel,
  [applicationConstants.SET_USER_TYPE_ID]: setUserTypeId,
});

export const app = (state: ApplicationState = defaultApplicationState, action: Action<any>) =>
  reducer(state, action);

import React from 'react';
import { Button } from 'factor';
import { connect } from 'react-redux';

import { AppState } from '../../../../store';
import { CampaignsActions, campaignsActions } from '../../../../store/campaigns/actions';
import { CategoriesSelect } from '../categoriesSelect';
import { Option } from '../../../../models/Option';

interface Props extends CampaignsActions {
  multipleItemsCategories: Option[];
  approve: () => void;
}

class ApproveMultipleCampaignsComponent extends React.Component<Props> {
  onCancel = () => {
    const { resetMultipleItemsCategories } = this.props;
    resetMultipleItemsCategories();
  };

  render() {
    const { multipleItemsCategories, setMultipleItemsCategories, approve } = this.props;

    return (
      <div className="pl-3 pr-3 pt-1 pb-1">
        <CategoriesSelect value={multipleItemsCategories} onChange={setMultipleItemsCategories} />
        <div className="d-flex justify-content-center mt-2">
          <Button className="btn-square _md _cornflower-blue mr-2" onClick={this.onCancel}>
            Cancel
          </Button>
          <Button
            className="btn-square _md _filled _cornflower-blue"
            onClick={approve}
            disabled={!multipleItemsCategories.length}
          >
            Approve
          </Button>
        </div>
      </div>
    );
  }
}

const mapState = (state: AppState) => ({
  multipleItemsCategories: state.campaigns.multipleItemsCategories,
  isSelectedCampaignsApprovalStart: state.campaigns.isSelectedCampaignsApprovalStart,
});

const mapActions = {
  setMultipleItemsCategories: campaignsActions.setMultipleItemsCategories,
  setSelectedCampaignsApprovalStart: campaignsActions.setSelectedCampaignsApprovalStart,
  resetMultipleItemsCategories: campaignsActions.resetMultipleItemsCategories,
};

export const ApproveMultipleCampaigns = connect(
  mapState,
  mapActions,
)(ApproveMultipleCampaignsComponent);

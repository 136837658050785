/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import { Logger } from '../utils/logger';
import { getInstance } from './Instance';

export interface Exchange {
  id: number;
  name: string;
}

export const FetchExchanges = async (): Promise<Exchange[]> => {
  try {
    const response: AxiosResponse<Exchange[]> = await getInstance().get('/exchanges');

    return response.data;
  } catch (e) {
    Logger.log('Error while getting exchanges', e);
    return [];
  }
};

import React from 'react';

import styles from './styles.module.scss';

interface Props {
  style?: {
    [key: string]: any;
  };
}

export const Skeleton = ({ style }: Props) => {
  return <div className={styles.skeleton} style={style} />;
};

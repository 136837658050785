import { Action } from '../../models/Action';
import { reducerFromMap } from '../../utils/actions';
import { sidebarConstants } from './constants';

export interface SidebarState {
  activeSidebarListLabel: 'Deals' | 'Campaigns';
}

export const defaultSidebarState: SidebarState = {
  activeSidebarListLabel: 'Deals',
};

const setActiveSidebarListLabel = (
  state: SidebarState,
  action: Action<SidebarState['activeSidebarListLabel']>,
): SidebarState => {
  return {
    ...state,
    activeSidebarListLabel: action.payload,
  };
};

const reducer = reducerFromMap<SidebarState>(defaultSidebarState, {
  [sidebarConstants.SET_ACTIVE_SIDEBAR_LIST_LABEL]: setActiveSidebarListLabel,
});

export const sidebar = (state: SidebarState = defaultSidebarState, action: Action<any>) =>
  reducer(state, action);

import { PrivateDealsCount } from '../api/PrivateDeals';

const COUNT_MAP_ARR: Array<{
  fetchedKey: keyof PrivateDealsCount;
  tabLabel: string;
  iconName: string;
}> = [
  { fetchedKey: 'All', tabLabel: 'All', iconName: 'StatusAllColor' },
  { fetchedKey: 'inactive', tabLabel: 'Inactive', iconName: 'StatusPausedColor' },
  { fetchedKey: 'inUse', tabLabel: 'In-use', iconName: 'StatusRunningColor' },
  { fetchedKey: 'unused', tabLabel: 'Unused', iconName: 'StatusDeletedColor' },
  { fetchedKey: 'rejected', tabLabel: 'Rejected', iconName: 'StatusRejectedColor' },
  { fetchedKey: 'requested', tabLabel: 'Requested', iconName: 'StatusDraftColor' },
];

export const transformDealsCount = (privateDealsCount: PrivateDealsCount) => {
  return COUNT_MAP_ARR.map((item) => ({
    ...item,
    count: privateDealsCount[item.fetchedKey] || '-',
  }));
};

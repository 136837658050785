import { createAction } from 'utils/actions';
import { applicationConstants } from './constants';

export interface SetUserApprovalAccess {
  setUserApprovalAccess: (has: boolean) => void;
}

export interface ToggleSidebar {
  toggleSidebar: () => void;
}

export interface SetUserTypeId {
  setUserTypeId: (id: number) => void;
}

export const applicationActions = {
  setUserApprovalAccess(has: boolean) {
    return createAction<boolean>(applicationConstants.SET_USER_APPROVAL_ACCESS, has);
  },
  toggleSidebar() {
    return createAction<void>(applicationConstants.TOGGLE_SIDEBAR_MENU);
  },
  toggleRightbar(value: boolean) {
    return createAction<boolean>(applicationConstants.TOGGLE_RIGHTBAR, value);
  },
  setRightbarActiveLabel(value: string | null) {
    return createAction<string | null>(applicationConstants.SET_RIGHTBAR_ACTIVE_LABEL, value);
  },
  setUserTypeId(id: number) {
    return createAction<number>(applicationConstants.SET_USER_TYPE_ID, id);
  },
};

import React from 'react';

import { createAction } from '../../utils/actions';
import { dialogConstants } from './constants';

export interface DialogActions {
  openDialog: (dialog: { content: React.ReactNode; className?: string }) => void;
  closeDialog: () => void;
  setRejectionReason: (reason: string) => void;
}

export const dialogActions = {
  openDialog(dialog: { content: React.ReactNode; className?: string }) {
    return createAction(dialogConstants.OPEN_DIALOG, dialog);
  },
  closeDialog() {
    return createAction(dialogConstants.CLOSE_DIALOG);
  },
  setRejectionReason(reason: string) {
    return createAction(dialogConstants.SET_REJECTION_REASON, reason);
  },
};

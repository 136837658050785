export const privateDealsConstants = {
  SET_PRIVATE_DEALS: 'SET_PRIVATE_DEALS',
  ADD_PRIVATE_DEALS: 'ADD_PRIVATE_DEALS',
  SET_PRIVATE_DEALS_PARAMS: 'SET_PRIVATE_DEALS_PARAMS',
  SET_PRIVATE_DEALS_STATUS: 'SET_PRIVATE_DEALS_STATUS',
  FETCH_PRIVATE_DEALS: 'FETCH_PRIVATE_DEALS',
  SET_PRIVATE_DEALS_FETCHING: 'SET_PRIVATE_DEALS_FETCHING',
  SELECT_DESELECT_PRIVATE_DEAL: 'SELECT_DESELECT_PRIVATE_DEAL',
  SELECT_DESELECT_ALL_PRIVATE_DEALS: 'SELECT_DESELECT_ALL_PRIVATE_DEALS',
  SET_PRIVATE_DEALS_COUNT: 'SET_PRIVATE_DEALS_COUNT',
};

import axios, { AxiosResponse, Canceler } from 'axios';
import { Logger } from '../utils/logger';
import { getInstance } from './Instance';
import { GetResponse } from '../models/Response';

/* eslint-disable */
export type Params = {
  searchField?: string;
  pageNo: number;
  status?: string;
  noOfEntries?: number;
  advertiserIds?: string | null;
  creativeTypeIds?: string | null;
  campaignIds?: string | null;

  filter_dsp?: string;
  filter_adv?: string;
  filter_camp?: string;
  sortBy?: string;
  order?: string;
  draw?: number;
};
/* eslint-enable */
export type Creatives = {
  data: Creative[];
  filteredRecords: number | null;
  totalRecords: number | null;
};
/* eslint-disable */
export type Creative = {
  dsp_id: number;
  mime: string | number | null;
  vast_xml_code: string | number | null;
  vast_type: string | number | null;
  id: number;
  campaign_name: string;
  name: string;
  duration: string | number | null;
  description: string | number | null;
  advertiser_id: 4;
  status: string;
  company_name: string;
  vast_version: string | number | null;
  ticker_meta: string | number | null;
  height: number;
  creative_categories: string | number | null;
  pixelUrl: string | number | null;
  width: number;
  creative_size: string;
  createdOn: number;
  creative_framework: string;
  ticker_html_source: string | number | null;
  advertiser_name: string;
  title: string | number | null;
  html_source: string;
  creative_type: string;
  excluded: number;
  advertiser_email: string;
  image_url: string | number | null;
  clickUrl: string | number | null;
  status_lists: {
    running: string;
    deleted: string;
    rejected: string;
  };
  remark: string | number | null;
  creativeCardSource: string;
  remote_url: string | number | null;
  campaign_id: number;
  isSelected: boolean;
  advertiserId: number;
  advertiserName: string;
  associatedCampaignIds?: string;
};

export type ApproveParams = {
  creativeIds: string;
};

export type RejectParams = {
  creativeIds: string;
  rejectionReason: string;
};
/* eslint-enable */
let cancel: Canceler;

export const FetchCreatives = async (params: Params): Promise<any> => {
  if (cancel) {
    cancel('Canceled by the user');
  }
  try {
    const response: AxiosResponse<GetResponse<Creatives>> = await getInstance().get(
      '/v2/crt/creative/listByDsp',
      {
        params: {
          status: 1,
          ...params,
        },
        cancelToken: new axios.CancelToken((c) => {
          cancel = c;
        }),
      },
    );

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while getting creatives', e);
    return {};
  }
};

export const ApproveCreatives = async (params: ApproveParams): Promise<any> => {
  try {
    const response: AxiosResponse<GetResponse<Creatives>> = await getInstance().put(
      '/v2/crt/creative/update-status',
      {
        ...params,
        status: 'running',
      },
    );

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while approving creatives', e);
    return {};
  }
};

export const RejectCreatives = async (params: RejectParams): Promise<any> => {
  try {
    const response: AxiosResponse<GetResponse<Creatives>> = await getInstance().put(
      '/v2/crt/creative/update-status',
      {
        ...params,
        status: 'rejected',
      },
    );

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while rejecting creatives', e);
    return {};
  }
};

import { User } from '../../models/User';
import { createAction } from '../../utils/actions';
import { authConstants } from './constants';
import { createIqmInstance } from '../../api/Instance';

export const authActions = {
  authorized(user: User) {
    createIqmInstance({
      token: user.apiToken,
      baseURL: user.domainURL,
    });
    return createAction<User>(authConstants.LOGIN_SUCCESS, user);
  },

  logout() {
    return createAction(authConstants.LOGOUT);
  },
};

export interface Authorized {
  authorized: (user: User) => void;
}

export interface Logout {
  logout: () => void;
}

import { all, put } from '@redux-saga/core/effects';

import { take } from 'redux-saga/effects';
import { API } from '../../api';
import { Data } from '../../api/CreatePrivateDeal';
import { fetchPrivateDeals, fetchPrivateDealsCount } from './privateDeals';
import { Action } from '../../models/Action';
import { snackbarActions } from '../snackbar/actions';
import { authConstants } from '../auth/constants';
import { privateDealActions } from '../privateDeal/actions';

export function* createPrivateDeal(action: Action<Data>) {
  const { statusCode, responseObject } = yield API.CreatePrivateDeal.CreatePrivateDeal(
    action.payload,
  );

  let message = '';
  // if status code is 2xx
  if (statusCode.toString().match(/^2\d{2}$/)) {
    message = `Private deal "${responseObject.dealName}" was successfully created!`;

    // refetch list with new item, fetch updated counts
    yield all([fetchPrivateDeals(), fetchPrivateDealsCount()]);
  } else {
    let errorMsg = '';
    if (Array.isArray(responseObject)) {
      errorMsg = responseObject.map((obj: { errorMsg: string }) => obj.errorMsg).join('; ');
    } else {
      errorMsg = responseObject.errorMsg;
    }
    message = `Private deal creation was failed with status code ${statusCode} and errors: ${errorMsg}`;
  }

  yield put(snackbarActions.openSnackbar({ message, duration: 10000 }));
}

export function* fetchPrivateDealTypes() {
  while (yield take(authConstants.LOGIN_SUCCESS)) {
    const { responseObject } = yield API.CreatePrivateDeal.fetchPrivateDealTypes();

    yield put(privateDealActions.setPrivateDealTypes(responseObject));
  }
}

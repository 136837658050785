import { put, select } from 'redux-saga/effects';

import { API } from '../../api';
import { privateDealsActions } from '../privateDeals/actions';
import { getPrivateDealsParams } from '../privateDeals/selectors';
import { PrivateDeals, PrivateDealsCount } from '../../api/PrivateDeals';
import { getAdvertiserId } from '../advertisers/selectors';

// using for initial load or
// when request params were changed
// and the whole list must be reloaded
export function* fetchPrivateDeals() {
  yield put(privateDealsActions.setPrivateDealsFetching(true));

  const params = yield select(getPrivateDealsParams);
  const advertiserId = yield select(getAdvertiserId);

  const privateDealsResponse: PrivateDeals = yield API.PrivateDeals.FetchPrivateDeals({
    ...params,
    ...(typeof advertiserId === 'number' ? { advertiserId } : {}),
    pageNo: 1,
  });

  yield put(privateDealsActions.setPrivateDeals(privateDealsResponse));
  yield put(privateDealsActions.setPrivateDealsParams({ pageNo: 2 }));
}

export function* fetchPrivateDealsCount() {
  const advertiserId = yield select(getAdvertiserId);

  const privateDealsCount: PrivateDealsCount = yield API.PrivateDeals.FetchPrivateDealsCount({
    ...(typeof advertiserId === 'number' ? { advertiserId } : {}),
  });

  yield put(privateDealsActions.setPrivateDealsCount(privateDealsCount));
}

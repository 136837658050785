import React from 'react';
import { connect } from 'react-redux';
import { ImageCard, ParamsCardsDate, Tumbler, Tooltip } from 'factor';
import { CreativeCard } from 'iqm-framework';
import moment from 'moment';

import styles from './styles.module.scss';
import { RightBarList } from '../rightBarList';
import { AppState } from '../../../../store';
import { Creative, Creatives } from '../../../../api/Creatives';
import { API } from '../../../../api';
import {
  creativesActions,
  CreativesActions,
  SetCreativesCompact,
} from '../../../../store/creatives/actions';
import { Skeleton } from '../../../shared/skeleton';
import { SnackbarActions, snackbarActions } from '../../../../store/snackbar/actions';
import { RejectDialog } from '../rejectDialog';
import { DialogActions, dialogActions } from '../../../../store/dialog/actions';
import { CustomerOption } from '../../../../models/Option';
import { CampaignsSelect } from '../campaignsSelect/CampaignsSelect';
import { RejectedTumbler } from '../rejectedTumbler';
import { SelectCreativeTypes } from '../selectCreativeTypes';

const SINGLE_ACTIONS = [
  { label: 'View', iconName: 'Impressions' },
  { label: 'Edit', iconName: 'Edit' },
  { label: 'Approve', iconName: 'Done' },
  { label: 'Decline', iconName: 'NoData' },
];

interface Props extends CreativesActions, SnackbarActions, DialogActions, SetCreativesCompact {
  creatives: Creatives;
  creativesParams: any;
  selectDeselectCreative: (id: number) => void;
  isCreativesFetching: boolean;
  selectDeselectAllCreatives: (shouldSelectAll: boolean) => void;
  rejectionReason: string;
  selectedAdvertiserOption: CustomerOption | null;
  isCompact: boolean;
}

interface State {
  searchField: string;
  expandedAssociatedCampaignIdsMap: { [key: number]: boolean };
}

class RightbarCreativesListComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchField: props.creativesParams.searchField,
      expandedAssociatedCampaignIdsMap: {} as State['expandedAssociatedCampaignIdsMap'],
    };
  }

  setImageCardActivity = (id: number) => () => {
    const { selectDeselectCreative } = this.props;

    selectDeselectCreative(id);
  };

  getTransformedData = () => {
    const {
      creatives: { data },
      selectedAdvertiserOption,
    } = this.props;

    if (!data?.length) {
      return [];
    }

    const transformedData: any[] = [];
    let currentBatch: { createdOn?: string; data: any[] } = { data: [] };
    transformedData.push(currentBatch);
    data.forEach((item: Creative) => {
      const createdOn: string = moment(item.createdOn).format('MM/DD/YYYY');
      if (!currentBatch.createdOn) {
        currentBatch.createdOn = createdOn;
      } else if (currentBatch.createdOn !== createdOn) {
        currentBatch = { createdOn, data: [] };
        transformedData.push(currentBatch);
      }

      // arrange name with or without subtitle
      let name: React.ReactNode = <>{item.name}</>;
      if (selectedAdvertiserOption === null) {
        name = (
          <>
            {item.name}
            <span className={styles.creativeSubtitle}>
              {item.advertiserId}/{item.advertiserName}
            </span>
          </>
        );
      }

      currentBatch.data.push({
        ...item,
        title: name,
      });
    });
    return transformedData;
  };

  selectDeselectAllCreatives = () => {
    const {
      creatives: { data },
      selectDeselectAllCreatives,
    } = this.props;
    const isNowSomethingSelected = data.filter((item: any) => item.isSelected).length;
    selectDeselectAllCreatives(!isNowSomethingSelected);
  };

  loadNewData = async () => {
    const {
      creatives,
      creativesParams,
      addCreatives,
      setCreativesParams,
      setCreativesFetching,
    } = this.props;
    if (
      creatives.data &&
      creatives.filteredRecords !== null &&
      creatives.data.length >= creatives.filteredRecords
    ) {
      return;
    }

    setCreativesFetching(true);

    const response = await API.Creatives.FetchCreatives(creativesParams);
    addCreatives(response);
    setCreativesParams({ pageNo: creativesParams.pageNo + 1 });
  };

  /* eslint-disable */
  renderSkeleton = () => {
    const { isCompact } = this.props;

    return (
      <>
        {[...new Array(4)].map((i, k) => (
          <ImageCard
            key={k}
            className={styles.imageCard}
            noImageElement={<Skeleton style={{ width: '100%', height: '100%' }} />}
            title={<Skeleton style={{ width: '5rem', height: `${17 / 16}rem` }} />}
            params={[...new Array(4)].map(() => ({
              label: <Skeleton style={{ width: '5rem', height: `${14 / 16}rem` }} />,
              value: <Skeleton style={{ width: '5rem', height: `${14 / 16}rem` }} />,
            }))}
            isSkeleton
            isCompact={isCompact}
          />
        ))}
      </>
    )
  };
  /* eslint-enable */

  approveCreative = (id: number) => async () => {
    const { openSnackbar, creativesApprovedOrRejected } = this.props;

    const response = await API.Creatives.ApproveCreatives({ creativeIds: `${id}` });
    if (response?.status) {
      openSnackbar({ message: `Creative with id ${id} was successfully approved` });
      creativesApprovedOrRejected();
    } else {
      openSnackbar({ message: `Creative with id ${id} approval was failed` });
    }
  };

  approveSelectedCreatives = async () => {
    const {
      creatives: { data },
      openSnackbar,
      creativesApprovedOrRejected,
    } = this.props;

    const selectedIds = data
      .filter((item: Creative) => item.isSelected)
      .map((item: Creative) => item.id);

    const response = await API.Creatives.ApproveCreatives({ creativeIds: selectedIds.join(',') });
    if (response?.status) {
      openSnackbar({
        message: `Creatives with ids ${selectedIds.join(', ')} were successfully approved`,
      });
      creativesApprovedOrRejected();
    } else {
      openSnackbar({ message: `Creatives with ids ${selectedIds.join(', ')} approval was failed` });
    }
  };

  reject = (ids: number[]) => async () => {
    const { openSnackbar, closeDialog, rejectionReason, creativesApprovedOrRejected } = this.props;

    closeDialog();
    const response = await API.Creatives.RejectCreatives({
      creativeIds: ids.join(','),
      rejectionReason,
    });
    const s = ids.length > 1 ? 's' : '';
    if (response?.status) {
      openSnackbar({
        message: `Creative${s} with id${s} ${ids.join(', ')} ${
          s ? 'were' : 'was'
        } successfully rejected`,
      });
      creativesApprovedOrRejected();
    } else {
      openSnackbar({ message: `Creative${s} with id${s} ${ids.join(', ')} rejection was failed` });
    }
  };

  rejectCreatives = (ids: number[]) => () => {
    const { openDialog } = this.props;

    openDialog({
      content: <RejectDialog ids={ids} reject={this.reject(ids)} type="Creative" />,
      className: styles.rejectDialog,
    });
  };

  getItemActions = (id: number) => {
    return SINGLE_ACTIONS.map((action) => {
      // eslint-disable-next-line no-console
      let onClick = () => console.log(`action ${action.label}`);

      if (action.label === 'Approve') {
        onClick = this.approveCreative(id);
      }

      if (action.label === 'Decline') {
        onClick = this.rejectCreatives([id]);
      }

      return {
        ...action,
        onClick,
      };
    });
  };

  onSearchChange = (searchField: string) => {
    const { setCreativesSearch } = this.props;
    this.setState({ searchField });
    setCreativesSearch(searchField);
  };

  setCompact = () => {
    const { isCompact, setCreativesCompact } = this.props;
    setCreativesCompact(!isCompact);
  };

  renderBeforeList = () => {
    const {
      creativesParams,
      setCreativesParams,
      setCreatives,
      setCreativesFetching,
      isCompact,
    } = this.props;

    return (
      <>
        <div className={styles.tumblers}>
          <RejectedTumbler
            params={creativesParams}
            setParams={setCreativesParams}
            setData={setCreatives}
            setFetching={setCreativesFetching}
            fetchData={API.Creatives.FetchCreatives}
          />
          <Tumbler title="Compact view" on={isCompact} onChange={this.setCompact} />
        </div>
        <div className={styles.creativeTypesSelect}>
          <SelectCreativeTypes />
        </div>
        <div className={styles.campaignsSelect}>
          <CampaignsSelect />
        </div>
      </>
    );
  };

  renderAssociatedCampaignIds = (creativeId: number, associatedCampaignIds?: string) => {
    const { expandedAssociatedCampaignIdsMap } = this.state;
    const campaignIds = associatedCampaignIds && associatedCampaignIds.split(',');

    if (!campaignIds || campaignIds.length < 6) {
      return campaignIds ? campaignIds.join(', ') : '—';
    }

    const onClick = (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();

      this.setState({
        expandedAssociatedCampaignIdsMap: {
          ...expandedAssociatedCampaignIdsMap,
          [creativeId]: true,
        },
      });
    };

    const isExpandedAssociatedCampaignIds = expandedAssociatedCampaignIdsMap[creativeId];
    const toggleLabel = !isExpandedAssociatedCampaignIds && ` and ${campaignIds.length - 5} more`;

    const displayedCampaignIds = isExpandedAssociatedCampaignIds
      ? campaignIds.join(', ')
      : campaignIds.slice(0, 5).join(', ');

    return (
      <Tooltip label={campaignIds.join(', ')} labelMaxWidth={200}>
        {displayedCampaignIds}{' '}
        {toggleLabel ? (
          <span className={styles.nMoreCreativesBtn} onClick={onClick}>
            {toggleLabel}
          </span>
        ) : null}
      </Tooltip>
    );
  };

  renderClickUrlsAsLabel = (clickUrl: string) => {
    return clickUrl ? (
      <div>
        <Tooltip label={clickUrl} auto labelMaxWidth={210}>
          <a
            onClick={(event: React.SyntheticEvent) => event.stopPropagation()}
            href={clickUrl}
            rel="noopener noreferrer"
            target="_blank"
            className={styles.cuttedURlLink}
          >
            {clickUrl}
          </a>
        </Tooltip>
      </div>
    ) : (
      '—'
    );
  };

  renderPixelUrlsAsLabel = (creativePixelUrls?: string[]) => {
    const pixelUrls = creativePixelUrls ? creativePixelUrls.join(',') : '';

    return creativePixelUrls ? (
      <Tooltip label={pixelUrls} auto labelMaxWidth={210}>
        <div className={styles.width140}>
          {creativePixelUrls.map((link: string, index: number) => {
            return (
              <React.Fragment key={link}>
                {index > 0 ? ', ' : ''}
                <a
                  onClick={(event: React.SyntheticEvent) => event.stopPropagation()}
                  href={link}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={styles.cuttedURlLink}
                >
                  {link}
                </a>
              </React.Fragment>
            );
          })}
        </div>
      </Tooltip>
    ) : (
      '—'
    );
  };

  render() {
    const {
      creatives: { data = [] },
      isCreativesFetching,
      creativesParams,
      isCompact,
    } = this.props;

    const { searchField } = this.state;

    const selectedIds = data
      .filter((item: Creative) => item.isSelected)
      .map((item: Creative) => item.id);

    const isSomethingSelected = !!selectedIds.length;

    const isRejectedCreatives = creativesParams.status === '5';

    return (
      <RightBarList
        search={searchField}
        onSearchChange={this.onSearchChange}
        selectDeselectAll={this.selectDeselectAllCreatives}
        isSomethingSelected={isSomethingSelected}
        loadData={this.loadNewData}
        isFetching={isCreativesFetching}
        skeleton={this.renderSkeleton()}
        approveSelected={this.approveSelectedCreatives}
        rejectSelected={this.rejectCreatives(selectedIds)}
        isRejected={isRejectedCreatives}
        beforeList={this.renderBeforeList()}
      >
        {this.getTransformedData().map((batch) => (
          <div
            key={batch.createdOn}
            className={`${styles.batch} ${isCompact ? styles.isCompact : ''}`}
          >
            <ParamsCardsDate className={styles.paramsCardsDate} date={batch.createdOn} />
            {batch.data.map((item: any) => (
              <CreativeCard
                className={!isCompact ? styles.imageCard : styles.imageCardCompact}
                creative={item}
                key={item.id}
                customURLParams
                additionalParams={[
                  {
                    label: 'Click URL:',
                    order: 70,
                    value: this.renderClickUrlsAsLabel(item.clickUrl),
                  },
                  {
                    label: 'Pixel URL:',
                    order: 80,
                    value: this.renderPixelUrlsAsLabel(item.pixelUrls),
                  },
                  {
                    label: 'Campaigns ID:',
                    order: 110,
                    value: this.renderAssociatedCampaignIds(item.id, item.associatedCampaignIds),
                  },
                ]}
                onClick={this.setImageCardActivity(item.id)}
                cardOptions={{
                  actions: !isRejectedCreatives ? this.getItemActions(item.id) : [],
                  isSelected: item.isSelected,
                  title: item.title,
                  isCompact,
                }}
              />
            ))}
          </div>
        ))}
      </RightBarList>
    );
  }
}

const mapState = (state: AppState) => {
  return {
    creatives: state.creatives.creatives,
    creativesParams: state.creatives.creativesParams,
    isCreativesFetching: state.creatives.isCreativesFetching,
    rejectionReason: state.dialog.rejectionReason,
    selectedAdvertiserOption: state.advertisers.selectedAdvertiserOption,
    isCompact: state.creatives.isCreativesCompact,
  };
};

const mapActions = {
  setCreatives: creativesActions.setCreatives,
  addCreatives: creativesActions.addCreatives,
  setCreativesParams: creativesActions.setCreativesParams,
  setCreativesFetching: creativesActions.setCreativesFetching,
  selectDeselectCreative: creativesActions.selectDeselectCreative,
  selectDeselectAllCreatives: creativesActions.selectDeselectAllCreatives,
  setCreativesSearch: creativesActions.setCreativesSearch,
  creativesApprovedOrRejected: creativesActions.creativesApprovedOrRejected,
  openSnackbar: snackbarActions.openSnackbar,
  openDialog: dialogActions.openDialog,
  closeDialog: dialogActions.closeDialog,
  setCreativesCompact: creativesActions.setCreativesCompact,
};

export const RightbarCreativesList = connect(mapState, mapActions)(RightbarCreativesListComponent);

import axios, { AxiosResponse, Canceler } from 'axios';
import { Logger } from '../utils/logger';
import { getInstance } from './Instance';
import { GetResponse } from '../models/Response';

/* eslint-disable */
export type Params = {
  pageNo: number;
  noOfEntries?: number;
  dealStatus: Array<keyof PrivateDealsCount>;
  advertiserId?: number;
};
/* eslint-enable */
export type PrivateDeals = {
  data: PrivateDeal[];
  filteredRecords: number | null;
  totalRecords: number | null;
};

export type PrivateDeal = {
  id: number;
  dealId: number;
  dealName: string;
  // description: string;
  // suggestedCPM: number;
  // creativeTypes: string[];
  // exchanges: string[];
  created: number;
  modifiedDate: string;
  dealsStatus: keyof PrivateDealsCount;

  isSelected?: boolean;
};

export interface PrivateDealsCount {
  All: number;
  inUse: number;
  inactive: number;
  rejected: number;
  requested: number;
  unused: number;
}

let cancel: Canceler;

export const FetchPrivateDeals = async (params: Params): Promise<any> => {
  if (cancel) {
    cancel('Canceled by the user');
  }
  try {
    const { dealStatus } = params;
    const response: AxiosResponse<GetResponse<PrivateDeals>> = await getInstance().get(
      '/v2/inv/private/deal/listByStatus',
      {
        params: {
          ...params,
          sortBy: 'modifiedDate',
          ...(dealStatus.length
            ? {
                dealStatus: dealStatus.filter((status) => status !== 'All').join(','),
              }
            : {}),
        },
        cancelToken: new axios.CancelToken((c) => {
          cancel = c;
        }),
      },
    );

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while getting private deals', e);
    return {};
  }
};

export const FetchPrivateDealsCount = async (params: { advertiserId?: number }): Promise<any> => {
  try {
    const response: AxiosResponse<GetResponse<
      PrivateDealsCount
    >> = await getInstance().get('/v2/inv/private/deal/countByStatus', { params });

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while getting private deals count', e);
    return {};
  }
};

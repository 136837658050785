import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { exchangesConstants } from './constants';
import { Exchange } from '../../api/Exchanges';

export interface ExchangesState {
  exchanges: Exchange[];
}

export const defaultExchangesState: ExchangesState = {
  exchanges: [],
};

function setExchanges(state: ExchangesState, action: Action<Exchange[]>): ExchangesState {
  return {
    ...state,
    exchanges: action.payload,
  };
}

const reducer = reducerFromMap<ExchangesState>(defaultExchangesState, {
  [exchangesConstants.SET_EXCHANGES]: setExchanges,
});

export const exchanges = (state: ExchangesState = defaultExchangesState, action: Action<any>) =>
  reducer(state, action);

import { all } from 'redux-saga/effects';

import { fetchCampaigns } from './campaigns';
import { fetchCreatives } from './creatives';

// using when request params were changed
// and the whole lists must be reloaded
export function* fetchCampaignsAndCreatives() {
  yield all([fetchCampaigns(), fetchCreatives()]);
}

import React from 'react';
import { Select, Button, TextField, Snackbar } from 'factor';

import styles from './styles.module.scss';
import { Option } from '../../../models/Option';

const roles = [
  { label: 'Admin', value: 'Admin' },
  { label: 'Manager', value: 'Manager' },
];

class MemberHeader extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      role: roles[0],
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      showChangesSnackbar: false,
    };
  }

  render() {
    const { name } = this.props;

    const {
      role,
      firstName,
      lastName,
      emailAddress,
      phoneNumber,
      showChangesSnackbar,
    } = this.state;

    return (
      <div className={styles.container}>
        <div className={`${styles.header}`}>
          <h2 className={`title-3 ${styles.name}`}>{name}</h2>
          <Select
            className={`${styles.headerSelect} mr-3`}
            value={role}
            options={roles}
            onChange={(v: Option) => this.setState({ role: v })}
          />
          <Button
            className={`btn-round _gray mr-3 mb-1 ${styles.btn}`}
            onClick={() => this.setState({ showChangesSnackbar: true })}
          >
            Reset Password
          </Button>
          <Button
            className={`btn-round _gray mb-1 ${styles.btn}`}
            onClick={() => this.setState({ showChangesSnackbar: true })}
          >
            Remove Member
          </Button>
        </div>
        <div className="block info mb-3">
          <h4 className="title-4 mb-3">Basic Info</h4>
          <div className="row">
            <div className="col-3">
              <TextField
                label="First Name"
                value={firstName}
                onChange={(v: string) => this.setState({ firstName: v })}
              />
            </div>
            <div className="col-3">
              <TextField
                label="Last Name"
                value={lastName}
                onChange={(v: string) => this.setState({ lastName: v })}
              />
            </div>
            <div className="col-3">
              <TextField
                label="Email Address"
                value={emailAddress}
                onChange={(v: string) => this.setState({ emailAddress: v })}
              />
            </div>
            <div className="col-3">
              <TextField
                label="Phone Number"
                value={phoneNumber}
                onChange={(v: string) => this.setState({ phoneNumber: v })}
              />
            </div>
          </div>
        </div>

        <Snackbar
          open={showChangesSnackbar}
          onClose={() => this.setState({ showChangesSnackbar: false })}
          message="2 Changes Made"
          action={[
            <span className={styles.snackbarBtn} key="undo">
              UNDO
            </span>,
          ]}
        />
      </div>
    );
  }
}

export default MemberHeader;

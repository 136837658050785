import axios, { AxiosInstance } from 'axios';

import { localStorageService } from '../services/localStorage';
import { HEADER_TOKEN_NAME } from '../config';

let iqmInstance: AxiosInstance;

export interface InstanceConfig {
  baseURL: string;
  token: string;
}

export function createIqmInstance(config: InstanceConfig) {
  const instance = axios.create({
    ...config,
    baseURL: `https://${config.baseURL}/api`,
    headers: {
      [HEADER_TOKEN_NAME]: config.token,
    },
  });

  instance.interceptors.response.use(
    (response: any) => {
      return response;
    },
    (response: any) => {
      if (axios.isCancel(response)) {
        const res = {
          response: {
            data: response,
          },
        };

        throw res;
      }
      if (response.response.status === 401) {
        localStorageService.removeBaseInfo();
        window.location.reload();
      }
      return Promise.reject(response);
    },
  );

  iqmInstance = instance;
}

export function getInstance(): AxiosInstance {
  return iqmInstance || axios;
}

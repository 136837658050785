import { put, select } from 'redux-saga/effects';

import { API } from '../../api';
import { Creatives } from '../../api/Creatives';
import { creativesActions } from '../creatives/actions';
import { getAdvertiserId } from '../advertisers/selectors';
import { getCreativesParams } from '../creatives/selectors';
import { getUserApprovalAccess } from '../app/selectors';
import { getSelectedCreativeTypes } from '../creativeTypes/selectors';
import { Option } from '../../models/Option';

// using when request params were changed
// and the whole list must be reloaded
export function* fetchCreatives() {
  const hasUserApprovalAccess = yield select(getUserApprovalAccess);
  const advertiserIds = yield select(getAdvertiserId);
  const selectedCreativeTypes = yield select(getSelectedCreativeTypes);
  const creativesParams = yield select(getCreativesParams);

  const creativeTypeIds = selectedCreativeTypes.map((type: Option<number>) => type.value).join(',');

  const commonParams = {
    pageNo: 1, // since the list is reloaded
    advertiserIds,
    creativeTypeIds,
  };

  if (hasUserApprovalAccess) {
    const creativesResponse: Creatives = yield API.Creatives.FetchCreatives({
      ...creativesParams,
      ...commonParams,
    });

    yield put(creativesActions.setCreatives(creativesResponse));
    yield put(
      creativesActions.setCreativesParams({
        ...commonParams,
        pageNo: 2, // to load next number of entries with the same params
      }),
    );
  }
}

import React from 'react';
import { CustomerSelect } from 'factor';
import { connect } from 'react-redux';

import { AppState } from '../../store';
import { Advertiser } from '../../api/Advertisers';
import { CustomerOption } from '../../models/Option';

interface Props {
  className?: string;
  advertisers: Advertiser[];
  searchField: string;
  value: CustomerOption[];
  onChange: (v: CustomerOption | null) => void;
  onSearchFieldChange: (v: string) => void;
  selectSpecificProps?: {};
  isSimpleBtn?: boolean;
}

class AdvertiserSelectComponent extends React.Component<Props> {
  onChange = (value: CustomerOption | null) => {
    const { onChange } = this.props;
    onChange(value);
  };

  getTransformAdvertisers = () => {
    const { advertisers, searchField } = this.props;

    let transformedAdvertisers = advertisers.map((advertiser: Advertiser) => ({
      label: advertiser.name,
      value: advertiser.id,
      email: advertiser.email,
      image: '', // no image got now
    }));

    // search searchField in label (name), value (id) and email
    if (searchField !== '') {
      transformedAdvertisers = transformedAdvertisers.filter(
        (advertiser: any) =>
          advertiser.label.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 ||
          advertiser.value
            .toString()
            .toLowerCase()
            .indexOf(searchField.toLowerCase()) !== -1 ||
          advertiser.email.toLowerCase().indexOf(searchField.toLowerCase()) !== -1,
      );
    }

    return transformedAdvertisers;
  };

  changeSearchHandler = (value: any) => {
    const { onSearchFieldChange } = this.props;
    const searchValue = typeof value === 'string' ? value : value?.nativeEvent?.target?.value;
    onSearchFieldChange(searchValue || '');
  };

  render() {
    const { className = '', value, selectSpecificProps, isSimpleBtn, advertisers } = this.props;

    return (
      <CustomerSelect
        isSimpleBtn={isSimpleBtn}
        className={className}
        options={this.getTransformAdvertisers()}
        onChange={this.onChange}
        value={value}
        selectSpecificProps={{
          placeholder: 'Select Advertiser',
          changeSearchHandler: this.changeSearchHandler,
          totalRecords: advertisers.length,
          ...selectSpecificProps,
        }}
      />
    );
  }
}

const mapState = (state: AppState) => ({
  advertisers: state.advertisers.advertisers,
});

export const AdvertiserSelect = connect(mapState)(AdvertiserSelectComponent);

import { Creative, Creatives, Params } from '../../api/Creatives';
import { Action } from '../../models/Action';
import { reducerFromMap } from '../../utils/actions';
import { creativesConstants } from './constants';
import { Option } from '../../models/Option';

export interface CreativesState {
  creatives: Creatives;
  creativesParams: Params;
  selectedCampaignsOptions: Option[];
  isCreativesFetching: boolean;
  isCreativesCompact: boolean;
}

export const defaultCreativesState: CreativesState = {
  creatives: {
    data: [],
    filteredRecords: null,
    totalRecords: null,
  },
  creativesParams: {
    pageNo: 1,
    noOfEntries: 10,
    sortBy: 'created',
    status: '1',
    searchField: '',
  },
  selectedCampaignsOptions: [],
  isCreativesFetching: false,
  isCreativesCompact: false,
};

const setCreatives = (state: CreativesState, action: Action<Creatives>): CreativesState => {
  return {
    ...state,
    creatives: action.payload,
    isCreativesFetching: false,
  };
};

const addCreatives = (state: CreativesState, action: Action<Creatives>): CreativesState => {
  return {
    ...state,
    creatives: {
      data: [...state.creatives.data, ...action.payload.data],
      filteredRecords: action.payload.filteredRecords,
      totalRecords: action.payload.totalRecords,
    },
    isCreativesFetching: false,
  };
};

const setCreativesParams = (
  state: CreativesState,
  action: Action<Partial<Params>>,
): CreativesState => {
  return {
    ...state,
    creativesParams: {
      ...state.creativesParams,
      ...action.payload,
    },
  };
};

const setSelectedCampaigns = (state: CreativesState, action: Action<Option[]>): CreativesState => {
  return {
    ...state,
    selectedCampaignsOptions: action.payload,
  };
};

const setCreativesFetching = (state: CreativesState, action: Action<boolean>): CreativesState => {
  return {
    ...state,
    isCreativesFetching: action.payload,
  };
};

const selectDeselectCreative = (state: CreativesState, action: Action<number>): CreativesState => {
  return {
    ...state,
    creatives: {
      filteredRecords: state.creatives.filteredRecords,
      totalRecords: state.creatives.totalRecords,
      data: state.creatives.data.map((item: Creative) => {
        if (item.id !== action.payload) {
          // This isn't the item we care about - keep it as-is
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          isSelected: !item.isSelected,
        };
      }),
    },
  };
};

const selectDeselectAllCreatives = (
  state: CreativesState,
  action: Action<boolean>,
): CreativesState => {
  return {
    ...state,
    creatives: {
      filteredRecords: state.creatives.filteredRecords,
      totalRecords: state.creatives.totalRecords,
      data: state.creatives.data.map((item: Creative) => {
        return {
          ...item,
          isSelected: action.payload,
        };
      }),
    },
  };
};

const setCreativesSearch = (state: CreativesState, action: Action<string>): CreativesState => {
  return {
    ...state,
    creativesParams: {
      ...state.creativesParams,
      pageNo: 2,
      searchField: action.payload,
    },
    isCreativesFetching: true,
  };
};

const setCreativesCompact = (state: CreativesState, action: Action<boolean>): CreativesState => {
  return {
    ...state,
    isCreativesCompact: action.payload,
  };
};

const reducer = reducerFromMap<CreativesState>(defaultCreativesState, {
  [creativesConstants.SET_CREATIVES]: setCreatives,
  [creativesConstants.ADD_CREATIVES]: addCreatives,
  [creativesConstants.SET_CREATIVES_PARAMS]: setCreativesParams,
  [creativesConstants.SET_SELECTED_CAMPAIGNS]: setSelectedCampaigns,
  [creativesConstants.SET_CREATIVES_FETCHING]: setCreativesFetching,
  [creativesConstants.SELECT_DESELECT_CREATIVE]: selectDeselectCreative,
  [creativesConstants.SELECT_DESELECT_ALL_CREATIVES]: selectDeselectAllCreatives,
  [creativesConstants.CREATIVES_LIST_SEARCH_CHANGE]: setCreativesSearch,
  [creativesConstants.SET_CREATIVES_COMPACT]: setCreativesCompact,
});

export const creatives = (state: CreativesState = defaultCreativesState, action: Action<any>) =>
  reducer(state, action);

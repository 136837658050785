import { createAction } from '../../utils/actions';
import { creativeTypesConstants } from './constants';
import { Option } from '../../models/Option';

export interface SetCreativeTypes {
  setCreativeTypes?: (list: Option<number>[]) => void;
}

export interface SelectCreativeTypes {
  selectCreativeTypes?: (list: Option<number>[]) => void;
}

export const creativeTypesActions = {
  setCreativeTypes(list: Option<number>[]) {
    return createAction(creativeTypesConstants.SET_CREATIVE_TYPES, list);
  },
  selectCreativeTypes(list: Option<number>[]) {
    return createAction(creativeTypesConstants.SELECT_CREATIVE_TYPES, list);
  },
};

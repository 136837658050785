import { DealTypes } from '../../api/CreatePrivateDeal';
import { Action } from '../../models/Action';
import { reducerFromMap } from '../../utils/actions';
import { privateDealConstants } from './constants';

export interface PrivateDealState {
  dealTypes: DealTypes;
}

export const defaultPrivateDealState: PrivateDealState = {
  dealTypes: [],
};

const setPrivateDealTypes = (
  state: PrivateDealState,
  action: Action<DealTypes>,
): PrivateDealState => {
  return {
    ...state,
    dealTypes: action.payload,
  };
};

const reducer = reducerFromMap<PrivateDealState>(defaultPrivateDealState, {
  [privateDealConstants.SET_PRIVATE_DEAL_TYPES]: setPrivateDealTypes,
});

export const privateDeal = (
  state: PrivateDealState = defaultPrivateDealState,
  action: Action<any>,
) => reducer(state, action);

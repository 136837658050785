import { put, take } from 'redux-saga/effects';

import { API } from 'api';
import { authConstants } from '../auth/constants';
import { CreativeType } from '../../api/CreativeTypes';
import { creativeTypesActions } from '../creativeTypes/actions';

export function* fetchCreativeTypes() {
  while (yield take(authConstants.LOGIN_SUCCESS)) {
    const creativeTypesFromServer = yield API.CreativeTypes.fetchCreativeTypes();
    const creativeTypes = creativeTypesFromServer.map((type: CreativeType) => ({
      label: type.name,
      value: type.id,
    }));
    yield put(creativeTypesActions.setCreativeTypes(creativeTypes));
  }
}

export enum CampaignInfoField {
  name = 'campaignName',
  group = 'campaignGroups',
  totalBudget = 'totalBudget',
  dailyBudget = 'dailyBudget',
  maxBid = 'maxBid',
  timezone = 'timezone',
  start = 'startDate',
  end = 'endDate',
  country = 'country',
  domain = 'domain',
  publishers = 'selectedPublishers',
  deviceTypes = 'selectedDeviceTypes',
  os = 'selectedOperetingSystems',
  carriers = 'selectedCarriers',
  network = 'selectedNetworkTypes',
  traffic = 'selectedTrafficTypes',
  manufacturers = 'selectedManufacturers',
  states = 'states',
  age = 'selectedAge',
  gender = 'selectedGender',
  language = 'selectedLanguage',
  interest = 'selectedInterest',
  incomeRange = 'selectedIncomeRange',
  nationality = 'selectedNationality',
  zipcodes = 'selectedZipCodes',
  csvZipcodes = 'selectedCsvZipCodes',
  geoRadiuses = 'selectedGeoRadiuses',
  dma = 'selectedDma',
  districts = 'selectedCongressionalDistricts',
  senates = 'selectedSenates',
  houses = 'selectedHouses',
  inventoryGroups = 'selectedInventoryGroups',
  dealGroups = 'selectedDealGroups',
  scheduling = 'selectedScheduling',
  exchanges = 'selectedExchanges',
  whiteListedDeviceId = 'selectedWhiteListedDeviceId',
  whiteListedIp = 'selectedWhiteListedIp',
  whiteListedAppId = 'selectedWhiteListedAppId',
  whiteListedPackageName = 'selectedWhiteListedPackageName',
  whiteListedSiteDomain = 'selectedWhiteListedSiteDomain',
  blackListedDeviceId = 'selectedBlackListedDeviceId',
  blackListedIp = 'selectedBlackListedIp',
  blackListedAppId = 'selectedBlackListedAppId',
  blackListedPackageName = 'selectedBlackListedPackageName',
  blackListedSiteDomain = 'selectedBlackListedSiteDomain',
}

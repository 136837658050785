import { SidebarState } from './reducers';
import { createAction } from '../../utils/actions';
import { sidebarConstants } from './constants';

export type SetActiveSidebarListLabel = (label: SidebarState['activeSidebarListLabel']) => void;

export const sidebarActions = {
  setActiveSidebarListLabel(label: SidebarState['activeSidebarListLabel']) {
    return createAction(sidebarConstants.SET_ACTIVE_SIDEBAR_LIST_LABEL, label);
  },
};

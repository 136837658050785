import React from 'react';

import CheckboxList from 'components/shared/CheckboxList/CheckboxList';
import styles from './styles.module.scss';

const optionsData = [
  {
    title: 'Manage',
    list: ['Advertisers', 'Campaigns', 'Creatives', 'Header Bidding', 'Custom Audience'],
  },
  {
    title: 'Finance Access',
    list: ['Overview', 'Manage Payments', 'Manage Refunds', 'Manage Credit', 'Manage Invoice'],
  },
  {
    title: 'Hide',
    list: [
      'View User Profiles',
      'Customize Profile',
      'View Performance',
      'Finance Data',
      'Earnings',
    ],
  },
  {
    title: 'App Access',
    list: ['Finance', 'Exchanges', 'Segment', 'Report Builder', 'Campaign Edit Budget'],
  },
];

class Options extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      checkboxes: optionsData.map((i) => {
        return {
          title: i.title,
          list: i.list.map((ii) => ({ label: ii, checked: true })),
        };
      }),
    };
  }

  onCheckboxListChange = (i: any) => (k: number) => {
    const { checkboxes } = this.state;
    /* eslint-disable-next-line */
    i.list[k].checked = !i.list[k].checked;
    this.setState({ checkboxes });
  };

  onAllCheckboxListChange = (i: any) => (allSelected: boolean) => {
    const { checkboxes } = this.state;
    /* eslint-disable-next-line */
    i.list.forEach((ii: any) => { ii.checked = !allSelected; });
    this.setState({ checkboxes });
  };

  render() {
    const { className, isAccountOwner } = this.props;
    const { checkboxes } = this.state;

    return (
      <div
        className={`block ${styles.container} ${className || ''} ${
          isAccountOwner ? '_disabled' : ''
        }`}
      >
        <div className="row">
          {/* eslint-disable-next-line */
            checkboxes.map((i: any, k: number) => (<div className="col-3" key={k}>
              <CheckboxList
                disabled={isAccountOwner}
                data={i}
                onChange={this.onCheckboxListChange(i)}
                onAllChange={this.onAllCheckboxListChange(i)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Options;

export const ROLE_LIST = [
  { label: 'Account Owner', value: 'Account Owner', assignees: 'Tim Wilson' },
  { label: 'Admin', value: 'Admin', assignees: '3' },
  { label: 'Advertiser', value: 'Advertiser', assignees: '25' },
  { label: 'Sr Advertiser', value: 'Sr Advertiser', assignees: '5' },
  { label: 'Creator', value: 'Creator', assignees: '4' },
  { label: 'Data Scientist', value: 'Data Scientist', assignees: '2' },
  { label: 'Strategist', value: 'Strategist', assignees: '1' },
];

export const MEMBER_LIST = [
  { label: 'Ivan Guerrero', value: 'Ivan Guerrero', date: '08/30/2019' },
  { label: 'Bernice Arnold', value: 'Bernice Arnold', date: '11/06/2019' },
  { label: 'Alice Leonard', value: 'Alice Leonard', date: '09/04/2019' },
  { label: 'Rosalie Porter', value: 'Rosalie Porter', date: '10/07/2019' },
  { label: 'Eva Wilkins', value: 'Eva Wilkins', date: '06/08/2019' },
  { label: 'Anne Webb', value: 'Anne Webb', date: '02/20/2019' },
  { label: 'Rena Brock', value: 'Rena Brock', date: '07/26/2019' },
];

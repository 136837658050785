import React from 'react';
import { Checkbox } from 'factor';

import styles from './styles.module.scss';
import { ListOption } from '../../../models/ListOption';

interface Props {
  className?: string;
  header?: any[];
  list: ListOption[];
  rightColumnField?: string;
  onChange?: (option: ListOption) => void;
  value?: ListOption | null;
  onCheckboxChange?: (option: ListOption, isAll?: boolean) => void;
}

export const List = (props: Props) => {
  const {
    className,
    header,
    list,
    rightColumnField,
    /* eslint-disable-next-line */
    onChange = (option: ListOption) => {},
    value,
    /* eslint-disable-next-line */
    onCheckboxChange = (option: ListOption) => {},
  } = props;

  const onLiClick = (liValue: ListOption) => () => {
    onChange(liValue);
  };

  const handleCheckboxChange = (option: ListOption) => () => onCheckboxChange(option);

  const valueField: string = rightColumnField || 'value';

  return (
    <div className={styles.container}>
      <ul className={`${styles.list} ${className || ''}`}>
        {header && (
          <li className={`${styles.item} _header`}>
            {header.map((i) => (
              <div className={styles.th} key={i}>
                {i}
              </div>
            ))}
          </li>
        )}
        {list.map((option) => {
          const liClassNames = [styles.item];
          if (option.value === value?.value) {
            liClassNames.push('_active');
          }
          if (option.checked) {
            liClassNames.push('_checked');
          }

          return (
            <li className={liClassNames.join(' ')} key={option.value} onClick={onLiClick(option)}>
              <div className={styles.label}>
                {/* eslint-disable-next-line */
                  option.hasOwnProperty('checked') && (
                  <Checkbox
                    className="mr-3"
                    checked={option.checked}
                    onChange={handleCheckboxChange(option)}
                  />
                )}
                {option.dot && <span className={`${styles.dot} _${option.dot}`} />}
                {option.label}
              </div>
              <div className={styles.value}>{option[valueField]}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

import React from 'react';
import { Select } from 'factor';
import { connect } from 'react-redux';

import { AppState } from '../../../../store';
import {
  creativeTypesActions,
  SelectCreativeTypes as SelectCreativeTypesInterface,
} from '../../../../store/creativeTypes/actions';
import { Option } from '../../../../models/Option';

interface Props extends SelectCreativeTypesInterface {
  creativeTypesList: Option<number>[];
  selectedCreativeTypes: Option<number>[];
}

const SelectCreativeTypesComponent = (props: Props) => {
  const { creativeTypesList, selectedCreativeTypes, selectCreativeTypes } = props;

  return (
    <Select
      label="Creative Types"
      placeholder="Select Creative Types"
      options={creativeTypesList}
      value={selectedCreativeTypes}
      onChange={selectCreativeTypes}
      isMulti
      isClearable
    />
  );
};

const mapState = (state: AppState) => {
  return {
    creativeTypesList: state.creativeTypes.creativeTypesList,
    selectedCreativeTypes: state.creativeTypes.selectedCreativeTypes,
  };
};

const mapActions = {
  selectCreativeTypes: creativeTypesActions.selectCreativeTypes,
};

export const SelectCreativeTypes = connect(mapState, mapActions)(SelectCreativeTypesComponent);

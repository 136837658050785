import React from 'react';

import styles from './styles.module.scss';
import SettingsBasicInfo from './SettingsBasicInfo/SettingsBasicInfo';
import SettingsAddresses from './SettingsAddresses/SettingsAddresses';
import SettingsPaymentMethods from './SettingsPaymentMethods/SettingsPaymentMethods';
import DefaultCustomerSettings from './DefaultCustomerSettings/DefaultCustomerSettings';
import CustomerPaymentOptions from './CustomerPaymentOptions/CustomerPaymentOptions';
import LandingPageCustomizations from './LandingPageCustomizations/LandingPageCustomizations';
import { Layout } from '../../components/Layout';
import { MainContent } from '../../components/MainContent';

export const Settings = () => (
  <Layout>
    <MainContent>
      <div className={styles.container}>
        <div className="row mb-4">
          <div className="col-6">
            <SettingsBasicInfo />
          </div>
          <div className="col-6 d-flex flex-column align-items-stretch">
            <SettingsAddresses className="mb-2" />
            <SettingsPaymentMethods className="flex-grow-1" />
          </div>
        </div>
        <DefaultCustomerSettings />
        <div className="row mt-4 mb-4">
          <div className="col-6">
            <CustomerPaymentOptions />
          </div>
        </div>
        <LandingPageCustomizations />
      </div>
    </MainContent>
  </Layout>
);

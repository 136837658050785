import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Layout as FactorLayout } from 'factor';

import styles from './styles.module.scss';
import { applicationActions } from '../../store/app/actions';
import { AppState } from '../../store';
import { Rightbar } from '../Rightbar';

interface Props {
  mainClassName?: string;
  sidebarClassName?: string;
  rightbarClassName?: string;
  children: React.ReactNode;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  sidebar?: React.ReactNode;
  isRightbarOpen: boolean;
  toggleRightbar: (value: boolean) => void;
  rightbarActiveLabel: string | null;
  setRightbarActiveLabel: (value: string | null) => void;
  hasUserApprovalAccess: boolean;
}

class LayoutComponent extends PureComponent<Props> {
  render() {
    const {
      mainClassName = '',
      sidebarClassName = '',
      rightbarClassName = '',
      children,
      isSidebarOpen,
      toggleSidebar,
      sidebar,
      isRightbarOpen,
      toggleRightbar,
      rightbarActiveLabel,
      setRightbarActiveLabel,
      hasUserApprovalAccess,
    } = this.props;

    const rightbar = hasUserApprovalAccess
      ? {
          isRightbarOpen,
          toggleRightbar,
          isRightbarExpanded: rightbarActiveLabel !== null,
          rightbarChildren: (
            <Rightbar activeLabel={rightbarActiveLabel} setActiveLabel={setRightbarActiveLabel} />
          ),
        }
      : {};

    return sidebar ? (
      <FactorLayout
        mainClassName={mainClassName}
        sidebarClassName={sidebarClassName}
        rightbarClassName={`${rightbarClassName} ${styles.zIndex21}`}
        opened={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        sidebar={sidebar}
        isRightbarOpen={rightbar.isRightbarOpen}
        toggleRightbar={rightbar.toggleRightbar}
        isRightbarExpanded={rightbar.isRightbarExpanded}
        rightbarChildren={rightbar.rightbarChildren}
      >
        {children}
      </FactorLayout>
    ) : (
      <div className={styles.sidebarLess}>{children}</div>
    );
  }
}

const mapState = (state: AppState) => ({
  hasUserApprovalAccess: state.app.hasUserApprovalAccess,
  isSidebarOpen: state.app.isSidebarOpen,
  isRightbarOpen: state.app.isRightbarOpen,
  rightbarActiveLabel: state.app.rightbarActiveLabel,
});

const mapActions = {
  toggleSidebar: applicationActions.toggleSidebar,
  toggleRightbar: applicationActions.toggleRightbar,
  setRightbarActiveLabel: applicationActions.setRightbarActiveLabel,
};

export const Layout = connect(mapState, mapActions)(LayoutComponent);

import { put } from 'redux-saga/effects';

import { API } from '../../api';
import { campaignsActions } from '../campaigns/actions';
import { CampaignCount } from '../../api/Campaigns';

export function* fetchCampaignsCountByStatus() {
  const response: CampaignCount[] = yield API.Campaigns.fetchCampaignsCountByStatus();

  yield put(campaignsActions.setCampaignsCountByStatus(response));
}

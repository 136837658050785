import { AppState } from 'store';

export const getCreativesApprovalCount = (state: AppState) =>
  // with not empty searchField number of filtered creatives is returned
  // in filteredRecords property
  state.creatives.creativesParams.searchField
    ? state.creatives.creatives?.filteredRecords
    : state.creatives.creatives?.totalRecords;

export const getCreativesParams = (state: AppState) => state.creatives.creativesParams;

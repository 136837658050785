import { createAction } from '../../utils/actions';
import { campaignsConstants } from './constants';
import { CampaignCount, Campaigns, Params } from '../../api/Campaigns';
import { Option } from '../../models/Option';

export interface CampaignsActions {
  setCampaigns: (campaigns: Campaigns) => void;
  addCampaigns: (campaigns: Campaigns) => void;
  setCampaignsParams: (params: Partial<Params>) => void;
  setCampaignsFetching: (isFetching: boolean) => void;
  setCampaignsSearch: (searchField: string) => void;
  setCampaignCategories: ({ id, categories }: { id: number; categories: Option[] }) => void;
  setSelectedCampaignsApprovalStart: (value: boolean) => void;
  setMultipleItemsCategories: (value: Option[]) => void;
  resetMultipleItemsCategories: () => void;
}

export interface SetCampaignsParams {
  setCampaignsParams: (params: Partial<Params>) => void;
}

export interface SetCampaignsCountByStatus {
  setCampaignsCountByStatus: (count: CampaignCount[]) => void;
}

export const campaignsActions = {
  setCampaigns(campaigns: Campaigns) {
    return createAction(campaignsConstants.SET_CAMPAIGNS, campaigns);
  },
  addCampaigns(campaigns: Campaigns) {
    return createAction(campaignsConstants.ADD_CAMPAIGNS, campaigns);
  },
  setCampaignsParams(params: Partial<Params>) {
    return createAction(campaignsConstants.SET_CAMPAIGNS_PARAMS, params);
  },
  setCampaignsFetching(isFetching: boolean) {
    return createAction(campaignsConstants.SET_CAMPAIGNS_FETCHING, isFetching);
  },
  selectDeselectCampaign(value: number) {
    return createAction(campaignsConstants.SELECT_DESELECT_CAMPAIGN, value);
  },
  selectDeselectAllCampaigns(value: boolean) {
    return createAction(campaignsConstants.SELECT_DESELECT_ALL_CAMPAIGNS, value);
  },
  setCampaignsSearch(searchField: string) {
    return createAction(campaignsConstants.CAMPAIGNS_LIST_SEARCH_CHANGE, searchField);
  },
  setCampaignCategories(value: { id: number; categories: Option[] }) {
    return createAction(campaignsConstants.SET_CAMPAIGN_CATEGORIES, value);
  },
  setSelectedCampaignsApprovalStart(value: boolean) {
    return createAction(campaignsConstants.SELECTED_CAMPAIGNS_APPROVAL_START, value);
  },
  setMultipleItemsCategories(value: Option[]) {
    return createAction(campaignsConstants.MULTIPLE_ITEMS_CATEGORIES_CHANGE, value);
  },
  resetMultipleItemsCategories() {
    return createAction(campaignsConstants.RESET_MULTIPLE_ITEMS_CATEGORIES);
  },
  setCampaignsCountByStatus(count: CampaignCount[]) {
    return createAction(campaignsConstants.SET_CAMPAIGNS_COUNT_BY_STATUS, count);
  },
};

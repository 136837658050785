import { takeEvery, fork, all, takeLatest } from 'redux-saga/effects';

import { fetchPublishers } from './publishers';
import { publishersConstants } from '../publishers/constants';
import loginSuccess from './loginSuccess';
import { advertisersConstants } from '../advertisers/constants';
import { fetchCampaignsAndCreatives } from './campaignsAndCreatives';
import fetchAdvertisers from './advertisers';
import { creativesConstants } from '../creatives/constants';
import { creativeTypesConstants } from '../creativeTypes/constants';
import { fetchCreativeTypes } from './creativeTypes';
import { campaignsConstants } from '../campaigns/constants';
import { fetchCampaigns } from './campaigns';
import { fetchCreatives } from './creatives';
import { fetchExchanges } from './exchanges';
import { fetchIqmDealId } from './iqmDealId';
import { privateDealConstants } from '../privateDeal/constants';
import { createPrivateDeal, fetchPrivateDealTypes } from './privateDeal';
import { fetchPrivateDeals, fetchPrivateDealsCount } from './privateDeals';
import { privateDealsConstants } from '../privateDeals/constants';

export function* watchPublishers() {
  yield takeEvery(publishersConstants.FETCH_PUBLISHERS, fetchPublishers);
}

export function* watchAdvertisers() {
  yield takeEvery(advertisersConstants.SELECT_ADVERTISER, fetchCampaignsAndCreatives);
  yield takeEvery(advertisersConstants.SELECT_ADVERTISER, fetchPrivateDealsCount);
  yield takeEvery(advertisersConstants.SELECT_ADVERTISER, fetchPrivateDeals);
}

export function* watchCreativesApprovedOrRejected() {
  yield takeLatest(creativesConstants.CREATIVES_APPROVED_OR_REJECTED, fetchCampaignsAndCreatives);
}

export function* watchCreativeTypes() {
  yield takeLatest(creativeTypesConstants.SELECT_CREATIVE_TYPES, fetchCampaignsAndCreatives);
}

export function* watchCampaignsSearchField() {
  yield takeLatest(campaignsConstants.CAMPAIGNS_LIST_SEARCH_CHANGE, fetchCampaigns);
}

export function* watchCreativesSearchField() {
  yield takeLatest(creativesConstants.CREATIVES_LIST_SEARCH_CHANGE, fetchCreatives);
}

export function* watchCreativesCampaignsIdsChange() {
  yield takeLatest(creativesConstants.SET_SELECTED_CAMPAIGNS, fetchCreatives);
}

export function* watchCreatPrivateDeal() {
  yield takeLatest(privateDealConstants.CREATE_PRIVATE_DEAL, createPrivateDeal);
}

export function* watchPrivateDealsStatusChange() {
  yield takeLatest(privateDealsConstants.SET_PRIVATE_DEALS_STATUS, fetchPrivateDeals);
}

export function* watchCampaignsParamsChange() {
  yield takeLatest(campaignsConstants.SET_CAMPAIGNS_PARAMS, fetchCampaigns);
}

export function* fetchInitialAdvertisers() {
  yield fork(fetchAdvertisers);
}

export function* fetchInitialCreativeTypes() {
  yield fork(fetchCreativeTypes);
}

export function* fetchInitialExchanges() {
  yield fork(fetchExchanges);
}

export function* fetchInitialIqmDealId() {
  yield fork(fetchIqmDealId);
}

export function* fetchInitialPrivateDealTypes() {
  yield fork(fetchPrivateDealTypes);
}

export function* watchLogin() {
  yield fork(loginSuccess);
}

export function* rootSaga() {
  yield all([
    watchPublishers(),
    watchLogin(),
    watchAdvertisers(),
    watchCreativesApprovedOrRejected(),
    watchCreativeTypes(),
    watchCampaignsSearchField(),
    watchCreativesSearchField(),
    watchCreativesCampaignsIdsChange(),
    watchCreatPrivateDeal(),
    watchPrivateDealsStatusChange(),
    watchCampaignsParamsChange(),
    fetchInitialAdvertisers(),
    fetchInitialCreativeTypes(),
    fetchInitialExchanges(),
    fetchInitialIqmDealId(),
    fetchInitialPrivateDealTypes(),
  ]);
}

import { put, take } from 'redux-saga/effects';

import { API } from 'api';
import { exchangeActions } from '../exchanges/actions';
import { authConstants } from '../auth/constants';

export function* fetchExchanges() {
  while (yield take(authConstants.LOGIN_SUCCESS)) {
    const exchanges = yield API.Exchanges.FetchExchanges();
    yield put(exchangeActions.setExchanges(exchanges));
  }
}

import React from 'react';
import { connect } from 'react-redux';
import { RightbarTabs, RightbarTab, SidebarHeader, Tabs } from 'factor';

import { getCreativesApprovalCount } from 'store/creatives/selectors';
import { getCampaignsApprovalCount } from 'store/campaigns/selectors';

import styles from './styles.module.scss';
import { RightbarCreativesList } from './components/rightbarCreativesList';
import { rightbarActions } from '../../store/rightbar/actions';
import { AppState } from '../../store';
import { creativesActions } from '../../store/creatives/actions';
import { CampaignsList } from './components/campaignsList';

const TABS = [
  { title: 'Campaigns', value: 'Campaigns' },
  { title: 'Creatives', value: 'Creatives' },
];

interface Props {
  activeLabel: string | null;
  setActiveLabel: (activeLabel: string | null) => void;
  activeApproveTabLabel: string | null;
  setActiveApproveTabLabel: (activeLabel: string | null) => void;
  isRightbarOpen: boolean;
  approvalCount: number | null;
  campaignsApprovalCount: number | null;
  creativesApprovalCount: number | null;
}

class RightbarComponent extends React.Component<Props> {
  closeTab = () => {
    const { setActiveLabel } = this.props;
    setActiveLabel(null);
  };

  handleTabChange = (tab: { title: string; value: string }) => {
    const { setActiveApproveTabLabel } = this.props;

    setActiveApproveTabLabel(tab.title);
  };

  renderApprove = () => {
    const { activeLabel, activeApproveTabLabel } = this.props;

    return (
      <>
        <SidebarHeader title={activeLabel} onCloseClick={this.closeTab} />
        <Tabs
          className={styles.tabs}
          items={TABS}
          onChange={this.handleTabChange}
          value={activeApproveTabLabel}
        />
        {activeApproveTabLabel === 'Campaigns' ? <CampaignsList /> : <RightbarCreativesList />}
      </>
    );
  };

  setActiveTab = (newActiveLabel: string | null) => {
    const { activeLabel: oldActiveLabel, setActiveLabel } = this.props;

    let activeLabel = newActiveLabel;

    if (newActiveLabel === oldActiveLabel) {
      activeLabel = null;
    }

    setActiveLabel(activeLabel);
  };

  render() {
    const {
      activeLabel,
      isRightbarOpen,
      campaignsApprovalCount,
      creativesApprovalCount,
    } = this.props;

    const totalApprovalCount =
      (campaignsApprovalCount !== null ? campaignsApprovalCount : 0) +
      (creativesApprovalCount !== null ? creativesApprovalCount : 0);

    return (
      <RightbarTabs
        activeLabel={isRightbarOpen ? activeLabel : null}
        setActiveTab={this.setActiveTab}
      >
        <RightbarTab
          label="Approve"
          iconName="ApplicationApprove"
          iconClassName={styles.icon}
          iconChildren={
            !!totalApprovalCount && (
              <span className={styles.notifications}>{totalApprovalCount}</span>
            )
          }
        >
          {this.renderApprove()}
        </RightbarTab>
      </RightbarTabs>
    );
  }
}

const mapState = (state: AppState) => {
  return {
    activeApproveTabLabel: state.rightbar.activeApproveTabLabel,
    reports: state.rightbar.reports,
    creatives: state.rightbar.creatives,
    isRightbarOpen: state.app.isRightbarOpen,
    approvalCount: state.rightbar.approvalCount,
    campaignsApprovalCount: getCampaignsApprovalCount(state),
    creativesApprovalCount: getCreativesApprovalCount(state),
  };
};

const mapActions = {
  setActiveApproveTabLabel: rightbarActions.setActiveApproveTabLabel,
  selectDeselectCreative: creativesActions.selectDeselectCreative,
  selectDeselectAllCreatives: creativesActions.selectDeselectAllCreatives,
};

export const Rightbar = connect(mapState, mapActions)(RightbarComponent);

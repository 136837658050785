import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'factor';

import { AppState } from '../../../../store';
import { NestedOption, Option } from '../../../../models/Option';
import { PublisherActions, publisherActions } from '../../../../store/publishers/actions';

interface Props extends PublisherActions {
  publishers: NestedOption[];
  value: Option[];
  onChange: (value: Option[]) => void;
}

class CategoriesSelectComponent extends React.Component<Props> {
  componentDidMount() {
    const { publishers, fetchPublishers } = this.props;
    if (!publishers.length) {
      fetchPublishers();
    }
  }

  render() {
    const { publishers, value, onChange } = this.props;

    return (
      <Select
        label="Campaign Categories"
        placeholder="Select Campaign Categories"
        isMulti
        allSelectable
        isClearable
        options={publishers}
        value={value}
        onChange={onChange}
        isSearchable
      />
    );
  }
}

const mapState = (state: AppState) => {
  return {
    publishers: state.publishers.publishers,
  };
};

const mapActions = {
  fetchPublishers: publisherActions.fetchPublishers,
};

export const CategoriesSelect = connect(mapState, mapActions)(CategoriesSelectComponent);

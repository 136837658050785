export const campaignsConstants = {
  SET_CAMPAIGNS: 'SET_CAMPAIGNS',
  ADD_CAMPAIGNS: 'ADD_CAMPAIGNS',
  SET_CAMPAIGNS_PARAMS: 'SET_CAMPAIGNS_PARAMS',
  FETCH_CAMPAIGNS: 'FETCH_CAMPAIGNS',
  SET_CAMPAIGNS_FETCHING: 'SET_CAMPAIGNS_FETCHING',
  SELECT_DESELECT_CAMPAIGN: 'SELECT_DESELECT_CAMPAIGN',
  SELECT_DESELECT_ALL_CAMPAIGNS: 'SELECT_DESELECT_ALL_CAMPAIGNS',
  CAMPAIGNS_LIST_SEARCH_CHANGE: 'CAMPAIGNS_LIST_SEARCH_CHANGE',
  SET_CAMPAIGN_CATEGORIES: 'SET_CAMPAIGN_CATEGORIES',
  SELECTED_CAMPAIGNS_APPROVAL_START: 'SELECTED_CAMPAIGNS_APPROVAL_START',
  MULTIPLE_ITEMS_CATEGORIES_CHANGE: 'MULTIPLE_ITEMS_CATEGORIES_CHANGE',
  RESET_MULTIPLE_ITEMS_CATEGORIES: 'RESET_MULTIPLE_ITEMS_CATEGORIES',
  SET_CAMPAIGNS_COUNT_BY_STATUS: 'SET_CAMPAIGNS_COUNT_BY_STATUS',
};

import React from 'react';
import { connect } from 'react-redux';
import { TextField, Button } from 'factor';

import styles from './styles.module.scss';
import { SnackbarActions, snackbarActions } from '../../../../store/snackbar/actions';
import { DialogActions, dialogActions } from '../../../../store/dialog/actions';
import { AppState } from '../../../../store';

interface Props extends SnackbarActions, DialogActions {
  ids: number[];
  closeDialog: () => void;
  reject: () => void;
  type: string;
  rejectionReason: string;
}

const RejectDialogComponent = (props: Props) => {
  const { ids, closeDialog, reject, type, rejectionReason, setRejectionReason } = props;

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>
        {`Reject ${ids.length} ${type}${ids.length > 1 ? 's' : ''}?`}
      </h4>
      <TextField
        className={styles.textarea}
        isTextarea
        placeholder="Reject Reason"
        label="Reject Reason"
        value={rejectionReason}
        onChange={setRejectionReason}
      />
      <div className={styles.buttons}>
        <Button className="btn-square _cornflower-blue _md" onClick={closeDialog}>
          Cancel
        </Button>
        <Button className="btn-square _filled _persimmon _md ml-3" onClick={reject}>
          Reject
        </Button>
      </div>
    </div>
  );
};

const mapState = (state: AppState) => ({
  rejectionReason: state.dialog.rejectionReason,
});

const mapActions = {
  openSnackbar: snackbarActions.openSnackbar,
  closeDialog: dialogActions.closeDialog,
  setRejectionReason: dialogActions.setRejectionReason,
};

export const RejectDialog = connect(mapState, mapActions)(RejectDialogComponent);

import { createAction } from '../../utils/actions';
import { iqmDealIdConstants } from './constants';

export type setIqmDealId = (uuid: string | null) => void;

export const iqmDealIdActions = {
  setIqmDealId(uuid: string | null) {
    return createAction(iqmDealIdConstants.SET_IQM_DEAL_ID, uuid);
  },
};

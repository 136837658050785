import React from 'react';
import { Button } from 'factor';

import styles from './styles.module.scss';
import { MainContent } from '../../components/MainContent';
import { Layout } from '../../components/Layout';
import image from './img/mainpage@3x.png';

const MainPageComponent = () => {
  return (
    <Layout>
      <MainContent>
        <div className={styles.container}>
          <div className={styles.info}>
            <h1 className={styles.title}>Take Full Control</h1>
            <p className={styles.p}>
              Leverage our full-service DSP to manage your own Organisation and the advertisers you
              represent.
            </p>
            <ul className={styles.list}>
              {[
                'Build and retain full ownership over apps using the IQM API.',
                'Manage your own list of customers and the apps they have access to.',
                'Access to exclusive IQM apps available to oganizations only. ',
              ].map((i) => (
                <li className={styles.item} key={i}>
                  {i}
                </li>
              ))}
            </ul>
            <Button className={`btn-round _lg _filled _conflower-blue ${styles.btn}`}>
              Become a Partner
            </Button>
          </div>
          <div className={styles.image}>
            <img src={image} alt="" />
          </div>
        </div>
      </MainContent>
    </Layout>
  );
};

export const MainPage = MainPageComponent;

import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { auth, AuthState } from './auth/reducer';
import { app, ApplicationState } from './app/reducer';
import { organisation, OrganisationState } from './organization/reducer';
import { rightbar, RightbarState } from './rightbar/reducers';
import { table, TableState } from './table/reducers';
import { customers, CustomersState } from './customers/reducer';
import { publishers, PublishersState } from './publishers/reducer';
import { advertisers, AdvertisersState, defaultAdvertisersState } from './advertisers/reducers';
import { creatives, CreativesState, defaultCreativesState } from './creatives/reducers';
import { campaigns, CampaignsState, defaultCampaignsState } from './campaigns/reducers';
import { snackbar, SnackbarState } from './snackbar/reducers';
import { dialog, DialogState } from './dialog/reducers';
import {
  creativeTypes,
  CreativeTypesState,
  defaultCreativeTypesState,
} from './creativeTypes/reducers';
import { privateDeals, PrivateDealsState, defaultPrivateDealsState } from './privateDeals/reducers';
import { exchanges, ExchangesState, defaultExchangesState } from './exchanges/reducers';
import { iqmDealId, IqmDealIdState, defaultIqmDealIdState } from './iqmDealId/reducers';
import { privateDeal, PrivateDealState, defaultPrivateDealState } from './privateDeal/reducers';

import { rootSaga } from './sagas';
import { authConstants } from './auth/constants';
import { defaultSidebarState, sidebar, SidebarState } from './sidebar/reducers';

export interface AppState {
  auth: AuthState;
  app: ApplicationState;
  organisation: OrganisationState;
  rightbar: RightbarState;
  table: TableState;
  customers: CustomersState;
  publishers: PublishersState;
  advertisers: AdvertisersState;
  creatives: CreativesState;
  campaigns: CampaignsState;
  snackbar: SnackbarState;
  dialog: DialogState;
  creativeTypes: CreativeTypesState;
  privateDeals: PrivateDealsState;
  exchanges: ExchangesState;
  iqmDealId: IqmDealIdState;
  privateDeal: PrivateDealState;
  sidebar: SidebarState;
}

const reducer = combineReducers({
  auth,
  app,
  organisation,
  rightbar,
  table,
  customers,
  publishers,
  advertisers,
  creatives,
  campaigns,
  snackbar,
  dialog,
  creativeTypes,
  privateDeals,
  exchanges,
  iqmDealId,
  privateDeal,
  sidebar,
});

const rootReducer: any = (state: AppState, action: any) => {
  const applicableState =
    action.type === authConstants.LOGOUT
      ? {
          ...state,
          advertisers: defaultAdvertisersState,
          campaigns: defaultCampaignsState,
          creatives: defaultCreativesState,
          creativeTypes: defaultCreativeTypesState,
          privateDeals: defaultPrivateDealsState,
          exchanges: defaultExchangesState,
          iqmDealId: defaultIqmDealIdState,
          privateDeal: defaultPrivateDealState,
          sidebar: defaultSidebarState,
        }
      : state;

  return reducer(applicableState, action);
};

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

export const store = createStore<any, any, any, any>(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);

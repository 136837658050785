import { Advertisers } from '../../api/Advertisers';
import { Action } from '../../models/Action';
import { reducerFromMap } from '../../utils/actions';
import { advertisersConstants } from './constants';
import { CustomerOption } from '../../models/Option';

export interface AdvertisersState {
  advertisers: Advertisers;
  advertisersSearchField: string;
  selectedAdvertiserOption: CustomerOption | null;
}

export const defaultAdvertisersState: AdvertisersState = {
  advertisers: [],
  advertisersSearchField: '',
  selectedAdvertiserOption: null,
};

function setAdvertisers(state: AdvertisersState, action: Action<Advertisers>): AdvertisersState {
  return {
    ...state,
    advertisers: action.payload,
  };
}

function setAdvertisersSearchField(
  state: AdvertisersState,
  action: Action<string>,
): AdvertisersState {
  return {
    ...state,
    advertisersSearchField: action.payload,
  };
}

const selectAdvertiser = (
  state: AdvertisersState,
  action: Action<CustomerOption | null>,
): AdvertisersState => {
  return {
    ...state,
    selectedAdvertiserOption: action.payload,
  };
};

const reducer = reducerFromMap<AdvertisersState>(defaultAdvertisersState, {
  [advertisersConstants.SET_ADVERTISERS]: setAdvertisers,
  [advertisersConstants.SET_ADVERTISERS_SEARCH_FIELD]: setAdvertisersSearchField,
  [advertisersConstants.SELECT_ADVERTISER]: selectAdvertiser,
});

export const advertisers = (
  state: AdvertisersState = defaultAdvertisersState,
  action: Action<any>,
) => reducer(state, action);

import { createAction } from '../../utils/actions';
import { privateDealsConstants } from './constants';
import { PrivateDeals, Params, PrivateDealsCount } from '../../api/PrivateDeals';

export interface PrivateDealsActions {
  setPrivateDeals: (privateDeals: PrivateDeals) => void;
  addPrivateDeals: (privateDeals: PrivateDeals) => void;
  setPrivateDealsParams: (params: Partial<Params>) => void;
  setPrivateDealsFetching: (isFetching: boolean) => void;
}

export interface SetPrivateDealsCount {
  setPrivateDealsCount: (properties: PrivateDealsCount) => void;
}

export interface SetPrivateDealsStatus {
  setPrivateDealsCount: (status: (keyof PrivateDealsCount)[]) => void;
}

export const privateDealsActions = {
  setPrivateDeals(privateDeals: PrivateDeals) {
    return createAction(privateDealsConstants.SET_PRIVATE_DEALS, privateDeals);
  },
  addPrivateDeals(privateDeals: PrivateDeals) {
    return createAction(privateDealsConstants.ADD_PRIVATE_DEALS, privateDeals);
  },
  setPrivateDealsParams(params: Partial<Params>) {
    return createAction(privateDealsConstants.SET_PRIVATE_DEALS_PARAMS, params);
  },
  setPrivateDealsFetching(isFetching: boolean) {
    return createAction(privateDealsConstants.SET_PRIVATE_DEALS_FETCHING, isFetching);
  },
  selectDeselectPrivateDeal(value: number) {
    return createAction(privateDealsConstants.SELECT_DESELECT_PRIVATE_DEAL, value);
  },
  selectDeselectAllPrivateDeals(value: boolean) {
    return createAction(privateDealsConstants.SELECT_DESELECT_ALL_PRIVATE_DEALS, value);
  },
  setPrivateDealsCount(properties: PrivateDealsCount) {
    return createAction(privateDealsConstants.SET_PRIVATE_DEALS_COUNT, properties);
  },
  setPrivateDealsStatus(status: (keyof PrivateDealsCount)[]) {
    return createAction(privateDealsConstants.SET_PRIVATE_DEALS_STATUS, status);
  },
};

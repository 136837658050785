/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import { Logger } from '../utils/logger';
import { getInstance } from './Instance';

export interface IqmDealId {
  uuid: string | null;
}

export const FetchIqmDealId = async (): Promise<string | null> => {
  try {
    const response: AxiosResponse<IqmDealId> = await getInstance().get('/get/uuid');

    return response.data.uuid;
  } catch (e) {
    Logger.log('Error while getting iqm deal id', e);
    return null;
  }
};

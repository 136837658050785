import React from 'react';
import { Tabs } from 'factor';
import { connect } from 'react-redux';

import styles from './styles.module.scss';
import { SidebarState } from '../../../../store/sidebar/reducers';
import { AppState } from '../../../../store';
import { transformDealsCount } from '../../../../utils/transformDealsCount';
import { PrivateDealsCount } from '../../../../api/PrivateDeals';
import { Tab } from '../../../../models/Tab';
import { privateDealsActions } from '../../../../store/privateDeals/actions';
import { CampaignCount, Params as CampaignsParams } from '../../../../api/Campaigns';
import { campaignsActions, SetCampaignsParams } from '../../../../store/campaigns/actions';

interface Props extends SetCampaignsParams {
  activeSidebarListLabel: SidebarState['activeSidebarListLabel'];
  privateDealsCount: PrivateDealsCount;
  privateDealsStatus: (keyof PrivateDealsCount)[];
  setPrivateDealsStatus: (status: (keyof PrivateDealsCount)[]) => void;
  campaignsCountByStatus: CampaignCount[];
  campaignsParams: CampaignsParams;
}

const StatusTabsComponent = (props: Props) => {
  const {
    activeSidebarListLabel,
    privateDealsCount,
    privateDealsStatus,
    setPrivateDealsStatus,
    campaignsCountByStatus,
    setCampaignsParams,
    campaignsParams,
  } = props;

  let items: { title: React.ReactNode; value: string }[] = [];
  let onChange: ((tab: Tab<keyof PrivateDealsCount>) => void) | null = null;
  let value: string | undefined;

  switch (activeSidebarListLabel) {
    case 'Deals': {
      // deals Tabs items
      items = transformDealsCount(privateDealsCount).map((item) => ({
        title: (
          <>
            {item.tabLabel} <span className={styles.count}>({item.count})</span>
          </>
        ),
        value: item.fetchedKey,
      }));

      // deals Tabs onChange
      onChange = (tab: Tab<keyof PrivateDealsCount>) => {
        setPrivateDealsStatus([tab.value]);
      };

      // deals Tabs value
      [value] = privateDealsStatus;
      break;
    }
    case 'Campaigns':
      // campaigns Tabs items
      items = campaignsCountByStatus.map((count) => ({
        title: (
          <>
            {count.status_label} <span className={styles.count}>({count.status_count})</span>
          </>
        ),
        value: count.status_key,
      }));

      onChange = (tab: Tab) => {
        setCampaignsParams({ status: tab.value });
      };

      value = campaignsParams.status;
      break;
    default:
  }

  return <Tabs items={items} onChange={onChange} value={value} />;
};

const mapState = (state: AppState) => ({
  activeSidebarListLabel: state.sidebar.activeSidebarListLabel,
  privateDealsCount: state.privateDeals.privateDealsCount,
  privateDealsStatus: state.privateDeals.privateDealsParams.dealStatus,
  campaignsCountByStatus: state.campaigns.campaignsCountByStatus,
  campaignsParams: state.campaigns.campaignsParams,
});

const mapActions = {
  setPrivateDealsStatus: privateDealsActions.setPrivateDealsStatus,
  setCampaignsParams: campaignsActions.setCampaignsParams,
};

export const StatusTabs = connect(mapState, mapActions)(StatusTabsComponent);

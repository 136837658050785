import React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../store';
import { SidebarState } from '../../store/sidebar/reducers';
import { PrivateDealsList } from '../Rightbar/components/privateDealsList';
import { CampaignsList } from '../Rightbar/components/campaignsList';

interface Props {
  activeSidebarListLabel: SidebarState['activeSidebarListLabel'];
}

const Lists = {
  Deals: <PrivateDealsList />,
  Campaigns: <CampaignsList />,
};

const SidebarComponent = (props: Props) => {
  const { activeSidebarListLabel } = props;

  return Lists[activeSidebarListLabel];
};

const mapState = (state: AppState) => ({
  activeSidebarListLabel: state.sidebar.activeSidebarListLabel,
});

export const Sidebar = connect(mapState)(SidebarComponent);

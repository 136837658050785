import React from 'react';

import { Action } from '../../models/Action';
import { reducerFromMap } from '../../utils/actions';
import { dialogConstants } from './constants';

export interface DialogState {
  isDialogOpen: boolean;
  dialogContent: React.ReactNode;
  dialogClassName?: string;
  rejectionReason: string;
}

export const defaultDialogState: DialogState = {
  isDialogOpen: false,
  dialogContent: null,
  dialogClassName: '',
  rejectionReason: '',
};

const openDialog = (
  state: DialogState,
  action: Action<{ content: React.ReactNode; className?: string }>,
): DialogState => {
  return {
    ...state,
    isDialogOpen: true,
    dialogContent: action.payload.content,
    dialogClassName: action.payload.className || '',
  };
};

const closeDialog = (state: DialogState): DialogState => {
  return {
    ...state,
    isDialogOpen: false,
    dialogContent: null,
    dialogClassName: '',
    rejectionReason: '',
  };
};

const setRejectionReason = (state: DialogState, action: Action<string>): DialogState => {
  return {
    ...state,
    rejectionReason: action.payload,
  };
};

const reducer = reducerFromMap<DialogState>(defaultDialogState, {
  [dialogConstants.OPEN_DIALOG]: openDialog,
  [dialogConstants.CLOSE_DIALOG]: closeDialog,
  [dialogConstants.SET_REJECTION_REASON]: setRejectionReason,
});

export const dialog = (state: DialogState = defaultDialogState, action: Action<any>) =>
  reducer(state, action);

import { put, select } from 'redux-saga/effects';

import { API } from '../../api';
import { Campaigns } from '../../api/Campaigns';
import { campaignsActions } from '../campaigns/actions';
import { getAdvertiserId } from '../advertisers/selectors';
import { getCampaignsParams } from '../campaigns/selectors';
import { getUserApprovalAccess } from '../app/selectors';
import { getSelectedCreativeTypes } from '../creativeTypes/selectors';
import { Option } from '../../models/Option';

// using when request params were changed
// and the whole list must be reloaded
export function* fetchCampaigns() {
  console.log('fetchCampaigns');
  const hasUserApprovalAccess = yield select(getUserApprovalAccess);
  const advertiserIds = yield select(getAdvertiserId);
  const selectedCreativeTypes = yield select(getSelectedCreativeTypes);
  const campaignsParams = yield select(getCampaignsParams);

  const creativeTypeIds = selectedCreativeTypes.map((type: Option<number>) => type.value).join(',');

  const commonParams = {
    pageNo: 1, // since the list is reloaded
    advertiserIds,
    creativeTypeIds,
  };

  if (hasUserApprovalAccess) {
    const campaignsResponse: Campaigns = yield API.Campaigns.FetchCampaigns({
      ...campaignsParams,
      ...commonParams,
    });

    yield put(campaignsActions.setCampaigns(campaignsResponse));
  }
}

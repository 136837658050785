import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'factor';

import styles from './styles.module.scss';
import { AppState } from '../../store';
import { Tab } from '../../models/Tab';
import { SetActiveSidebarListLabel, sidebarActions } from '../../store/sidebar/actions';
import { SidebarState } from '../../store/sidebar/reducers';
import { StatusTabs } from './components/statusTabs';

const LISTS: Tab[] = [
  { title: 'Deals', value: 'Deals' },
  { title: 'Campaigns', value: 'Campaigns' },
];

interface Props {
  isRightbarOpen: boolean;
  activeSidebarListLabel: SidebarState['activeSidebarListLabel'];
  setActiveSidebarListLabel: SetActiveSidebarListLabel;
}

const ListsHeaderComponent = (props: Props) => {
  const { isRightbarOpen, activeSidebarListLabel, setActiveSidebarListLabel } = props;

  function setTabs<T extends (...args: any) => void, T2 = string>(setState: T) {
    return (tab: Tab<T2>) => setState(tab.value);
  }

  const classNames = [styles.container];
  if (isRightbarOpen) {
    classNames.push(styles.rightbarOpen);
  }

  return (
    <div className={classNames.join(' ')}>
      <Tabs
        className={styles.lists}
        items={LISTS}
        onChange={setTabs<SetActiveSidebarListLabel, SidebarState['activeSidebarListLabel']>(
          setActiveSidebarListLabel,
        )}
        value={activeSidebarListLabel}
      />
      <StatusTabs />
    </div>
  );
};

const mapState = (state: AppState) => ({
  isRightbarOpen: state.app.isRightbarOpen,
  activeSidebarListLabel: state.sidebar.activeSidebarListLabel,
});

const mapActions = {
  setActiveSidebarListLabel: sidebarActions.setActiveSidebarListLabel,
};

export const ListsHeader = connect(mapState, mapActions)(ListsHeaderComponent);

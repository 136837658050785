import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'factor';

import styles from './styles.module.scss';
import { SnackbarActions, snackbarActions } from '../../../../store/snackbar/actions';
import { DialogActions, dialogActions } from '../../../../store/dialog/actions';
import { AppState } from '../../../../store';

interface Props extends SnackbarActions, DialogActions {
  ids: number[];
  closeDialog: () => void;
  deleteDeal: () => void;
}

const DeleteDealDialogDialogComponent = (props: Props) => {
  const { ids, closeDialog, deleteDeal } = props;

  const submit = () => {
    closeDialog();
    deleteDeal();
  };

  const s = ids.length > 1 ? 's' : '';

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Delete Requested Private Deal{s}?</h4>
      <p>Delete {ids.join(', ')}?</p>
      <div className={styles.buttons}>
        <Button className="btn-square _cornflower-blue _md" onClick={closeDialog}>
          Cancel
        </Button>
        <Button className="btn-square _filled _persimmon _md ml-3" onClick={submit}>
          Delete
        </Button>
      </div>
    </div>
  );
};

const mapState = (state: AppState) => ({
  rejectionReason: state.dialog.rejectionReason,
});

const mapActions = {
  openSnackbar: snackbarActions.openSnackbar,
  closeDialog: dialogActions.closeDialog,
  setRejectionReason: dialogActions.setRejectionReason,
};

export const DeleteDealDialogDialog = connect(
  mapState,
  mapActions,
)(DeleteDealDialogDialogComponent);

import React from 'react';
import { connect } from 'react-redux';
import { LazySelect } from 'iqm-framework';
import { Option } from '../../../../models/Option';
import { creativesActions } from '../../../../store/creatives/actions';
import { AppState } from '../../../../store';

class CampaignsSelectComponent extends React.Component<any, any> {
  onChange = (value: Option[]) => {
    const { setCreativesParams, setSelectedCampaigns } = this.props;
    setCreativesParams({
      campaignIds: value.map((option) => option.value).join(','),
    });
    setSelectedCampaigns(value);
  };

  transformPagingParameters = (state: any) => {
    return {
      dspId: 18,
      sort_by: 'id',
      order: 'desc',
      no_of_entries: state.noOfEntries,
      pgno: state.pageNo,
      search_field: state.searchField,
    };
  };

  mapServerResponseData = (response: any) => {
    const { data, recordsTotal, recordsFiltered } = response;

    return {
      totalRecords: recordsTotal,
      filteredRecords: recordsFiltered,
      data: data.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      }),
    };
  };

  render() {
    const {
      selectedCampaignsOptions,
      campaignsParams: { creativeTypeIds = '' },
    } = this.props;

    return (
      <LazySelect
        httpMethod="get"
        numberOfEntries={10}
        apiPath={`api/v2/adv/campaigns/list?creative_type_ids=${creativeTypeIds}`}
        value={selectedCampaignsOptions}
        onChange={this.onChange}
        transformPagingParameters={this.transformPagingParameters}
        mapServerReposponseData={this.mapServerResponseData}
        selectSpecificProps={{
          label: 'Campaigns',
          placeholder: 'Select Campaigns',
        }}
      />
    );
  }
}

const mapState = (state: AppState) => ({
  selectedCampaignsOptions: state.creatives.selectedCampaignsOptions,
  campaignsParams: state.campaigns.campaignsParams,
});

const mapActions = {
  setCreativesParams: creativesActions.setCreativesParams,
  setSelectedCampaigns: creativesActions.setSelectedCampaigns,
};

export const CampaignsSelect = connect(mapState, mapActions)(CampaignsSelectComponent);

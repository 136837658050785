import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { publishersConstants } from './constants';
import { Option, NestedOption } from '../../models/Option';
import { CampaignInfoField } from '../../models/CampaignInfoFields';
import { PublisherResponse } from '../../api/Publishers';

export interface PublishersState {
  publishers: NestedOption[];
  rawPublishers: PublisherResponse[];
  [CampaignInfoField.publishers]: Option<number>[];
}

const defaultPublishersState: PublishersState = {
  publishers: [],
  rawPublishers: [],
  [CampaignInfoField.publishers]: [],
};

function setPublishers(
  state: PublishersState,
  action: Action<{ raw: PublisherResponse[]; formattedResponse: NestedOption[] }>,
): PublishersState {
  return {
    ...state,
    publishers: action.payload.formattedResponse,
    rawPublishers: action.payload.raw,
  };
}

function selectPublishers(
  state: PublishersState,
  action: Action<Option<number>[]>,
): PublishersState {
  return {
    ...state,
    [CampaignInfoField.publishers]: action.payload,
  };
}

const reducer = reducerFromMap<PublishersState>(defaultPublishersState, {
  [publishersConstants.SET_PUBLISHERS]: setPublishers,
  [publishersConstants.SELECT_PUBLISHERS]: selectPublishers,
});

export const publishers = (state: PublishersState = defaultPublishersState, action: Action<any>) =>
  reducer(state, action);

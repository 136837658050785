import React from 'react';
import { ActionSelect, Button, ParamsCardsList } from 'factor';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  selectDeselectAll: (shouldSelectAll: boolean) => void;
  search?: string;
  onSearchChange?: (search: string) => void;
  isSomethingSelected: boolean;
  loadData?: () => void;
  isFetching?: boolean;
  skeleton?: React.ReactNode;
  approveSelected?: () => void;
  rejectSelected?: () => void;
  approvalStart?: (value: boolean) => void;
  isApprovalStarted?: boolean;
  approvalContent?: React.ReactNode;
  isRejected?: boolean;
  beforeList?: React.ReactNode;
  groupActions?: {}[];
  isSubContentOpen?: boolean;
  subContent?: React.ReactNode;
  hasNoButtons?: boolean;
}

interface State {
  isSearchOpen: boolean;
}

export class RightBarList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSearchOpen: !!props.search,
    };
  }

  onApproveClick = () => {
    const { approvalStart, approveSelected } = this.props;
    if (approvalStart) {
      approvalStart(true);
    } else if (approveSelected) {
      approveSelected();
    } else {
      console.log('approve all');
    }
  };

  getGroupActions = () => {
    const { rejectSelected, groupActions } = this.props;

    return (
      groupActions || [
        {
          label: 'Approve',
          iconName: 'Done',
          onClick: this.onApproveClick,
        },
        {
          label: 'Decline',
          iconName: 'NoData',
          onClick:
            rejectSelected ||
            function() {
              console.log('reject all');
            },
        },
      ]
    );
  };

  toggleSearch = () => {
    const { onSearchChange } = this.props;
    const { isSearchOpen } = this.state;
    this.setState({ isSearchOpen: !isSearchOpen });
    if (isSearchOpen && onSearchChange) {
      onSearchChange('');
    }
  };

  render() {
    const {
      className = '',
      search,
      onSearchChange,
      isSomethingSelected,
      selectDeselectAll,
      children,
      loadData,
      isFetching,
      skeleton,
      isApprovalStarted,
      approvalContent,
      isRejected,
      beforeList,
      subContent,
      isSubContentOpen,
      hasNoButtons,
    } = this.props;
    const { isSearchOpen } = this.state;

    const selectBtnTitle = isSomethingSelected ? 'Deselect All' : 'Select All';

    return (
      <div className={`${styles.container} ${className}`}>
        {!isRejected && !hasNoButtons && (
          <div className={styles.actions}>
            {!isApprovalStarted ? (
              <div className="d-flex justify-content-center">
                {isSomethingSelected && (
                  <ActionSelect className={styles.actionSelect} actions={this.getGroupActions()} />
                )}
                <Button
                  className="btn-square _cornflower-blue align-self-center"
                  onClick={selectDeselectAll}
                >
                  {selectBtnTitle}
                </Button>
              </div>
            ) : (
              approvalContent
            )}
          </div>
        )}
        {beforeList}
        <ParamsCardsList
          searchValue={search}
          onSearchChange={onSearchChange}
          toggleSearch={this.toggleSearch}
          isSearchOpen={isSearchOpen}
          loadData={loadData}
          isFetching={isFetching}
          skeleton={skeleton}
        >
          {children}
        </ParamsCardsList>
        {isSubContentOpen && <div className={styles.subContent}>{subContent}</div>}
      </div>
    );
  }
}

import { PrivateDeals, PrivateDeal, Params, PrivateDealsCount } from '../../api/PrivateDeals';
import { Action } from '../../models/Action';
import { reducerFromMap } from '../../utils/actions';
import { privateDealsConstants } from './constants';

export interface PrivateDealsState {
  privateDeals: PrivateDeals;
  privateDealsParams: Params;
  isPrivateDealsFetching: boolean;
  privateDealsCount: PrivateDealsCount;
}

export const defaultPrivateDealsState: PrivateDealsState = {
  privateDeals: {
    data: [],
    filteredRecords: null,
    totalRecords: null,
  },
  privateDealsParams: {
    pageNo: 1,
    noOfEntries: 20,
    dealStatus: ['All'],
  },
  isPrivateDealsFetching: true,
  privateDealsCount: {
    All: 0,
    inUse: 0,
    inactive: 0,
    rejected: 0,
    requested: 0,
    unused: 0,
  },
};

const setPrivateDeals = (
  state: PrivateDealsState,
  action: Action<PrivateDeal[]>,
): PrivateDealsState => {
  return {
    ...state,
    privateDeals: {
      ...state.privateDeals,
      data: action.payload || [],
    },
    isPrivateDealsFetching: false,
  };
};

const addPrivateDeals = (
  state: PrivateDealsState,
  action: Action<PrivateDeal[]>,
): PrivateDealsState => {
  return {
    ...state,
    privateDeals: {
      ...state.privateDeals,
      data: [...state.privateDeals.data, ...action.payload],
    },
    isPrivateDealsFetching: false,
  };
};

const setPrivateDealsParams = (
  state: PrivateDealsState,
  action: Action<Partial<Params>>,
): PrivateDealsState => {
  return {
    ...state,
    privateDealsParams: {
      ...state.privateDealsParams,
      ...action.payload,
    },
  };
};

const setPrivateDealsStatus = (
  state: PrivateDealsState,
  action: Action<(keyof PrivateDealsCount)[]>,
): PrivateDealsState => {
  return {
    ...state,
    privateDealsParams: {
      ...state.privateDealsParams,
      dealStatus: action.payload,
    },
  };
};

const setPrivateDealsFetching = (
  state: PrivateDealsState,
  action: Action<boolean>,
): PrivateDealsState => {
  return {
    ...state,
    isPrivateDealsFetching: action.payload,
  };
};

const selectDeselectPrivateDeal = (
  state: PrivateDealsState,
  action: Action<number>,
): PrivateDealsState => {
  return {
    ...state,
    privateDeals: {
      filteredRecords: state.privateDeals.filteredRecords,
      totalRecords: state.privateDeals.totalRecords,
      data: state.privateDeals.data.map((item: PrivateDeal) => {
        if (item.id !== action.payload) {
          // This isn't the item we care about - keep it as-is
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          isSelected: !item.isSelected,
        };
      }),
    },
  };
};

const selectDeselectAllPrivateDeals = (
  state: PrivateDealsState,
  action: Action<boolean>,
): PrivateDealsState => {
  return {
    ...state,
    privateDeals: {
      filteredRecords: state.privateDeals.filteredRecords,
      totalRecords: state.privateDeals.totalRecords,
      data: state.privateDeals.data.map((item: PrivateDeal) => {
        return {
          ...item,
          isSelected: action.payload,
        };
      }),
    },
  };
};

const setPrivateDealsCount = (
  state: PrivateDealsState,
  action: Action<PrivateDealsCount>,
): PrivateDealsState => {
  return {
    ...state,
    privateDealsCount: action.payload,
  };
};

const reducer = reducerFromMap<PrivateDealsState>(defaultPrivateDealsState, {
  [privateDealsConstants.SET_PRIVATE_DEALS]: setPrivateDeals,
  [privateDealsConstants.ADD_PRIVATE_DEALS]: addPrivateDeals,
  [privateDealsConstants.SET_PRIVATE_DEALS_PARAMS]: setPrivateDealsParams,
  [privateDealsConstants.SET_PRIVATE_DEALS_STATUS]: setPrivateDealsStatus,
  [privateDealsConstants.SET_PRIVATE_DEALS_FETCHING]: setPrivateDealsFetching,
  [privateDealsConstants.SELECT_DESELECT_PRIVATE_DEAL]: selectDeselectPrivateDeal,
  [privateDealsConstants.SELECT_DESELECT_ALL_PRIVATE_DEALS]: selectDeselectAllPrivateDeals,
  [privateDealsConstants.SET_PRIVATE_DEALS_COUNT]: setPrivateDealsCount,
});

export const privateDeals = (
  state: PrivateDealsState = defaultPrivateDealsState,
  action: Action<any>,
) => reducer(state, action);

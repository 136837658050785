import { createAction } from '../../utils/actions';
import { advertisersConstants } from './constants';
import { Advertisers } from '../../api/Advertisers';
import { CustomerOption } from '../../models/Option';

export interface AdvertiserActions {
  setAdvertisers: (advertisers: Advertisers) => void;
  selectAdvertiser: (id: CustomerOption | null) => void;
  setAdvertisersSearchField: (value: string) => void;
}

export const advertiserActions = {
  setAdvertisers(advertisers: Advertisers) {
    return createAction(advertisersConstants.SET_ADVERTISERS, advertisers);
  },
  setAdvertisersSearchField(value: string) {
    return createAction(advertisersConstants.SET_ADVERTISERS_SEARCH_FIELD, value);
  },
  selectAdvertiser(option: CustomerOption | null) {
    return createAction(advertisersConstants.SELECT_ADVERTISER, option);
  },
};

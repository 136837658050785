import { createAction } from 'utils/actions';
import { tableConstants } from './constants';
import { Table } from '../../models/Table';

export const tableActions = {
  getData(tableData: Table) {
    return createAction(tableConstants.GET_TABLE_DATA, tableData);
  },
};

export interface GetData {
  getData: (tableData: Table) => void;
}

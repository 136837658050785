import React from 'react';

import styles from './styles.module.scss';
import MemberHeader from './MemberHeader';
import { AccountsTable } from '../Table';

export const Member = () => {
  return (
    <div className={styles.container}>
      <MemberHeader name="Ivan Guerrero" />
      <AccountsTable title="Assigned Advertiser Accounts" />
    </div>
  );
};

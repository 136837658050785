import { reducerFromMap } from 'utils/actions';
import { Action } from 'models/Action';
import { organisationConstants } from './constants';
import { ListOption } from '../../models/ListOption';

export interface OrganisationState {
  organisationSwitch: string;
  role: ListOption | null;
}

const defaultOrganisationState: OrganisationState = {
  organisationSwitch: 'Roles',
  role: null,
};

function setSwitch(state: OrganisationState, action: Action<string>): OrganisationState {
  return {
    ...state,
    organisationSwitch: action.payload,
  };
}

function setRole(state: OrganisationState, action: Action<ListOption>): OrganisationState {
  return {
    ...state,
    role: action.payload,
  };
}

const reducer = reducerFromMap<OrganisationState>(defaultOrganisationState, {
  [organisationConstants.SET_ORGANISATION_SWITCH]: setSwitch,
  [organisationConstants.SET_ROLE]: setRole,
});

export const organisation = (
  state: OrganisationState = defaultOrganisationState,
  action: Action<any>,
) => reducer(state, action);

import { reducerFromMap } from '../../utils/actions';
import { rightbarConstants } from './constants';
import { Action } from '../../models/Action';
import { data, creatives } from './consts';

export interface RightbarState {
  activeApproveTabLabel: string | null;
  reports: { [key: string]: any };
  rightbarReportsSearch: string;
  creatives: { [key: string]: any };
  rightbarCreativesSearch: string;
  approvalCount: number | null;
}

export const defaultRightbarState: RightbarState = {
  activeApproveTabLabel: 'Campaigns',
  reports: data,
  rightbarReportsSearch: '',
  creatives,
  rightbarCreativesSearch: '',
  approvalCount: null,
};

const setActiveApproveTabLabel = (
  state: RightbarState,
  action: Action<string | null>,
): RightbarState => {
  return {
    ...state,
    activeApproveTabLabel: action.payload,
  };
};

const selectDeselectReport = (state: RightbarState, action: Action<string>): RightbarState => {
  return {
    ...state,
    reports: {
      total: state.reports.total,
      items: state.reports.items.map((item: any) => {
        if (item.id !== action.payload) {
          // This isn't the item we care about - keep it as-is
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          isSelected: !item.isSelected,
        };
      }),
    },
  };
};

const selectDeselectAllReports = (state: RightbarState, action: Action<boolean>): RightbarState => {
  return {
    ...state,
    reports: {
      total: state.reports.total,
      items: state.reports.items.map((item: any) => {
        return {
          ...item,
          isSelected: action.payload,
        };
      }),
    },
  };
};

const updateReport = (
  state: RightbarState,
  action: Action<{ id: number; field: string; value: any }>,
): RightbarState => {
  return {
    ...state,
    reports: {
      total: state.reports.total,
      items: state.reports.items.map((item: any) => {
        if (item.id !== action.payload.id) {
          // This isn't the item we care about - keep it as-is
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          [action.payload.field]: action.payload.value,
        };
      }),
    },
  };
};

const setRightbarReportsSearch = (state: RightbarState, action: Action<string>): RightbarState => {
  return {
    ...state,
    rightbarReportsSearch: action.payload,
  };
};

const reducer = reducerFromMap<RightbarState>(defaultRightbarState, {
  [rightbarConstants.SET_ACTIVE_APPROVE_TAB_LABEL]: setActiveApproveTabLabel,
  [rightbarConstants.SELECT_DESELECT_REPORT]: selectDeselectReport,
  [rightbarConstants.SELECT_DESELECT_ALL_REPORTS]: selectDeselectAllReports,
  [rightbarConstants.UPDATE_REPORT]: updateReport,
  [rightbarConstants.RIGHTBAR_REPORTS_LIST_SEARCH_CHANGE]: setRightbarReportsSearch,
});

export const rightbar = (state: RightbarState = defaultRightbarState, action: Action<any>) =>
  reducer(state, action);

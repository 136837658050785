import axios, { AxiosResponse, Canceler } from 'axios';
import { Logger } from '../utils/logger';
import { getInstance } from './Instance';
import { GetResponse } from '../models/Response';

export type Params = {
  dspId: number;
};

export type CreativeType = {
  advertiserId: number;
  dspId: number;
  id: number;
  name: string;
  status: string | null;
  creativeTypeId: number;
};

export type CreativeTypes = {
  statusCode: string;
  responseObject: CreativeType[];
};

let cancel: Canceler;

export const fetchCreativeTypes = async (): Promise<any> => {
  if (cancel) {
    cancel('Canceled by the user');
  }
  try {
    const response: AxiosResponse<GetResponse<CreativeTypes>> = await getInstance().get(
      '/v2/adv/creativetype/list',
      {
        params: {
          dspId: 18,
        },
        cancelToken: new axios.CancelToken((c) => {
          cancel = c;
        }),
      },
    );

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while getting creative types', e);
    return {};
  }
};

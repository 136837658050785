import { put, take } from 'redux-saga/effects';

import { API } from 'api';
import { iqmDealIdActions } from '../iqmDealId/actions';
import { authConstants } from '../auth/constants';

export function* fetchIqmDealId() {
  while (yield take(authConstants.LOGIN_SUCCESS)) {
    const iqmDealId = yield API.IqmDealId.FetchIqmDealId();
    yield put(iqmDealIdActions.setIqmDealId(iqmDealId));
  }
}

import { Table } from '../../models/Table';

const tableData: Table = {
  unsortedData: {
    total: true,
    id: 'total',
    activeCampaigns: '86',
    userBudget: '$95,993',
    userSpent: '$24,807',
    rowClassName: '_aggregated',
  },
  data: [
    {
      customer: 'CADNC',
      dateAdded: '10/11/2019',
      assignees: '1',
      activeCampaigns: '86',
      userBudget: '$95,993',
      userSpent: '$24,807',
    },
    {
      customer: 'Isabelle Brock',
      dateAdded: '09/24/2019',
      assignees: '2',
      activeCampaigns: '7',
      userBudget: '$61,535',
      userSpent: '$38,916',
    },
    {
      customer: 'Lee Rogers',
      dateAdded: '01/16/2019',
      assignees: '2',
      activeCampaigns: '13',
      userBudget: '$32,912',
      userSpent: '$30,697',
    },
    {
      customer: 'Mabel Watson',
      dateAdded: '05/14/2019',
      assignees: '3',
      activeCampaigns: '84',
      userBudget: '$88,213',
      userSpent: '$31,056',
    },
    {
      customer: 'Isabelle McKinney',
      dateAdded: '02/12/2019',
      assignees: '1',
      activeCampaigns: '65',
      userBudget: '$86,559',
      userSpent: '$32,429',
    },
    {
      customer: 'Jim Munoz',
      dateAdded: '01/08/2019',
      assignees: '3',
      activeCampaigns: '89',
      userBudget: '$92,941',
      userSpent: '$88,605',
    },
    {
      customer: 'Joe Jensen',
      dateAdded: '10/13/2019',
      assignees: '3',
      activeCampaigns: '6',
      userBudget: '$56,707',
      userSpent: '$7,463',
    },
    {
      customer: 'Mae Howard',
      dateAdded: '08/30/2019',
      assignees: '1',
      activeCampaigns: '23',
      userBudget: '$77,515',
      userSpent: '$18,772',
    },
  ],
};

/* eslint-disable */
tableData.data.forEach((item, i) => {
  item.id = i < 10 ? `0${i}` : i;
});
/* eslint-enable */

export { tableData };

export const testOptions = [
  { label: 'Option 1', value: 'Option 1' },
  { label: 'Option 2', value: 'Option 2' },
  { label: 'Option 3', value: 'Option 3' },
];

export const locationOptions = [
  { value: 22, label: 'State' },
  { value: 23, label: 'City' },
  { value: 17, label: 'Zip Code' },
  { value: 18, label: 'House District' },
  { value: 19, label: 'Congress District' },
  { value: 20, label: 'Senate District' },
  { value: 21, label: 'County' },
  { value: 24, label: 'Polling Booth' },
  { value: 241, label: 'Custom Location' },
];

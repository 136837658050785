import React, {Component} from "react";

import styles from "./File.module.scss";

import {ReactComponent as PreviewIcon} from "./img/preview.svg";

class File extends Component {
	state = {
		previewSrc: "",
	}

	readURL = (file) => {
		const reader = new FileReader();
		reader.onload = ({target}) => this.setState({previewSrc: target.result});
		reader.readAsDataURL(file);
	}

	render() {
		const {
			className,
			label,
			id,
			btnTitle,
			onChange = () => {},
			withPreview,
			previewClassName = "",
		} = this.props;

		const {
			previewSrc,
		} = this.state;

		return (
			<div className={`${styles.container} ${className || ""}`}>
				{label &&
					<div className={styles.label}>
						{label}
					</div>
				}
				<div className={styles.row}>
					{withPreview &&
						<div className={`${styles.preview} ${previewClassName}`}>
							<i>
								<PreviewIcon/>
							</i>
							<img src={previewSrc} alt=""/>
						</div>
					}
					<input
						style={{display: "none"}}
						type="file"
						id={id || label}
						onChange={(e) => {
							onChange(e);
							if(withPreview) {
								this.readURL(e.target.files[0]);
							}
						}}
					/>
					<label className="btn-square _conflower-blue" htmlFor={id || label}>
						{btnTitle}
					</label>
				</div>
			</div>
		);
	}
}

export default File;
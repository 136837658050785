import React from 'react';
import { Tumbler } from 'factor';

import styles from './styles.module.scss';
import { Params as CampaignsParams } from '../../../../api/Campaigns';
import { Params as CreativesParams } from '../../../../api/Creatives';

interface Props {
  className?: string;
  params: CampaignsParams | CreativesParams;
  setParams: (params: CampaignsParams | CreativesParams) => void;
  setFetching: (value: boolean) => void;
  setData: (data: any) => void;
  fetchData: (params: CampaignsParams | CreativesParams) => Promise<any>;
}

export const RejectedTumbler = (props: Props) => {
  const { className = '', params, setParams, setData, setFetching, fetchData } = props;

  const isRejected = params.status === 'rejected' || params.status === '5';
  const isStatusStringNumber = params.status === '1' || params.status === '5';

  const onStatusChange = async () => {
    let status = '';
    if (isRejected) {
      status = isStatusStringNumber ? '1' : 'pending';
    } else {
      status = isStatusStringNumber ? '5' : 'rejected';
    }

    setFetching(true);
    const response = await fetchData({
      ...params,
      status,
      pageNo: 1,
    });
    setData(response);
    setParams({
      ...params,
      status,
      pageNo: 2,
    });
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <Tumbler
        className={styles.tumbler}
        on={isRejected}
        onChange={onStatusChange}
        title="Show rejected"
      />
    </div>
  );
};

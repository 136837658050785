import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, TextField, Select, Checkbox, Switch, Tooltip } from 'factor';

import styles from './styles.module.scss';
import { CustomerOption, Option } from '../../../../models/Option';
import { AppState } from '../../../../store';
import { Exchange } from '../../../../api/Exchanges';
import {
  privateDealActions,
  CreatePrivateDeal as CreatePrivateDealInterface,
} from '../../../../store/privateDeal/actions';
import { DealType, DealTypes } from '../../../../api/CreatePrivateDeal';
import { AdvertiserSelect } from '../../../advertiserSelect';
import {
  isNotEmpty,
  isTextFieldAlphaNumeric,
  isTextFieldLessOrEqual,
  isTextFieldNotLongerThan,
} from '../../../../utils/validate';

interface TextFields {
  dealId: boolean;
  dealName: boolean;
  suggestedCpm: boolean;
  description: boolean;
}

interface Props extends CreatePrivateDealInterface {
  creativeTypes: Option[];
  exchanges: Exchange[];
  iqmDealId: string | null;
  closeDialog: () => void;
  privateDealTypes: DealTypes;
}

const CreatePrivateDealComponent = (props: Props) => {
  const {
    privateDealTypes,
    creativeTypes,
    exchanges,
    iqmDealId,
    closeDialog,
    createPrivateDeal,
  } = props;

  const [dealType, setDealType] = useState<DealType>(privateDealTypes[1]);
  const [dealId, setDealId] = useState('');
  const [dealName, setDealName] = useState('');
  const [description, setDescription] = useState('');
  const [suggestedCpm, setSuggestedCpm] = useState<string>('');
  const [selectedCreativeTypes, setSelectedCreativeTypes] = useState<Option[]>([]);
  const [selectedExchanges, setExchange] = useState<Option[]>([]);
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [advertisers, setAdvertisers] = useState<CustomerOption[]>([]);
  const [advertisersSearchField, setAdvertisersSearchField] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(true);
  const [textFieldsValidity, setTextFieldsValidity] = useState<TextFields>({
    dealId: false,
    dealName: false,
    suggestedCpm: false,
    description: false,
  });

  const prevDealTypeRef: any = useRef();
  useEffect(() => {
    if (dealType === privateDealTypes[2] && iqmDealId) {
      setDealId(iqmDealId);
    } else if (prevDealTypeRef.current === privateDealTypes[2]) {
      setDealId('');
    }
    prevDealTypeRef.current = dealType;
  }, [iqmDealId, dealType, privateDealTypes]);

  function onDealTypeChange(setState: Dispatch<SetStateAction<DealType>>, types: DealTypes) {
    return (v: boolean) => {
      setState(v ? types[1] : types[0]);
    };
  }

  function handleTextFieldChange<T = string>(setState: Dispatch<SetStateAction<T>>) {
    return (v: T) => setState(v);
  }

  const onSelectedCreativeTypesChange = (value: Option[]) => {
    setSelectedCreativeTypes(value);
  };

  const onMultiSelectChange = (setState: Dispatch<SetStateAction<Option[]>>) => (
    value: Option[],
  ) => {
    setState(value);
  };

  const onCheckboxChange = (setState: Dispatch<SetStateAction<boolean>>) => (value: boolean) => {
    setState(value);
  };

  const getTransformedExchanges = () => {
    /* eslint-disable */
    return exchanges.map((exchange: Exchange) => ({
      label: exchange.name,
      value: exchange.id,
    }));
    /* eslint-enable */
  };

  const isIqmDealType = dealType ? dealType.id === privateDealTypes[2].id : false;

  const setTextFieldValid = (field: keyof TextFields) => (isValid: boolean) =>
    setTextFieldsValidity({
      ...textFieldsValidity,
      [field]: isValid,
    });

  const isAllTextFieldsValid = () => {
    const values = Object.values(textFieldsValidity);
    return values.filter((value) => value).length === values.length;
  };

  const isValidated = () => {
    return (
      isAllTextFieldsValid() &&
      selectedCreativeTypes.length &&
      (isIqmDealType || selectedExchanges.length) &&
      (isPublic || advertisers.length)
    );
  };

  const handleSubmit = () => {
    createPrivateDeal({
      dealTypeId: dealType.id,
      dealId,
      dealName,
      description,
      suggestedCPM: parseFloat(suggestedCpm),
      creativeTypes: selectedCreativeTypes.map((type: Option) => type.value),
      exchanges: selectedExchanges.map((option) => option.value),
      ...(isPublic ? {} : { applicableTo: advertisers.map((adv: CustomerOption) => adv.value) }),
      requestedDealId: 0,
      isPublic,
      active: isActive,
    });

    closeDialog();
  };

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Create Deal ID</h4>
      <div className="row mb-3">
        {!isIqmDealType && (
          <div className="col-4 d-flex flex-column align-items-start">
            <span className={styles.label}>Exchange Deal Type</span>
            <Tooltip label="Exchange Deal Type">
              <Switch
                list={['Direct Deal', 'Open Package']}
                value={dealType ? dealType.id === privateDealTypes[0].id : false}
                onChange={onDealTypeChange(setDealType, [privateDealTypes[1], privateDealTypes[0]])}
              />
            </Tooltip>
          </div>
        )}
      </div>
      <div className="row mb-3">
        <div className="col-4">
          <Tooltip label="Unique identifier of the deal">
            <TextField
              label="Deal ID"
              name="Deal ID"
              value={dealId}
              onChange={handleTextFieldChange(setDealId)}
              disabled={isIqmDealType}
              validationRules={[isTextFieldAlphaNumeric, isTextFieldNotLongerThan(100), isNotEmpty]}
              onValidate={setTextFieldValid('dealId')}
            />
          </Tooltip>
        </div>
        <div className="col-4">
          <Tooltip label="Name of the deal">
            <TextField
              label="Deal Name"
              name="Deal Name"
              value={dealName}
              onChange={handleTextFieldChange(setDealName)}
              validationRules={[isTextFieldNotLongerThan(255), isNotEmpty]}
              onValidate={setTextFieldValid('dealName')}
            />
          </Tooltip>
        </div>
        <div className="col-4">
          <Tooltip label="Suggested CPM for the deal when targeted in a campaign">
            <TextField
              type="number"
              label="Suggested CPM"
              name="Suggested CPM"
              value={suggestedCpm}
              onChange={handleTextFieldChange<string>(setSuggestedCpm)}
              validationRules={[isNotEmpty, isTextFieldLessOrEqual(10000)]}
              onValidate={setTextFieldValid('suggestedCpm')}
            />
          </Tooltip>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-4">
          <Select
            label="Creative Types"
            placeholder="Select Creative Types"
            options={creativeTypes}
            value={selectedCreativeTypes}
            onChange={onSelectedCreativeTypesChange}
            isMulti
            tooltipParams={{ label: 'Creative Types supported' }}
          />
        </div>
        {!isIqmDealType && (
          <div className="col-4">
            <Select
              label="Exchange"
              placeholder="Select Exchange"
              options={getTransformedExchanges()}
              value={selectedExchanges}
              onChange={onMultiSelectChange(setExchange)}
              isMulti
              tooltipParams={{ label: 'Exchanges that support the deal' }}
            />
          </div>
        )}
        <div className="col-4">
          <Tooltip label="Deal description">
            <TextField
              label="Description"
              name="Description"
              value={description}
              onChange={handleTextFieldChange(setDescription)}
              validationRules={[isNotEmpty, isTextFieldNotLongerThan(500)]}
              onValidate={setTextFieldValid('description')}
            />
          </Tooltip>
        </div>
      </div>
      <div className="row align-items-center mb-3">
        <div className="col-4 d-flex justify-content-start">
          <Tooltip label="Make a deal available for all customers">
            <Checkbox
              className={styles.checkbox}
              label="Make it public"
              checked={isPublic}
              onChange={onCheckboxChange(setIsPublic)}
            />
          </Tooltip>
        </div>
        {!isPublic && (
          <div className="col-4">
            <AdvertiserSelect
              isSimpleBtn
              value={advertisers}
              onChange={setAdvertisers}
              searchField={advertisersSearchField}
              onSearchFieldChange={setAdvertisersSearchField}
              selectSpecificProps={{
                isMulti: true,
                label: 'Customers',
                placeholder: 'Select Customers',
                tooltipParams: { label: 'Select Customers to make deal available for them' },
              }}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-4 d-flex justify-content-start">
          <Tooltip label="Make deal active">
            <Checkbox
              className={styles.checkbox}
              label="Make deal active"
              checked={isActive}
              onChange={onCheckboxChange(setIsActive)}
            />
          </Tooltip>
        </div>
      </div>
      <div className={styles.footer}>
        <Button className="btn-square _md _cornflower-blue mr-3" onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          className="btn-square _md _filled _cornflower-blue"
          onClick={handleSubmit}
          disabled={!isValidated()}
        >
          Done
        </Button>
      </div>
    </div>
  );
};

const mapState = (state: AppState) => ({
  creativeTypes: state.creativeTypes.creativeTypesList,
  exchanges: state.exchanges.exchanges,
  iqmDealId: state.iqmDealId.uuid,
  privateDealTypes: state.privateDeal.dealTypes,
});

const mapActions = {
  createPrivateDeal: privateDealActions.createPrivateDeal,
};

export const CreatePrivateDeal = connect(mapState, mapActions)(CreatePrivateDealComponent);

import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { Logger } from '../utils/logger';
import { getInstance } from './Instance';

/* eslint-disable */
export type AdvertisersParams = {
  status?: string;
};
/* eslint-enable */
export type Advertisers = Advertiser[];
/* eslint-disable */
export type Advertiser = {
  id: number,
  dsp_id: number,
  name: string,
  email: string,
};
/* eslint-enable */
export const fetchAdvertisers = async (): Promise<Advertisers | {}> => {
  try {
    const response: AxiosResponse<Advertisers> = await getInstance().get('/list/advertisers', {
      params: { optimization_flag: 1 },
    });

    return response.data;
  } catch (e) {
    Logger.log('Error while getting advertisers', e);
    return {};
  }
};

type TFetchAdvertiserBalanceParams = {
  advertiserId?: number;
  dspId?: number;
  // eslint-disable-next-line camelcase
  list_adv?: string;
};

export const fetchAdvertiserBalance = async (
  params: TFetchAdvertiserBalanceParams,
): Promise<number> => {
  try {
    const response: AxiosResponse<Advertisers> = await getInstance().get(
      '/v2/advertiser/getavailablebalance',
      {
        params,
      },
    );
    return get(response, 'data.responseObject[0].finance_available_balance', 0);
  } catch (e) {
    Logger.log('Error while getting advertiser balance', e);
    return 0;
  }
};

import React from 'react';
import { connect } from 'react-redux';

import { Member } from 'components/Member';
import { Role } from 'components/Role';
import { Layout } from '../../components/Layout';
import { MainContent } from '../../components/MainContent';
import { OrganisationSidebar } from './OrganisationSidebar';
import { AppState } from '../../store';
import { organisationActions } from '../../store/organization/actions';

interface Props {
  organisationSwitch: string;
}

const OrganisationComponent = (props: Props) => {
  const { organisationSwitch } = props;

  return (
    <Layout sidebar={<OrganisationSidebar />}>
      <MainContent>
        {(() => {
          switch (organisationSwitch) {
            case 'Members':
              return <Member />;
            case 'Roles':
              return <Role />;
            default:
              return null;
          }
        })()}
      </MainContent>
    </Layout>
  );
};

const mapState = (state: AppState) => ({
  organisationSwitch: state.organisation.organisationSwitch,
});

const mapActions = {
  setSwitch: organisationActions.setSwitch,
};

export const Organisation = connect(mapState, mapActions)(OrganisationComponent);

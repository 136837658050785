import React from 'react';
import { connect } from 'react-redux';

import Header from './Header';
import Options from './Options';
import { AccountsTable } from '../Table';
import { AppState } from '../../store';
import { ListOption } from '../../models/ListOption';

interface Props {
  role: ListOption | null;
}

const RoleComponent = (props: Props) => {
  const { role } = props;

  const isAccountOwner = role?.label === 'Account Owner';
  return (
    <div>
      <Header isAccountOwner={isAccountOwner} className="mb-4" />
      <Options isAccountOwner={isAccountOwner} className="mb-2" />
      {role?.label !== 'Account Owner' && <AccountsTable title="Assigned Members (25)" />}
    </div>
  );
};

const mapState = (state: AppState) => ({
  role: state.organisation.role,
});

export const Role = connect(mapState)(RoleComponent);

import { Action } from '../../models/Action';
import { rightbarConstants } from './constants';

export const rightbarActions = {
  setActiveApproveTabLabel(value: string | null): Action<string | null> {
    return {
      type: rightbarConstants.SET_ACTIVE_APPROVE_TAB_LABEL,
      payload: value,
    };
  },
  selectDeselectReport(value: number): Action<number> {
    return {
      type: rightbarConstants.SELECT_DESELECT_REPORT,
      payload: value,
    };
  },
  selectDeselectAllReports(value: boolean): Action<boolean> {
    return {
      type: rightbarConstants.SELECT_DESELECT_ALL_REPORTS,
      payload: value,
    };
  },
  updateReport(value: {
    id: number;
    field: string;
    value: any;
  }): Action<{ id: number; field: string; value: any }> {
    return {
      type: rightbarConstants.UPDATE_REPORT,
      payload: value,
    };
  },
  setRightbarReportsSearch(value: string): Action<string> {
    return {
      type: rightbarConstants.RIGHTBAR_REPORTS_LIST_SEARCH_CHANGE,
      payload: value,
    };
  },
};

import { put, take } from 'redux-saga/effects';

import { API } from 'api';
import { advertiserActions } from '../advertisers/actions';
import { authConstants } from '../auth/constants';

export default function* fetchAdvertisers() {
  while (yield take(authConstants.LOGIN_SUCCESS)) {
    const advertisers = yield API.Advertisers.fetchAdvertisers();
    yield put(advertiserActions.setAdvertisers(advertisers));
  }
}

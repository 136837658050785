import { put } from 'redux-saga/effects';

import { API } from '../../api';
import { PublisherResponse } from '../../api/Publishers';
import { NestedOption } from '../../models/Option';
import { createAction } from '../../utils/actions';
import { publishersConstants } from '../publishers/constants';

export function* fetchPublishers() {
  const response: PublisherResponse[] = yield API.Publishers.FetchPublishers();
  const formattedResponse = yield response.reduce(
    (acc: NestedOption[], publisher: PublisherResponse) => {
      const foundGroup = acc.find((item) => item.value === publisher.parent_id);
      if (foundGroup && foundGroup.options) {
        foundGroup.options.push({
          label: publisher.name,
          value: publisher.id,
        });
      } else {
        acc.push({
          label: publisher.group,
          value: publisher.parent_id,
          options: [
            {
              label: publisher.name,
              value: publisher.id,
            },
          ],
        });
      }
      return acc;
    },
    [],
  );

  yield put(
    createAction<{ raw: PublisherResponse[]; formattedResponse: NestedOption[] }>(
      publishersConstants.SET_PUBLISHERS,
      { raw: response, formattedResponse },
    ),
  );
}

export const isAlphaNumeric = (copy: string) => /^[a-z0-9]+$/i.test(copy);

export const isAlphaNumericWithSpaces = (copy: string) => /^[A-Za-z\d\s]+$/.test(copy);

export const isNotLongerThan = (length: number, copy: string) => copy.length <= length;

export const isTextFieldAlphaNumeric = {
  func: (value: string) => isAlphaNumeric(value),
  error: (name: string) => `Only alpha-numeric characters are allowed for ${name}`,
};

export const isTextFieldAlphaNumericWithSpaces = {
  func: (value: string) => isAlphaNumericWithSpaces(value),
  error: (name: string) => `Only alpha-numeric characters and spaces are allowed for ${name}`,
};

export const isTextFieldNotLongerThan = (length: number) => ({
  func: (value: string) => isNotLongerThan(length, value),
  error: (name: string) => `${name} can not be longer than ${length} characters`,
});

export const isTextFieldLessOrEqual = (count: number) => ({
  func: (value: string) => +value <= count,
  error: (name: string) => `${name} can not be more than ${count}`,
});

export const isNotEmpty = {
  func: (value: string) => !!(value && value.trim()),
  error: (name: string) => `${name} must be filled!`,
};

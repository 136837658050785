export const creativesConstants = {
  SET_CREATIVES: 'SET_CREATIVES',
  ADD_CREATIVES: 'ADD_CREATIVES',
  SET_CREATIVES_PARAMS: 'SET_CREATIVES_PARAMS',
  SET_SELECTED_CAMPAIGNS: 'SET_SELECTED_CAMPAIGNS',
  FETCH_CREATIVES: 'FETCH_CREATIVES',
  SET_CREATIVES_FETCHING: 'SET_CREATIVES_FETCHING',
  SELECT_DESELECT_CREATIVE: 'SELECT_DESELECT_CREATIVE',
  SELECT_DESELECT_ALL_CREATIVES: 'SELECT_DESELECT_ALL_CREATIVES',
  CREATIVES_LIST_SEARCH_CHANGE: 'CREATIVES_LIST_SEARCH_CHANGE',
  CREATIVES_APPROVED_OR_REJECTED: 'CREATIVES_APPROVED_OR_REJECTED',
  SET_CREATIVES_COMPACT: 'SET_CREATIVES_COMPACT',
};

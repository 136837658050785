import React from 'react';
import { connect } from 'react-redux';

import styles from './styles.module.scss';
import { MainContent } from '../../../components/MainContent';
import { AppState } from '../../../store';
import { PrivateDeal } from '../../../api/PrivateDeals';
import { Overview } from '../../../components/overview';
import { SidebarState } from '../../../store/sidebar/reducers';

const PARAMS_MAP: { label: string; value: keyof PrivateDeal }[] = [
  { label: 'Deal ID', value: 'dealId' },
  { label: 'Deal Name', value: 'dealName' },
  // { label: 'Description', value: 'description' },
  // { label: 'Suggested CPM', value: 'suggestedCPM' },
  // { label: 'Creative Types', value: 'creativeTypes' },
  // { label: 'Exchanges', value: 'exchanges' },
];

interface Props {
  privateDealsList: PrivateDeal[];
  activeSidebarListLabel: SidebarState['activeSidebarListLabel'];
}

const CustomersMainComponent = (props: Props) => {
  const { privateDealsList, activeSidebarListLabel } = props;
  const selectedPrivateDealsList = privateDealsList.filter((deal: PrivateDeal) => deal.isSelected);

  return (
    <MainContent>
      {activeSidebarListLabel === 'Deals' && selectedPrivateDealsList.length ? (
        <ul className={styles.list}>
          {selectedPrivateDealsList.map((deal: PrivateDeal) => (
            <li className={styles.item} key={deal.id}>
              <header className={styles.header}>
                <h3 className={styles.name}>{deal.dealName}</h3>
                <span className={`text ${styles.id}`}>
                  ID: <span className={`text ${styles.idValue}`}>{deal.id}</span>
                </span>
              </header>
              <div className={styles.content}>
                <h4 className={styles.title}>Deal Details</h4>
                <div className="row">
                  {PARAMS_MAP.map((param) => (
                    <div className="col-4 mb-5" key={param.value}>
                      <div className={styles.param}>
                        <div className={styles.label}>{param.label}</div>
                        <div className={`text ${styles.value}`}>{deal[param.value]}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <Overview />
      )}
    </MainContent>
  );
};

const mapState = (state: AppState) => ({
  privateDealsList: state.privateDeals.privateDeals.data,
  activeSidebarListLabel: state.sidebar.activeSidebarListLabel,
});

export const CustomersMain = connect(mapState)(CustomersMainComponent);

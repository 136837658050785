import React from 'react';

import styles from './styles.module.scss';
import { CustomersMain } from './CustomersMain';
import { Layout } from '../../components/Layout';
import { ListsHeader } from '../../components/listsHeader';
import { Sidebar } from '../../components/sidebar';

export const Customers = () => {
  return (
    <>
      <ListsHeader />
      <Layout
        mainClassName={styles.main}
        sidebarClassName={styles.sidebar}
        rightbarClassName={styles.rightbar}
        sidebar={<Sidebar />}
      >
        <CustomersMain />
      </Layout>
    </>
  );
};

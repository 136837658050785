import React from 'react';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  contentClassName?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  lastChildren?: React.ReactNode;
  isMainContentHidden: boolean;
  children: React.ReactNode;
}

const ComplexSidebar = (props: Props) => {
  const {
    className,
    contentClassName,
    children,
    header,
    footer,
    lastChildren,
    isMainContentHidden,
  } = props;

  const containerClassNames = [styles.sidebar];
  if (className) {
    containerClassNames.push(className);
  }

  return (
    <div className={containerClassNames.join(' ')}>
      <div className={`${styles.container} ${isMainContentHidden ? '_hidden' : ''}`}>
        {header && <div className={styles.header}>{header}</div>}
        <div className={styles.main}>
          <div className={`${styles.content} ${contentClassName || ''}`}>{children}</div>
        </div>
        {footer && <footer className={styles.footer}>{footer}</footer>}
      </div>
      {lastChildren}
    </div>
  );
};

export default ComplexSidebar;

import { AppState } from 'store';

export const getCampaignsApprovalCount = (state: AppState) =>
  // with not empty searchField number of filtered campaigns is returned
  // in filteredRecords property
  state.campaigns.campaignsParams.searchField
    ? state.campaigns.campaigns?.filteredRecords
    : state.campaigns.campaigns?.totalRecords;

export const getCampaignsParams = (state: AppState) => state.campaigns.campaignsParams;

import { createAction } from '../../utils/actions';
import { exchangesConstants } from './constants';
import { Exchange } from '../../api/Exchanges';

export type setExchanges = (exchanges: Exchange[]) => void;

export const exchangeActions = {
  setExchanges(exchanges: Exchange[]) {
    return createAction(exchangesConstants.SET_EXCHANGES, exchanges);
  },
};

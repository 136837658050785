import { reducerFromMap } from 'utils/actions';
import { Action } from 'models/Action';
import { customersConstants } from './constants';

export interface CustomersState {
  selectedCustomersLength: number | undefined;
}

const defaultCustomersState: CustomersState = {
  selectedCustomersLength: undefined,
};

function changeSelectedCustomersLength(
  state: CustomersState,
  action: Action<number>,
): CustomersState {
  return {
    ...state,
    selectedCustomersLength: action.payload,
  };
}

const reducer = reducerFromMap<CustomersState>(defaultCustomersState, {
  [customersConstants.CHANGE_SELECTED_CUSTOMERS_LENGTH]: changeSelectedCustomersLength,
});

export const customers = (state: CustomersState = defaultCustomersState, action: Action<any>) =>
  reducer(state, action);

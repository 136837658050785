import { AxiosResponse } from 'axios';
import { Logger } from '../utils/logger';
import { getInstance } from './Instance';
import { GetResponse } from '../models/Response';

export type Data = {
  dealTypeId: number;
  dealId: string;
  dealName: string;
  description: string;
  suggestedCPM: number | null;
  creativeTypes: number[];
  exchanges: number[];
  applicableTo?: number[];
  requestedDealId: number;
  isPublic: boolean;
  active: boolean;
};

export type PrivateDeal = {
  id: number;
  dealId: string;
  dealName: string;
  description: string;
  suggestedCPM: number;
  creativeTypes: number[];
  exchanges: string[];
  created: number;
  modifiedDate: string;
};

type ErrorResponse = {
  statusCode: number;
  responseObject: {
    errorField: string;
    errorMsg: string;
  }[];
};

export type DealType = {
  id: number;
  name: string;
};

export type DealTypes = DealType[];

export const CreatePrivateDeal = async (data: Data): Promise<GetResponse<Partial<PrivateDeal>>> => {
  try {
    const response: AxiosResponse<GetResponse<PrivateDeal>> = await getInstance().post(
      '/v2/inv/private/deal/add',
      data,
    );
    return response.data;
  } catch (e) {
    Logger.log('Error while creating private deal', e);
    return e.response.data;
  }
};

export const fetchPrivateDealTypes = async (): Promise<GetResponse<DealTypes>> => {
  try {
    const response: AxiosResponse<GetResponse<DealTypes>> = await getInstance().get(
      '/v2/master/inventory/dealTypes',
    );
    return response.data;
  } catch (e) {
    Logger.log('Error while fetching private deal types', e);
    return e;
  }
};

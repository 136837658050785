import React from 'react';
import { connect } from 'react-redux';
import { Button, ParamsCard, ParamsCardsDate, TextField, Icon } from 'factor';
import moment from 'moment';

import styles from './styles.module.scss';
import { RightBarList } from '../rightBarList';
import { AppState } from '../../../../store';
import { API } from '../../../../api';
import { SnackbarActions } from '../../../../store/snackbar/actions';
import { PrivateDealsActions, privateDealsActions } from '../../../../store/privateDeals/actions';
import { PrivateDeal, PrivateDeals, Params, PrivateDealsCount } from '../../../../api/PrivateDeals';
import { Skeleton } from '../../../shared/skeleton';
import { dialogActions, DialogActions } from '../../../../store/dialog/actions';
import { DeleteDealDialogDialog } from '../deleteDealDialog';
import { CreatePrivateDeal } from '../createPrivateDeal';

const SINGLE_ACTIONS = [
  { label: 'Make Available', iconName: 'Done' },
  { label: 'Delete', iconName: 'Delete' },
];

interface Props extends PrivateDealsActions, SnackbarActions, DialogActions {
  privateDeals: PrivateDeals;
  privateDealsParams: Params;
  isPrivateDealsFetching: boolean;
  privateDealsCount: PrivateDealsCount;
  selectDeselectPrivateDeal: (id: number) => void;
  selectDeselectAllPrivateDeals: (shouldSelectAll: boolean) => void;
  advertiserId: number | undefined;
  userTypeId: number | null;
}

interface State {
  subContent: { id: number; description: string } | null;
}

class PrivateDealsListComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      subContent: null,
    };
  }

  selectDeselectAllPrivateDeals = () => {
    const {
      privateDeals: { data },
      selectDeselectAllPrivateDeals,
    } = this.props;
    const isNowSomethingSelected = data.filter((item: any) => item.isSelected).length;
    selectDeselectAllPrivateDeals(!isNowSomethingSelected);
  };

  loadNewData = async () => {
    const {
      privateDeals,
      privateDealsParams,
      addPrivateDeals,
      setPrivateDealsParams,
      setPrivateDealsFetching,
      privateDealsCount,
      advertiserId,
    } = this.props;

    let totalRecords = 0;
    privateDealsParams.dealStatus.forEach((status) => {
      totalRecords += privateDealsCount[status];
    });

    if (privateDeals.data && totalRecords !== 0 && privateDeals.data.length >= totalRecords) {
      return;
    }

    setPrivateDealsFetching(true);

    const response = await API.PrivateDeals.FetchPrivateDeals({
      ...privateDealsParams,
      ...(typeof advertiserId === 'number' ? { advertiserId } : {}),
    });
    addPrivateDeals(response);
    setPrivateDealsParams({ pageNo: privateDealsParams.pageNo + 1 });
  };

  /* eslint-disable */
  renderSkeleton = () => <>
    <ParamsCardsDate
      date={<Skeleton style={{ width: '4.5rem', height: `${17 / 16}rem` }} />}
    />
    {[...new Array(10)].map((i, k) => (
      <ParamsCard
        key={k}
        title={<Skeleton style={{ width: '5rem', height: `${14 / 16}rem`, margin: `${1/16}rem 0 ${2/16}rem` }} />}
        list={[...new Array(3)].map(() => ({
          label: <Skeleton style={{ width: '3rem', height: `${12 / 16}rem`, margin: `${3/16}rem 0` }} />,
          value: <Skeleton style={{ width: '2rem', height: `${12 / 16}rem`, margin: `${1/16}rem 0 ${2/16}rem` }} />,
        }))}
      />
    ))}
  </>;
  /* eslint-enable */

  getTransformedData = () => {
    const {
      privateDeals: { data },
    } = this.props;

    if (!data?.length) {
      return [];
    }

    const transformedData: any[] = [];
    let currentBatch: { modifiedDate?: string; data: any[] } = { data: [] };
    transformedData.push(currentBatch);
    data.forEach((item: PrivateDeal) => {
      // in case there is a lack of '000' at the end of 'modifiedDate'
      const itemModified = item.modifiedDate;
      // let itemModifiedString = item.modifiedDate.toString();
      // if (itemModifiedString.length === 10) {
      //   itemModifiedString = `${itemModifiedString}000`;
      //   itemModified = parseInt(itemModifiedString, 10);
      // }

      const modifiedDate: string = moment(itemModified).format('MM/DD/YYYY');
      if (!currentBatch.modifiedDate) {
        currentBatch.modifiedDate = modifiedDate;
      } else if (currentBatch.modifiedDate !== modifiedDate) {
        currentBatch = { modifiedDate, data: [] };
        transformedData.push(currentBatch);
      }

      currentBatch.data.push({
        id: item.id,
        name: (
          <>
            {item.dealName}
            {/* <span className={styles.dealSubtitle}>{item.description}</span> */}
          </>
        ),
        params: [
          // { label: 'Suggested CPM:', value: item.suggestedCPM },
          // { label: 'Creative types:', value: item.creativeTypes.join(', ') },
        ],
        isSelected: item.isSelected,
      });
    });

    return transformedData;
  };

  setParamsCardActivity = (id: number) => () => {
    const { selectDeselectPrivateDeal } = this.props;

    selectDeselectPrivateDeal(id);
  };

  openCardSubContent = (id: number) => () => {
    this.setState({ subContent: { id, description: '' } });
  };

  getItemActions = (id: number) => {
    return SINGLE_ACTIONS.map((action) => {
      let onClick = () => console.log(`action ${action.label}`);

      switch (action.label) {
        case 'Delete':
          onClick = this.openDeleteDealDialog([id]);
          break;
        case 'Make Available':
          onClick = this.openCardSubContent(id);
          break;
        default:
      }

      return {
        ...action,
        onClick,
      };
    });
  };

  getGroupActions = () => {
    const ids = this.getSelectedDealsIds();

    return SINGLE_ACTIONS.map((action) => {
      let onClick = () => console.log(`action ${action.label} ${ids.join(', ')}`);

      switch (action.label) {
        case 'Delete':
          onClick = this.openDeleteDealDialog(ids);
          break;
        default:
      }

      return {
        ...action,
        onClick,
      };
    });
  };

  deleteDeals = (ids: number[]) => () => {
    console.log(`Deleted ${ids.join(', ')}`);
  };

  openDeleteDealDialog = (ids: number[]) => () => {
    const { openDialog } = this.props;

    openDialog({
      content: <DeleteDealDialogDialog ids={ids} deleteDeal={this.deleteDeals(ids)} />,
    });
  };

  getSelectedDealsIds = () => {
    const {
      privateDeals: { data = [] },
    } = this.props;

    return data.filter((item: PrivateDeal) => item.isSelected).map((item: PrivateDeal) => item.id);
  };

  onSubContentDescriptionChange = (value: string) => {
    const { subContent } = this.state;

    if (!subContent) {
      return;
    }

    this.setState({ subContent: { ...subContent, description: value } });
  };

  closeCardSubContent = () => this.setState({ subContent: null });

  renderCardSubContent = (id: number) => {
    const {
      privateDeals: { data },
    } = this.props;
    const { subContent } = this.state;

    if (!subContent) {
      return null;
    }

    const privateDeal = data.find((deal: PrivateDeal) => deal.id === id);

    if (!privateDeal) {
      return null;
    }

    return (
      <div className={styles.subContent}>
        <h4 className={styles.subContentTitle}>{privateDeal.dealName}</h4>
        <TextField
          className={styles.subContentRow}
          label="Description"
          placeholder="Description"
          value={subContent.description}
          onChange={this.onSubContentDescriptionChange}
        />
        <div className="d-flex justify-content-end mt-3">
          <Button
            className="btn-square _md _cornflower-blue mr-2"
            onClick={this.closeCardSubContent}
          >
            Cancel
          </Button>
          <Button
            className="btn-square _md _filled _cornflower-blue"
            onClick={this.closeCardSubContent}
          >
            Save Changes
          </Button>
        </div>
      </div>
    );
  };

  openCreateDealForm = () => {
    const { openDialog, closeDialog } = this.props;

    openDialog({
      content: <CreatePrivateDeal closeDialog={closeDialog} />,
      className: styles.createDialog,
    });
  };

  renderBeforeList = () => {
    const { userTypeId } = this.props;

    // show deal creation button only if user is 'super'
    // (super user's userTypeId is 3)
    return userTypeId === 3 ? (
      <div className={styles.createDeal}>
        <Button className="btn-round _filled _cornflower-blue" onClick={this.openCreateDealForm}>
          <i className="btn-content__icon">
            <Icon name="Plus" />
          </i>
          Create a Deal
        </Button>
      </div>
    ) : null;
  };

  render() {
    const { isPrivateDealsFetching } = this.props;
    // const { subContent } = this.state;

    const selectedIds = this.getSelectedDealsIds();

    const isSomethingSelected = !!selectedIds.length;

    return (
      <RightBarList
        selectDeselectAll={this.selectDeselectAllPrivateDeals}
        isSomethingSelected={isSomethingSelected}
        loadData={this.loadNewData}
        isFetching={isPrivateDealsFetching}
        skeleton={this.renderSkeleton()}
        // groupActions={this.getGroupActions()}
        beforeList={this.renderBeforeList()}
        hasNoButtons
      >
        {this.getTransformedData().map((batch) => (
          <div key={batch.modifiedDate}>
            <ParamsCardsDate date={batch.modifiedDate} />
            {batch.data.map((item: any) => (
              <ParamsCard
                key={item.id}
                title={item.name}
                list={item.params}
                onClick={this.setParamsCardActivity(item.id)}
                isActive={item.isSelected}
                // actions={this.getItemActions(item.id)}
                // subContent={this.renderCardSubContent(item.id)}
                // isSubContentOpen={item.id === subContent?.id}
              />
            ))}
          </div>
        ))}
      </RightBarList>
    );
  }
}

const mapState = (state: AppState) => {
  return {
    privateDeals: state.privateDeals.privateDeals,
    privateDealsParams: state.privateDeals.privateDealsParams,
    isPrivateDealsFetching: state.privateDeals.isPrivateDealsFetching,
    privateDealsCount: state.privateDeals.privateDealsCount,
    advertiserId: state.advertisers.selectedAdvertiserOption?.value,
    userTypeId: state.app.userTypeId,
  };
};

const mapActions = {
  setPrivateDeals: privateDealsActions.setPrivateDeals,
  addPrivateDeals: privateDealsActions.addPrivateDeals,
  setPrivateDealsParams: privateDealsActions.setPrivateDealsParams,
  setPrivateDealsFetching: privateDealsActions.setPrivateDealsFetching,
  selectDeselectPrivateDeal: privateDealsActions.selectDeselectPrivateDeal,
  selectDeselectAllPrivateDeals: privateDealsActions.selectDeselectAllPrivateDeals,
  openDialog: dialogActions.openDialog,
  closeDialog: dialogActions.closeDialog,
};

export const PrivateDealsList = connect(mapState, mapActions)(PrivateDealsListComponent);

import React from 'react';
import { Table, Checkbox, Button, Icon, Select, TextField } from 'factor';
import { connect } from 'react-redux';

import styles from './styles.module.scss';

import { getRowClassName } from '../helpers';

import { SelectedCampaigns } from './SelectedCampaigns';

import { AppState } from '../../store';
import { tableActions } from '../../store/table/actions';
import { TableRow } from '../../models/Table';
import { testOptions } from '../consts';
import { Option } from '../../models/Option';

class AccountsTableComponent extends React.PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      filterColumns: null,
      search: '',
    };
  }

  getHeader = () => {
    return [
      { label: '', className: '_checkbox' },
      { label: 'Customer', className: '_customer', sortingKey: 'customer' },
      { label: 'Date Added', className: '_dateAdded', sortingKey: 'dateAdded' },
      { label: 'Assignees', className: '_assignees', sortingKey: 'assignees' },
      { label: 'Active Campaigns', className: '_activeCampaigns', sortingKey: 'activeCampaigns' },
      { label: 'User Budget', className: '_userBudget', sortingKey: 'userBudget' },
      { label: 'User Spent', className: '_userSpent', sortingKey: 'userSpent' },
    ];
  };

  getBody = () => {
    const {
      tableData: { data },
    } = this.props;
    const selectedLength = data.filter((i: any) => i.selected).length;

    return [
      {
        key: (d: TableRow) =>
          d.total ? (
            <Checkbox
              className={selectedLength && !d.selectedAll ? '_dirty' : ''}
              checked={d.selectedAll}
              name={`checkbox-id-${d.id}`}
            />
          ) : (
            <Checkbox checked={d.selected} name={`checkbox-id-${d.id}`} />
          ),
        className: '_checkbox',
      },
      {
        key: (d: TableRow) => {
          if (d.total) {
            return <span className="table__cell-total">Total</span>;
          }
          return d.customer;
        },
        className: '_customer',
      },
      { key: 'dateAdded', className: '_dateAdded' },
      { key: 'assignees', className: '_assignees' },
      { key: 'activeCampaigns', className: '_activeCampaigns' },
      { key: 'userBudget', className: '_userBudget' },
      { key: 'userSpent', className: '_userSpent' },
    ];
  };

  toggleSelect = (d: any) => {
    const { getData, tableData: oldTableData } = this.props;

    const newData = JSON.parse(JSON.stringify(oldTableData));

    const tableData = newData.data;
    const tableFirstRow = newData.unsortedData;

    const selectedRowData = tableData.find((i: any) => i.id === d.id);
    selectedRowData.selected = !selectedRowData.selected;
    selectedRowData.rowClassName = getRowClassName(
      selectedRowData.rowClassName,
      '_selected',
      selectedRowData.selected,
    );

    newData.unsortedData = {
      ...tableFirstRow,
      selectedAll: tableData.filter((i: any) => i.selected).length === tableData.length,
    };
    newData.data = tableData.map((i: any) => {
      return i.id === d.id ? { ...i } : i;
    });

    getData(newData);
  };

  toggleSelectAll = () => {
    const { getData, tableData: oldTableData } = this.props;

    const newData = JSON.parse(JSON.stringify(oldTableData));

    const tableData = newData.data;
    const tableFirstRow = newData.unsortedData;

    const selectedLength = tableData.filter((i: any) => i.selected).length;

    if (newData.selectedAll || selectedLength > 0) {
      newData.unsortedData = {
        ...tableFirstRow,
        selectedAll: false,
      };
      newData.data = tableData.map((i: any) => {
        /* eslint-disable */
        i.selected = false;
        i.rowClassName = getRowClassName(i.rowClassName, '_selected', false);
        /* eslint-enable */
        return { ...i };
      });
    } else {
      newData.unsortedData = {
        ...tableFirstRow,
        selectedAll: true,
      };
      newData.data = tableData.map((i: any) => {
        /* eslint-disable */
        i.selected = true;
        i.rowClassName = getRowClassName(i.rowClassName, '_selected', true);
        /* eslint-enable */
        return { ...i };
      });
    }
    getData(newData);
  };

  isSelectedRowsEqualExcluded = () => {
    const { tableData: oldTableData } = this.props;

    const newData = JSON.parse(JSON.stringify(oldTableData));

    const tableData = newData.data;

    const selectedRows = tableData.filter((i: any) => i.selected);
    const excludedInSelectedRows = selectedRows.filter((i: any) => i.isExcluded);

    return selectedRows.length === excludedInSelectedRows.length;
  };

  toggleExclude = () => {
    const { getData, tableData: oldTableData } = this.props;

    const newData = JSON.parse(JSON.stringify(oldTableData));

    const tableData = newData.data;

    const selectedRows = tableData.filter((i: any) => i.selected);

    if (this.isSelectedRowsEqualExcluded()) {
      selectedRows.forEach((i: any) => {
        /* eslint-disable */
        i.isExcluded = false;
        i.rowClassName = getRowClassName(i.rowClassName, '_excluded', false);
        /* eslint-enable */
      });
    } else {
      selectedRows.forEach((i: any) => {
        /* eslint-disable */
        i.isExcluded = true;
        i.rowClassName = getRowClassName(i.rowClassName, '_excluded', true);
        /* eslint-enable */
      });
    }

    getData(newData);
  };

  rowClickHandler = (e: any, data: any) => {
    const { tagName } = e.target;
    const elType = e.target.getAttribute('type');

    if (tagName === 'INPUT' && elType === 'checkbox') {
      if (data.total) {
        this.toggleSelectAll();
      } else {
        this.toggleSelect(data);
      }
    }
  };

  onSearchChange = (search: string) => this.setState({ search });

  render() {
    const { tableData, title } = this.props;
    const { filterColumns, search } = this.state;
    const selectedLength = tableData.data.filter((i: any) => i.selected).length;

    return (
      <div className={`block ${styles.container}`}>
        <h4 className="title-4 mb-3">{title}</h4>
        <div className={`${styles.table}`}>
          <div className={styles.header}>
            {selectedLength > 0 && (
              <>
                <SelectedCampaigns className="mb-1" />
                <Button className={`btn-round _conflower-blue mr-5 mb-1 ${styles.actions}`}>
                  <span className="btn-round__prefix">
                    <Icon name="Expand" />
                  </span>
                  Actions
                </Button>
              </>
            )}
            <div className={styles.headerSearch}>
              <TextField
                label="Search"
                placeholder="Search"
                iconName="Search"
                value={search}
                onChange={this.onSearchChange}
              />
            </div>
            <Select
              className={styles.headerSelect}
              label="Columns"
              placeholder="Select Columns"
              multiPlaceholder={(length: number) =>
                `${length} Column${length > 1 ? 's' : ''} Selected`
              }
              value={filterColumns}
              options={testOptions}
              onChange={(v: Option[]) => this.setState({ filterColumns: v })}
              dnd
              isMulti
              allSelectable
            />
          </div>
          <Table
            className={styles.table}
            header={this.getHeader()}
            body={this.getBody()}
            data={tableData.data}
            unsortedData={[tableData.unsortedData]}
            paging={null}
            fixedHeader
            offsetTop={64}
            rowKeyExtractor={(data: any) => data.id}
            rowClickHandler={this.rowClickHandler}
            windowFreeResizeEvent
          />
        </div>
      </div>
    );
  }
}

const mapState = (state: AppState) => {
  return {
    tableData: state.table.tableData,
  };
};

const mapActions = {
  getData: tableActions.getData,
};

export const AccountsTable = connect(mapState, mapActions)(AccountsTableComponent);

import React from 'react';
import { Action } from '../../models/Action';
import { reducerFromMap } from '../../utils/actions';
import { snackbarConstants } from './constants';

export interface SnackbarState {
  isSnackbarOpen: boolean;
  snackbarMessage: string | React.ReactNode;
  duration: number;
}

export const defaultSnackbarState: SnackbarState = {
  isSnackbarOpen: false,
  snackbarMessage: '',
  duration: 5000,
};

const openSnackbar = (
  state: SnackbarState,
  action: Action<{ message: string; duration?: number }>,
): SnackbarState => {
  return {
    ...state,
    isSnackbarOpen: true,
    snackbarMessage: action.payload.message,
    duration: action.payload.duration ? action.payload.duration : 5000,
  };
};

const closeSnackbar = (state: SnackbarState): SnackbarState => {
  return {
    ...state,
    isSnackbarOpen: false,
    snackbarMessage: '',
    duration: 5000,
  };
};

const reducer = reducerFromMap<SnackbarState>(defaultSnackbarState, {
  [snackbarConstants.OPEN_SNACKBAR]: openSnackbar,
  [snackbarConstants.CLOSE_SNACKBAR]: closeSnackbar,
});

export const snackbar = (state: SnackbarState = defaultSnackbarState, action: Action<any>) =>
  reducer(state, action);

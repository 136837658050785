import React from 'react';
import { createAction } from '../../utils/actions';
import { snackbarConstants } from './constants';

export interface SnackbarActions {
  openSnackbar: ({
    message,
    duration,
  }: {
    message: string | React.ReactNode;
    duration?: number;
  }) => void;
  closeSnackbar: () => void;
}

export const snackbarActions = {
  openSnackbar({ message, duration }: { message: string | React.ReactNode; duration?: number }) {
    return createAction(snackbarConstants.OPEN_SNACKBAR, { message, duration });
  },
  closeSnackbar() {
    return createAction(snackbarConstants.CLOSE_SNACKBAR);
  },
};

import React, { Component } from 'react';
import { Switch, Select, TextField, Button } from 'factor';
import { connect } from 'react-redux';

import { devices } from 'components/consts';
import styles from './styles.module.scss';
import { List } from '../../../components/shared/List';
import Invite from './Invite/Invite';
import { AppState } from '../../../store';
import ComplexSidebar from '../../../components/ComplexSidebar';
import { organisationActions } from '../../../store/organization/actions';
import { Option } from '../../../models/Option';
import { ListOption } from '../../../models/ListOption';
import { MEMBER_LIST, ROLE_LIST } from '../../../store/organization/consts';

const membersOptions = [
  { label: 'All', value: 'All' },
  { label: 'not All', value: 'not All' },
];

interface Props {
  organisationSwitch: string;
  setSwitch: (v: string) => void;
  role: ListOption | null;
  setRole: (v: ListOption) => void;
}

interface State {
  membersSelect: Option;
  search: string;
  member: ListOption | undefined;
  isInviteFormVisible: boolean;
}

class OrganisationSidebarComponent extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      membersSelect: membersOptions[0],
      search: '',
      isInviteFormVisible: false,
      member: undefined,
    };
  }

  setSwitch = () => {
    const { organisationSwitch, setSwitch } = this.props;
    const newOrganisationSwitch = organisationSwitch === 'Members' ? 'Roles' : 'Members';
    setSwitch(newOrganisationSwitch);
  };

  onMembersSelectChange = (option: Option) => {
    this.setState({ membersSelect: option });
  };

  onSearchChange = (search: string) => this.setState({ search });

  onListChange = (role: ListOption) => {
    const { setRole } = this.props;
    setRole(role);
  };

  setMember = (member: ListOption) => this.setState({ member });

  toggleInvite = (isInviteFormVisible: boolean) => () => {
    this.setState({ isInviteFormVisible });
  };

  render() {
    const { organisationSwitch, role } = this.props;

    const { membersSelect, search, isInviteFormVisible, member } = this.state;

    return (
      <ComplexSidebar
        header={
          <div className={styles.header}>
            <Switch
              className={styles.btnGroup}
              name="OrganisationSwitch"
              list={devices}
              value={organisationSwitch === 'Roles'}
              onChange={this.setSwitch}
            />
            <div className="row">
              <div className="col-6">
                <Select
                  value={membersSelect}
                  options={membersOptions}
                  onChange={this.onMembersSelectChange}
                />
              </div>
              <div className="col-6">
                <TextField
                  label="Search"
                  placeholder="Search"
                  iconName="Search"
                  value={search}
                  onChange={this.onSearchChange}
                />
              </div>
            </div>
          </div>
        }
        lastChildren={
          <Invite
            className={isInviteFormVisible ? '' : '_hidden'}
            close={this.toggleInvite(false)}
          />
        }
        isMainContentHidden={isInviteFormVisible}
      >
        {organisationSwitch === 'Roles' ? (
          <List
            className={styles.list}
            list={ROLE_LIST}
            rightColumnField="assignees"
            header={['Role', 'Assignees']}
            value={role}
            onChange={this.onListChange}
          />
        ) : (
          <List
            className={styles.list}
            list={MEMBER_LIST}
            rightColumnField="date"
            value={member}
            onChange={this.setMember}
          />
        )}

        {!isInviteFormVisible && (
          <div className={styles.buttons}>
            <Button
              className="btn-round _filled _cornflower-blue"
              onClick={() => this.setState({ isInviteFormVisible: true })}
            >
              Invite Members
            </Button>
          </div>
        )}
      </ComplexSidebar>
    );
  }
}

const mapState = (state: AppState) => ({
  organisationSwitch: state.organisation.organisationSwitch,
  role: state.organisation.role,
});

const mapActions = {
  setSwitch: organisationActions.setSwitch,
  setRole: organisationActions.setRole,
};

export const OrganisationSidebar = connect(mapState, mapActions)(OrganisationSidebarComponent);

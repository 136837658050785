import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { iqmDealIdConstants } from './constants';

export interface IqmDealIdState {
  uuid: string | null;
}

export const defaultIqmDealIdState: IqmDealIdState = {
  uuid: null,
};

function setIqmDealId(state: IqmDealIdState, action: Action<string | null>): IqmDealIdState {
  return {
    ...state,
    uuid: action.payload,
  };
}

const reducer = reducerFromMap<IqmDealIdState>(defaultIqmDealIdState, {
  [iqmDealIdConstants.SET_IQM_DEAL_ID]: setIqmDealId,
});

export const iqmDealId = (state: IqmDealIdState = defaultIqmDealIdState, action: Action<any>) =>
  reducer(state, action);
